import './component-hooks';
import Vue from 'vue';
import axios from 'axios';
import App from './components/App.vue';
import router from './router';
import store from './store/index';
import i18n from './i18n';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import VueMask from 'v-mask';
import LelyVueShared, { LelyAuth } from '@tec/frontend-vue-shared';
import initBrowserlogs from '@tec/frontend-vue-shared/src/core/Browserlogs';
import BootstrapVue from 'bootstrap-vue';

import '@tec/frontend-vue-shared/src/assets/lely-red-rules/lely-styling/lely-stylesheet.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/math.filters';

Vue.config.productionTip = false;

Vue.use(VTooltip);
Vue.use(VModal);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(LelyVueShared);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use((response) => response, (error: any) => {
    if (error.config?.method === 'get' &&
        error.response?.status === 401 &&
        !router.currentRoute.meta?.guest &&
        !error.config?.headers['X-Lely-Skip-Auth-Interceptor']
    ) {
        LelyAuth.logout();

        const redirectTo = window.location.href.replace(window.location.origin + '/#', '');
        router.push({ name: 'login', query: { redirect_to: redirectTo === '/' ? null : redirectTo } }).then();
    }

    return Promise.reject(error);
});

if ('Cypress' in window) {
    const userId = window.localStorage.getItem('lely_alarmcenter_cypress_user_id');

    if (userId) {
        store.commit('setCurrentUserId', Number(userId));
    }
}

if (LelyAuth.isAuthenticated()) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + LelyAuth.getAccessToken();

    store.dispatch('syncMasterData').then();
    store.dispatch('runs/syncRuns').then();
    store.dispatch('tickets/syncOpenTickets').then();
    store.dispatch('tickets/subscribeTickets').then();
    store.dispatch('initSyncInterval').then();
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

initBrowserlogs('alarmcenter', () => Number(store.state.currentUserId), () => String(LelyAuth.getAccessToken()));
