















import { Component, Vue } from 'vue-property-decorator';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({})
export default class DashboardVehicleTypeTabs extends Vue {
    get vehicleTypes(): VehicleType[] {
        return this.$store.getters.vehicleTypesSet;
    }
}

