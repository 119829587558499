






import { Prop, Vue } from 'vue-property-decorator';
import vSelect from 'vue-select';
import Component from 'vue-class-component';
import Modal from '@/components/Modal.vue';

interface Action {
    label: string;
    service: string;
}

@Component({
    components: {
        Modal,
        vSelect,
    },
})
export default class DashboardActionsModal extends Vue {
    @Prop({ required: true })
    private actions!: Action[];

    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.select as any).$refs.search.focus();
    }

    execute(action: Action) {
        this.$store.dispatch('vehicleDashboard/callService', { service: action.service });
        this.$emit('close');
    }
}
