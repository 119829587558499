


























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    addDays,
    differenceInDays,
    endOfDay,
    endOfToday,
    endOfTomorrow,
    endOfYesterday,
    format,
    isAfter,
    isSameDay,
    isToday,
    isYesterday,
    startOfDay,
    startOfToday,
    startOfYesterday,
    subDays,
    subMonths,
} from 'date-fns';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

interface DateRange {
    startDate: Date;
    endDate: Date;
}

@Component({
    components: {
        DateRangePicker,
    },
})
export default class PeriodSelector extends Vue {
    @Prop({ required: true })
    value!: { start: Date; end: Date };

    ranges = {
        [this.$t('general.period.today').toString()]: [this.today.startDate, this.today.endDate],
        [this.$t('general.period.yesterday').toString()]: [this.yesterday.startDate, this.yesterday.endDate],
        [this.$t('general.period.last-2-days').toString()]: [this.last2Days.startDate, this.last2Days.endDate],
        [this.$t('general.period.last-7-days').toString()]: [this.last7Days.startDate, this.last7Days.endDate],
        [this.$t('general.period.last-month').toString()]: [this.lastMonth.startDate, this.lastMonth.endDate],
    };

    get maxDate(): Date {
        return endOfToday();
    }

    get dateRange(): DateRange {
        return {
            startDate: this.value.start,
            endDate: this.value.end,
        };
    }

    set dateRange(dateRange: DateRange) {
        this.$emit('input', {
            start: startOfDay(dateRange.startDate),
            end: endOfDay(dateRange?.endDate),
        });
    }

    get today(): DateRange {
        return {
            startDate: startOfToday(),
            endDate: endOfToday(),
        };
    }

    get yesterday(): DateRange {
        return {
            startDate: startOfYesterday(),
            endDate: endOfYesterday(),
        };
    }

    get last2Days(): DateRange {
        return {
            startDate: startOfDay(subDays(startOfToday(), 2)),
            endDate: endOfYesterday(),
        };
    }

    get last7Days(): DateRange {
        return {
            startDate: startOfDay(subDays(startOfToday(), 7)),
            endDate: endOfYesterday(),
        };
    }

    get lastMonth(): DateRange {
        return {
            startDate: startOfDay(subMonths(startOfToday(), 1)),
            endDate: endOfYesterday(),
        };
    }

    get disabledCustomNext(): boolean {
        const diff = differenceInDays(this.value.end, this.value.start) + 1;
        const nextEnd = addDays(this.value.end, diff);

        return isAfter(nextEnd, endOfTomorrow());
    }

    dateRangeSelected(dateRange: DateRange): boolean {
        return isSameDay(this.value.start, dateRange.startDate) && isSameDay(this.value.end, dateRange.endDate);
    }

    setCustomPrev(): void {
        const diff = differenceInDays(this.value.end, this.value.start) + 1;

        this.$emit('input', {
            start: subDays(this.value.start, diff),
            end: subDays(this.value.end, diff),
        });
    }

    setCustomNext(): void {
        const diff = differenceInDays(this.value.end, this.value.start) + 1;

        this.$emit('input', {
            start: addDays(this.value.start, diff),
            end: addDays(this.value.end, diff),
        });
    }

    formatDate(date: Date): string {
        if (isYesterday(date)) {
            return this.$t('general.period.yesterday').toString();
        }

        if (isToday(date)) {
            return this.$t('general.period.today').toString();
        }

        return format(date, 'dd/MM/yyyy');
    }

    get visibleText(): string | null {
        if (this.dateRangeSelected(this.today)) {
            return this.$t('general.period.today').toString();
        }

        if (this.dateRangeSelected(this.yesterday)) {
            return this.$t('general.period.yesterday').toString();
        }

        if (this.dateRangeSelected(this.last2Days)) {
            return this.$t('general.period.last-2-days').toString();
        }

        if (this.dateRangeSelected(this.last7Days)) {
            return this.$t('general.period.last-7-days').toString();
        }

        if (this.dateRangeSelected(this.lastMonth)) {
            return this.$t('general.period.last-month').toString();
        }

        if (isSameDay(this.dateRange.startDate, this.dateRange.endDate)) {
            return this.formatDate(this.dateRange.startDate);
        }

        return `${this.formatDate(this.dateRange.startDate)} - ${this.formatDate(this.dateRange.endDate)}`;
    }
}
