import L, { TileLayer, Util } from 'leaflet';
import getParamString = Util.getParamString;

const SimpleCRSWMS = L.TileLayer.WMS.extend({
    options: {
        transformBounds: null,
    },

    getTileUrl(coords: L.Coords) {
        const tileBounds = this._tileCoordsToNwSe(coords);
        const bounds = this.options.transformBounds.call(this, new L.LatLngBounds(tileBounds[0], tileBounds[1])) as L.LatLngBounds;
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();
        const newBounds = new L.Bounds(
            [
                [
                    nw.lng,
                    nw.lat,
                ],
                [
                    se.lng,
                    se.lat,
                ],
            ],
        );

        const min = newBounds.min;
        const max = newBounds.max;

        if (!min || !max) {
            return;
        }

        const bbox = [min.y, min.x, max.y, max.x].join(',');
        const url = TileLayer.prototype.getTileUrl.call(this, coords);

        return url +
            getParamString(this.wmsParams, url, this.options.uppercase) +
            (this.options.uppercase ? '&BBOX=' : '&bbox=') + bbox;
    },
});

export default SimpleCRSWMS;
