import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { DashboardState } from '@/store/modules/dashboard/dashboardState';
import axios, { CancelTokenSource } from 'axios';

let fetchCancelToken: CancelTokenSource | null = null;

const actions: ActionTree<DashboardState, RootState> = {
    fetch: async(context: ActionContext<DashboardState, RootState>, params: {
        dateFrom: string;
        dateUntil: string;
        vehicleTypeId: number;
        levels: Record<string, string>;
    }) => {
        context.commit('setDashboard', {
            vehicles: [],
            notificationTypes: [],
            notificationTypesFeedback: [],
        });

        if (fetchCancelToken) {
            fetchCancelToken.cancel();
        }

        fetchCancelToken = axios.CancelToken.source();

        const response = await axios.get('/dashboard-analytics', {
            cancelToken: fetchCancelToken.token,
            params: {
                date_from: params.dateFrom,
                date_until: params.dateUntil,
                vehicleTypeId: params.vehicleTypeId,
                ...params.levels,
            },
        });

        context.commit('setDashboard', {
            vehicles: response.data.vehicles,
            notificationTypes: response.data.notification_types,
            notificationTypesFeedback: response.data.feedback_notification_types,
        });
    },
};

export default actions;
