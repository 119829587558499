

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RunsTableRow from '@/components/run/runs-table/RunsTableRow.vue';
import NotificationCardDetails from '@/components/notification/NotificationCardDetails.vue';
import RunCardNotifications from '@/components/run/RunCardNotifications.vue';
import RunViewPreference from '@/core/enums/runViewPreference';
import { State } from 'vuex-class';
import {
    itemIsRunWithNotifications,
    NotificationOrRunWithNotificationsHolder,
} from '@/core/interfaces/notificationOrRunWithNotificationsHolder';
import RunsTableNotificationRow from '@/components/run/runs-table/RunsTableNotificationRow.vue';
import NotificationCard from '@/components/notification/NotificationCard.vue';

@Component({
    methods: { itemIsRunWithNotifications },
    components: {
        NotificationCard,
        RunsTableNotificationRow,
        RunCardNotifications,
        NotificationCardDetails,
        RunsTableRow,
    },
})
export default class RunsTable extends Vue {
    openedIds: string[] = [];

    @Prop({ type: Array, required: true })
    items!: NotificationOrRunWithNotificationsHolder[];

    @Prop()
    viewPreferenceEventBus!: Vue;

    @State('runViewPreference')
    runViewPreference!: RunViewPreference;

    created(): void {
        if (this.viewPreferenceEventBus) {
            this.viewPreferenceEventBus.$on(RunViewPreference.COLLAPSED, () => {
                this.openedIds = [];
            });
            this.viewPreferenceEventBus.$on(RunViewPreference.EXPANDED, () => {
                this.openedIds = this.allRunIds;
            });
        }
    }

    @Watch('items', { immediate: true })
    openInitialRuns(): void {
        if (this.runViewPreference === RunViewPreference.EXPANDED) {
            this.openedIds = this.allRunIds;
        }
    }

    public toggleItem(id: string): void {
        if (this.openedIds.includes(id)) {
            this.openedIds = this.openedIds.filter(i => i !== id);
            return;
        }

        this.openedIds.push(id);
    }

    get allRunIds(): string[] {
        return this.items.map(item => itemIsRunWithNotifications(item) ? 'run-' + item.run.id : '').filter(id => !!id);
    }
}

