



























import { Component, Prop } from 'vue-property-decorator';
import { Phone } from '@/core/interfaces/phone';
import axios from 'axios';
import { mixins } from 'vue-class-component';
import UsesUsernameOfPhone from '@/components/settings/phone/UsesUsernameOfPhone';

@Component({
    components: {},
})
export default class SendSimpleForm extends mixins(UsesUsernameOfPhone) {
    @Prop({ required: true })
    phone!: Phone;

    json = '';

    error = '';

    async submit(): Promise<void> {
        this.error = '';

        try {
            await axios.post(`${process.env.VUE_APP_API_URL}/phones/${this.phone.id}/send_raw_message`, JSON.parse(this.json));
        } catch (err) {
            this.error = String(err);
            return;
        }

        this.$emit('close');
    }
}

