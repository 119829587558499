import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/components/views/Login.vue';
import SettingsView from '@/components/views/SettingsView.vue';
import Vehicles from '@/components/views/Vehicles.vue';
import RunsTableView from '@/components/views/RunsTableView.vue';
import RunsTableViewMode from '@/core/enums/runsTableViewMode';
import RulesView from '@/components/views/settings/RulesView.vue';
import TimetableView from '@/components/views/settings/TimetableView.vue';
import RolesView from '@/components/views/settings/RolesView.vue';
import store from '@/store';
import KnowledgeBasePageView from '@/components/views/knowledge-base/KnowledgeBasePageView.vue';
import KnowledgeBaseIndexView from '@/components/views/knowledge-base/KnowledgeBaseIndexView.vue';
import PhonesView from '@/components/views/settings/PhonesView.vue';
import ConnectionsView from '@/components/views/relay/ConnectionsView.vue';
import RelayView from '@/components/views/RelayView.vue';
import MachinesView from '@/components/views/relay/MachinesView.vue';
import { loadLocaleForVehiclesTypes } from '@/i18n';
import TicketsView from '@/components/views/tickets/TicketsView.vue';
import ClosedTicketsView from '@/components/views/tickets/ClosedTicketsView.vue';
import BrowserLogsView from '@/components/views/settings/BrowserLogsView.vue';
import DashboardView from '@/components/views/DashboardView.vue';
import { LelyAuth } from '@tec/frontend-vue-shared';
import AssignedRuns from '@/components/views/AssignedRuns.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'open',
        component: RunsTableView,
        props: {
            view: RunsTableViewMode.OPEN,
        },
    },
    {
        path: '/current-runs/open',
        redirect: '/',
    },
    {
        path: '/assigned',
        name: 'assigned',
        component: AssignedRuns,
        props: true,
    },
    {
        path: '/10-days',
        name: '10-days',
        component: RunsTableView,
        props: {
            view: RunsTableViewMode.TEN_DAYS,
        },
    },
    {
        path: '/archive',
        name: 'archive',
        component: RunsTableView,
        props: {
            view: RunsTableViewMode.ARCHIVED,
        },
    },
    {
        path: '/tickets',
        props: true,
        component: { render: h => h('router-view') },
        children: [
            {
                path: 'unassigned',
                name: 'tickets.unassigned',
                component: TicketsView,
                props: {
                    view: 'unassigned',
                },
            },
            {
                path: 'assigned',
                name: 'tickets.assigned',
                component: TicketsView,
                props: {
                    view: 'assigned',
                },
            },
            {
                path: 'closed',
                name: 'tickets.closed',
                component: ClosedTicketsView,
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        children: [
            {
                path: 'rules',
                name: 'settings.rules',
                component: RulesView,
            },
            {
                path: 'roles',
                name: 'settings.roles',
                component: RolesView,
                meta: {
                    admin: true,
                },
            },
            {
                path: 'timetable',
                name: 'settings.timetable',
                component: TimetableView,
            },
            {
                path: 'phones',
                name: 'settings.phones',
                component: PhonesView,
                meta: {
                    admin: true,
                },
            },
            {
                path: 'browserlogs',
                name: 'settings.browserlogs',
                component: BrowserLogsView,
                meta: {
                    admin: true,
                },
            },
        ],
    },
    {
        path: '/vehicles',
        name: 'vehicles',
        component: Vehicles,
    },
    {
        path: '/knowledge-base',
        props: true,
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'knowledge-base.index',
                component: KnowledgeBaseIndexView,
            },
            {
                path: ':vehicleTypeName/page/:sectionId',
                name: 'knowledge-base.vehicle-type.section-page',
                component: KnowledgeBasePageView,
                props: true,
            },
            {
                path: ':vehicleTypeName/page',
                name: 'knowledge-base.vehicle-type.page',
                component: KnowledgeBasePageView,
                props: true,
            },
            {
                path: ':vehicleTypeName/farm/:farmId',
                name: 'knowledge-base.vehicle-type.farm',
                component: KnowledgeBasePageView,
                props: true,
            },
            {
                path: ':vehicleTypeName/:notificationTypeCode',
                name: 'knowledge-base.vehicle-type.notification-type',
                component: KnowledgeBasePageView,
                props: true,
            },
        ],
    },
    {
        path: '/relay',
        props: true,
        name: 'relay',
        component: RelayView,
        redirect: () => ({
            name: 'relay.connections',
        }),
        children: [
            {
                path: 'connections',
                name: 'relay.connections',
                component: ConnectionsView,
            },
            {
                path: 'machines',
                name: 'relay.machines',
                component: MachinesView,
            },
        ],
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async(to, from, next) => {
    if (LelyAuth.isAuthenticated()) {
        await loadLocaleForVehiclesTypes(store.getters.localeKeys, store.getters.vehicleTypesSet);
    }

    if (to.matched.some(route => route.meta.guest)) {
        if (!LelyAuth.isAuthenticated() || !store.state.currentUserId) {
            return next();
        } else {
            return next({ name: 'open' });
        }
    }

    if (LelyAuth.isAuthenticated()) {
        if (to.matched.some(route => route.meta.admin)) {
            if (store.getters.isAdmin) {
                return next();
            } else {
                return next({ name: 'open' });
            }
        }

        return next();
    }

    const redirectTo = window.location.href.replace(window.location.origin + '/#', '');

    return next({ name: 'login', query: { redirect_to: redirectTo === '/' ? null : redirectTo } });
});

export default router;
