import { RunsState } from '@/store/modules/runs/runsState';
import { Run } from '@/core/interfaces/run';
import { MutationTree } from 'vuex';
import Vue from 'vue';
import { Notification } from '@/core/interfaces/notification';

export interface RunsAndNotificationsPayload {
    notification_runs: Run[];
    notifications: Notification[];
}

const updateItemInState = <T extends { id: number }>(arr: T[], payload: T) => {
    const index = arr.findIndex(item => item.id === payload.id);

    if (index > -1) {
        Vue.set(arr, index, payload);
    }
};

const mutations: MutationTree<RunsState> = {
    setRunsAndNotifications: (state: RunsState, payload: RunsAndNotificationsPayload) => {
        state.runs = payload.notification_runs;
        state.notifications = payload.notifications;
    },

    setTableRunsAndNotifications: (state: RunsState, payload: RunsAndNotificationsPayload) => {
        state.tableRuns = payload.notification_runs;
        state.tableNotifications = payload.notifications;
    },

    setTableLoading: (state: RunsState, payload: boolean) => {
        state.tableLoading = payload;
    },

    setTableTotalNumber: (state: RunsState, payload: number) => {
        state.tableTotalNumberOfRecords = payload;
    },

    updateNotification: (state: RunsState, payload: Notification) => {
        updateItemInState(state.notifications, payload);
        updateItemInState(state.tableNotifications, payload);
    },

    updateRun: (state: RunsState, payload: Run) => {
        updateItemInState(state.runs, payload);
        updateItemInState(state.tableRuns, payload);
    },
};

export default mutations;
