
























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';

const vehicleDashboard = namespace('vehicleDashboard');

@Component
export default class DashboardCamera extends Vue {
    @vehicleDashboard.State('cameraTopic')
    private cameraTopic!: string;

    @vehicleDashboard.State('camera')
    private camera!: string;

    @vehicleDashboard.State('definition')
    private definition!: DashboardDefinition;

    get cameraData(): string {
        if (!this.camera) {
            return '';
        }
        return `data:image/jpeg;base64,${this.camera}`;
    }

    private mounted() {
        if (!this.definition.cameras.length) {
            return;
        }

        this.loadCamera(this.definition.cameras[0].topic);
    }

    private loadCamera(cameraTopic: string) {
        this.$store.dispatch('vehicleDashboard/loadCamera', cameraTopic);
    }

    private beforeDestroy() {
        this.$store.dispatch('vehicleDashboard/closeCamera');
    }

    protected async toggleFullscreen() {
        const container = (this.$refs.container as HTMLElement);

        if (!document.fullscreenElement) {
            await container.requestFullscreen();
        } else if (document.exitFullscreen) {
            await document.exitFullscreen();
        }
    }
}

