













import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Mutation, State } from 'vuex-class';
import RunViewPreference from '@/core/enums/runViewPreference';

@Component({})
export default class RunViewPreferenceControl extends Vue {
    @Mutation('setRunViewPreference')
    setRunViewPreference!: (preference: RunViewPreference) => void;

    @State('runViewPreference')
    currentRunViewPreference!: RunViewPreference;

    @Prop()
    viewPreferenceEventBus!: Vue;

    RunViewPreference = RunViewPreference;

    expand(): void {
        this.setRunViewPreference(RunViewPreference.EXPANDED);
        this.viewPreferenceEventBus.$emit(RunViewPreference.EXPANDED);
    }

    collapse(): void {
        this.setRunViewPreference(RunViewPreference.COLLAPSED);
        this.viewPreferenceEventBus.$emit(RunViewPreference.COLLAPSED);
    }
}
