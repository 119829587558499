import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GetChannelsFromNotifyLog extends Vue {
    public getChannelsFromNotifyLog(log: { sms: boolean; app: boolean; call: boolean }): string[] {
        const channels = [];
        if (log.sms) {
            channels.push('sms');
        }
        if (log.app) {
            channels.push('app');
        }
        if (log.call) {
            channels.push('call');
        }

        return channels;
    }
}
