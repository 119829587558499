import { RelayState } from '@/store/modules/relay/relayState';
import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { RelayMachine } from '@/core/interfaces/relayMachine';

const getters: GetterTree<RelayState, RootState> = {
    connections: (state: RelayState): RelayConnection[] => state.connections
        .sort((a, b) => a.name ? a.name.localeCompare(b.name || '') : 0),
    machines: (state: RelayState): RelayMachine[] => state.machines
        .sort((a, b) => a.machine.localeCompare(b.machine)),
    machineByName: (state: RelayState) => (name: string): RelayMachine => state.machines
        .find(machine => machine.machine === name) as RelayMachine,
    connectionByUuid: (state: RelayState) => (uuid: string): RelayConnection => state.connections
        .find(connection => connection.uuid === uuid) as RelayConnection,
};

export default getters;
