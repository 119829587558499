



























import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { NotificationType } from '@/core/interfaces/notificationType';
import KnowledgeBasePage from '@/components/knowledge-base/KnowledgeBasePage.vue';
import { VehicleType } from '@/core/interfaces/vehicleType';
import BreadcrumbInterface from '@tec/frontend-vue-shared/lib/core/BreadcrumbInterface';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import KnowledgeBaseNav from '@/components/knowledge-base/KnowledgeBaseNav.vue';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import { Farm } from '@/core/interfaces/farm';
import { KnowledgeBaseSection } from '@/core/interfaces/knowledgeBaseSection';

@Component({
    components: { KnowledgeBasePage, KnowledgeBaseNav, TopnavLayout },
})
export default class KnowledgeBasePageView extends UsesNotificationMessage {
    @Prop({ required: true })
    vehicleTypeName!: string;

    @Prop()
    notificationTypeCode!: string;

    @Prop()
    farmId!: string;

    @Prop()
    sectionId!: string;

    @Getter('notificationTypeByCode')
    notificationTypeByCode!: (code: number, vehicleTypeId: number) => NotificationType;

    @Getter('vehicleTypeByName')
    vehicleTypeByName!: (name: string) => VehicleType;

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Getter('knowledgeBase/sectionById')
    sectionById!: (id: number) => KnowledgeBaseSection;

    mounted(): void {
        this.$store.dispatch('knowledgeBase/fetchSections', this.vehicleType.id);
    }

    get breadcrumbs(): BreadcrumbInterface[] {
        const breadcrumbs: BreadcrumbInterface[] = [];

        breadcrumbs.push({
            label: `${this.vehicleType.name}`,
            location: {
                name: 'knowledge-base.vehicle-type.page',
                params: {
                    vehicleTypeName: this.vehicleType.name,
                },
            },
        });

        if (this.farm) {
            breadcrumbs.push({
                label: `${this.farm.name}`,
            });
        }

        if (this.notificationType) {
            breadcrumbs.push({
                label: `${this.notificationMessage}`,
            });
        }

        if (this.section) {
            breadcrumbs.push({
                label: `${this.section.name}`,
            });
        }

        return breadcrumbs;
    }

    get vehicleType(): VehicleType {
        return this.vehicleTypeByName(this.vehicleTypeName);
    }

    get farm(): Farm | null {
        if (!this.farmId) {
            return null;
        }

        return this.farmById(Number(this.farmId));
    }

    get notificationType(): NotificationType | null {
        if (!this.notificationTypeCode) {
            return null;
        }

        return this.notificationTypeByCode(Number(this.notificationTypeCode), this.vehicleType.id) || null;
    }

    get section(): KnowledgeBaseSection | null {
        if (!this.sectionId) {
            return null;
        }

        return this.sectionById(Number(this.sectionId));
    }
}

