















import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { User } from '@/core/interfaces/user';
import { abbr } from '@/helpers/string.helpers';
import { Role } from '@/core/interfaces/role';
import FormatsDate from '@/components/mixins/FormatsDate';

@Component
export default class Avatar extends FormatsDate {
    @Prop({ required: true })
    protected who!: User | Role;

    @Prop()
    protected when!: string | null;

    @Prop({ default: false, type: Boolean })
    protected small!: boolean;

    @Prop({ required: true })
    protected type!: 'assigned' | 'resolved';

    get isMe(): boolean {
        return this.isUserFn(this.who) && this.who === this.$store.getters.currentUser;
    }

    get name(): string {
        return this.isUserFn(this.who) ? this.who.username : this.who.code_name;
    }

    get whoShort(): string {
        return abbr(this.name);
    }

    get isRole(): boolean {
        return !this.isUserFn(this.who);
    }

    get isUser(): boolean {
        return this.isUserFn(this.who);
    }

    get tooltip(): string {
        if (this.when) {
            return `${this.type}: ${this.name} (${this.formatDate(this.when)})`;
        }

        return `${this.type}: ${this.name}`;
    }

    isUserFn(who: User | Role): who is User {
        return 'username' in who;
    }

    get iconClass(): string {
        if (this.type === 'assigned') {
            return 'lely-icon-person-assign';
        }

        if (this.type === 'resolved') {
            return 'lely-icon-check';
        }

        return '';
    }
}
