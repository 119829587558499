import { RootState } from '@/store/rootState';
import { Farm } from '@/core/interfaces/farm';
import { createRecordByIdFromArray } from '@/helpers';
import { NotificationType } from '@/core/interfaces/notificationType';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import { User } from '@/core/interfaces/user';
import { Role } from '@/core/interfaces/role';
import { Vehicle } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { UserRole } from '@/core/interfaces/userRole';
import Vue from 'vue';
import RunViewPreference from '@/core/enums/runViewPreference';
import { Phone } from '@/core/interfaces/phone';
import { FarmVehicleType } from '@/core/interfaces/farmVehicleType';
import NotificationLevel from '@/core/enums/notificationLevel';

export const setFarms = (state: RootState, payload: Farm[]) => {
    state.farms = createRecordByIdFromArray(payload);
    state.farmsList = payload.map(item => item.id);
};

export const setFarmVehicleTypes = (state: RootState, payload: FarmVehicleType[]) => {
    state.farmVehicleTypes = payload;
};

export const setNotificationTypes = (state: RootState, payload: NotificationType[]) => {
    state.notificationTypes = createRecordByIdFromArray(payload);
    state.notificationTypesList = payload.map(item => item.id);
};

export const setVehiclePhases = (state: RootState, payload: VehiclePhase[]) => {
    state.vehiclePhases = createRecordByIdFromArray(payload);
    state.vehiclePhasesList = payload.map(item => item.id);
};

export const setUsers = (state: RootState, payload: User[]) => {
    state.users = createRecordByIdFromArray(payload);
    state.usersList = payload.map(item => item.id);
};

export const setUserRoles = (state: RootState, payload: UserRole[]) => {
    state.userRoles = payload;
};

export const setRoles = (state: RootState, payload: Role[]) => {
    state.roles = createRecordByIdFromArray(payload);
    state.rolesList = payload.map(item => item.id);
};

export const setVehicles = (state: RootState, payload: Vehicle[]) => {
    state.vehicles = createRecordByIdFromArray(payload);
    state.vehiclesList = payload.map(item => item.id);
};

export const setVehicleTypes = (state: RootState, payload: VehicleType[]) => {
    state.vehicleTypes = createRecordByIdFromArray(payload);
    state.vehicleTypesList = payload.map(item => item.id);
};

export const setCurrentUserId = (state: RootState, payload: number) => {
    state.currentUserId = payload;
};

export const setLocales = (state: RootState, payload: Record<string, string>) => {
    state.locales = payload;
};

export const deleteRole = (state: RootState, payload: number) => {
    state.rolesList = state.rolesList.filter(roleId => roleId !== payload);
};

export const setRunViewPreference = (state: RootState, payload: RunViewPreference) => {
    state.runViewPreference = payload;
};

export const updateRole = (state: RootState, payload: Role) => {
    Vue.set(state.roles, payload.id, payload);

    if (!state.rolesList.includes(payload.id)) {
        state.rolesList.push(payload.id);
    }
};

export const setPhones = (state: RootState, payload: Phone[]) => {
    state.phones = payload;
};

export const deletePhone = (state: RootState, payload: number) => {
    state.phones = state.phones.filter(phone => phone.id !== payload);
};

export const updatePhone = (state: RootState, payload: Phone) => {
    Vue.set(state.phones, state.phones.findIndex(phone => phone.id === payload.id), payload);
};

export const setVisibleVehicleTypeIds = (state: RootState, payload: number[]) => {
    state.visibleVehicleTypeIds = payload;
};

export const setVisibleNotificationLevels = (state: RootState, payload: NotificationLevel[]) => {
    state.visibleNotificationLevels = payload;
};

export const setGroupRuns = (state: RootState, payload: boolean) => {
    state.groupRuns = payload;
};
