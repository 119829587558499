import { Vue } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import Component from 'vue-class-component';
import VehicleDashboard from '@/components/vehicle-dashboard/VehicleDashboard.vue';

@Component
export default class OpensDashboardModal extends Vue {
    async openDashboardModal(vehicle: Vehicle): Promise<void> {
        this.$modal.show(
            VehicleDashboard,
            { vehicle: vehicle },
            { adaptive: true, height: 'auto', width: '95%' },
        );
    }
}
