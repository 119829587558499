

















import Component from 'vue-class-component';
import Vue from 'vue';
import Modal from '@/components/Modal.vue';
import RunCardNotifications from '@/components/run/RunCardNotifications.vue';
import axios from 'axios';
import { RunsAndNotificationsPayload } from '@/store/modules/runs/mutations';
import { Prop } from 'vue-property-decorator';
import { Notification } from '@/core/interfaces/notification';
import { Run } from '@/core/interfaces/run';

@Component({
    components: { RunCardNotifications, Modal },
})
export default class RunNotificationsModal extends Vue {
    @Prop({ required: true })
    run!: Run;

    @Prop({ type: Number, default: null })
    initialNotificationId?: number;

    notifications: Notification[] = [];

    async mounted(): Promise<void> {
        if (this.run.notifications.length) {
            this.notifications = this.run.notifications;
            return;
        }

        this.notifications = (await axios.get<RunsAndNotificationsPayload>(`/notifications?runids=${this.run.id}`)).data.notifications;
    }
}
