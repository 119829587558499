import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { KnowledgeBaseState } from '@/store/modules/knowledgeBase/knowledgeBaseState';
import { KnowledgeBaseSection } from '@/core/interfaces/knowledgeBaseSection';

const getters: GetterTree<KnowledgeBaseState, RootState> = {
    sections: (state: KnowledgeBaseState): KnowledgeBaseSection[] => state.sections
        .sort((a, b) => a.order - b.order),

    sectionById: (state: KnowledgeBaseState) => (id: number): KnowledgeBaseSection => state
        .sections.find(section => section.id === id) as KnowledgeBaseSection,

};

export default getters;
