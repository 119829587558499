







































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter, Mutation, State } from 'vuex-class';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({})
export default class VisibleVehicleTypesFilter extends Vue {
    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @State('visibleVehicleTypeIds')
    visibleVehicleTypeIds!: number[];

    @Mutation('setVisibleVehicleTypeIds')
    setVisibleVehicleTypeIds!: (ids: number[]) => void;

    get allSelected(): boolean {
        return this.visibleVehicleTypeIds.length === 0 || this.visibleVehicleTypeIds.length === this.vehicleTypes.length;
    }

    get selectedVehicleTypes(): VehicleType[] {
        return this.visibleVehicleTypeIds.map(id => this.vehicleTypeById(id));
    }
}
