








































import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import { Getter } from 'vuex-class';
import { Role } from '@/core/interfaces/role';
import TranslatesRole from '@/components/mixins/TranslatesRole';
import { NotificationNotifyRequest } from '@/core/interfaces/notificationNotifyRequest';

@Component({
    components: { Modal },
})
export default class NotificationActionMessageModal extends Mixins(TranslatesRole) {
    @Getter('notifyableRolesSet')
    protected roles!: Role[];

    protected role: Role | null = null;
    protected message = '';
    protected call = false;
    protected sms = false;
    protected app = true;

    mounted() {
        (this.$refs.message as HTMLTextAreaElement).focus();
    }

    save() {
        const isValid = (this.$refs.form as HTMLFormElement).reportValidity();

        if (!isValid) {
            return;
        }

        if (!this.role) {
            return;
        }

        this.$emit('close', {
            role_id: this.role.id,
            message: this.message,
            sms: this.sms,
            call: this.call,
            app: this.app,
        } as NotificationNotifyRequest);
    }

    close() {
        this.$emit('close');
    }
}
