

















































import { Component, Vue } from 'vue-property-decorator';
import LocaleSelect from '@/components/LocaleSelect.vue';
import { Getter } from 'vuex-class';
import { LelyApp, LelyAuth } from '@tec/frontend-vue-shared';

@Component({
    components: {
        LocaleSelect,
    },
})
export default class NavigationTop extends Vue {
    @Getter('isAdmin')
    isAdmin!: boolean;

    get loginPage() {
        return this.$route.meta?.layout === 'LoginLayout';
    }

    get app(): LelyApp {
        return LelyApp.ALARMCENTER;
    }

    logout() {
        this.$store.dispatch('stopSyncInterval');
        LelyAuth.logout();

        this.$router.push({ name: 'login' });
    }
}

