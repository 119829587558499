


























import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Phone } from '@/core/interfaces/phone';
import { mixins } from 'vue-class-component';
import UsesUsernameOfPhone from '@/components/settings/phone/UsesUsernameOfPhone';

@Component({
    components: {},
})
export default class PhoneForm extends mixins(UsesUsernameOfPhone) {
    @Action('savePhone')
    savePhone!: (payload: Partial<Phone>) => Promise<void>;

    @Prop({ required: true })
    initialPhone?: Phone;

    enabledOptions: (keyof Phone)[] = [];

    options: { value: keyof Phone; text: string }[] = [
        {
            value: 'use_push_notifications',
            text: 'Push notifications',
        },
        {
            value: 'use_incoming_call',
            text: 'Incoming call',
        },
        {
            value: 'use_operator_call',
            text: 'Operator call',
        },
    ];

    async mounted(): Promise<void> {
        if (this.initialPhone) {
            if (this.initialPhone.use_incoming_call) {
                this.enabledOptions.push('use_incoming_call');
            }

            if (this.initialPhone.use_push_notifications) {
                this.enabledOptions.push('use_push_notifications');
            }

            if (this.initialPhone.use_operator_call) {
                this.enabledOptions.push('use_operator_call');
            }
        }
    }

    get phoneFromForm(): Partial<Phone> {
        return {
            id: this.initialPhone?.id,
            use_operator_call: this.enabledOptions.includes('use_operator_call'),
            use_push_notifications: this.enabledOptions.includes('use_push_notifications'),
            use_incoming_call: this.enabledOptions.includes('use_incoming_call'),
        };
    }

    async submit(): Promise<void> {
        await this.savePhone(this.phoneFromForm);

        this.$emit('submitted');
    }
}

