import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { TicketsState } from '@/store/modules/tickets/ticketsState';
import { Ticket } from '@/core/interfaces/ticket';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import { isAfter, parseISO } from 'date-fns';

const getters: GetterTree<TicketsState, RootState> = {
    tickets: state => state.tickets.sort((a, b) =>
        isAfter(parseISO(a.created_at), parseISO(b.created_at)) ? -1 : 1),
    ticketById: state => (id: number): Ticket => state.tickets.find(ticket => ticket.id === id) as Ticket,
    ticketByCode: state => (code: string): Ticket => state.tickets.find(ticket => ticket.code === code) as Ticket,
    messageById: state => (id: number): TicketMessage => state.messages.find(message => message.id === id) as TicketMessage,
    closedTickets: (state, getters) => state.closedTicketIds.map(id => getters.ticketById(id)),
    openTickets: (state, getters, rootState, rootGetters) => getters.tickets
        .filter((ticket: Ticket) => !ticket.closed_by)
        .filter((ticket: Ticket) => {
            if (!rootState.visibleVehicleTypeIds.length) {
                return true;
            }

            return rootState.visibleVehicleTypeIds.includes(rootGetters.vehicleById(ticket.vehicle_id)?.vehicle_type_id);
        }),
    assignedTickets: (state, getters) => getters.tickets
        .filter((ticket: Ticket) => ticket.assigned_to && !ticket.closed_by),
    assignedToMe: (state, getters, rootState) => getters.tickets
        .filter((ticket: Ticket) => ticket.assigned_to === rootState.currentUserId),
    assignedToOthers: (state, getters, rootState) => getters.tickets
        .filter((ticket: Ticket) => ticket.assigned_to && ticket.assigned_to !== rootState.currentUserId),
    categories: state => state.categories,
    categoryById: state => (id: number) => state.categories.find(category => category.id === id),
    messagesByTicket: state => (ticketId: number) => state.messages
        .filter(message => message.ticket_id === ticketId),
    isTicketSeen: state => (id: number): boolean => state.seenTickets.map(ticket => ticket.id).includes(id),
    isMessageSeen: state => (id: number): boolean => state.seenMessages.map(message => message.id).includes(id),
    nrOfSeenMessagesByTicket: (state) => (id: number): number => state.seenMessages
        .filter(message => message.ticket_id === id).length,
    unseenMessagesTicketsAssignedToMe: (state, getters) => getters
        .assignedToMe.reduce((acc: number, ticket: Ticket) => acc + (getters.nrOfSeenMessagesByTicket(ticket.id) - ticket.message_count), 0),
};

export default getters;
