






























import { Component, Mixins, Prop } from 'vue-property-decorator';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import UsesNotificationDate from '@/components/mixins/UsesNotificationDate';
import UsesDateFnsLocale from '@/components/mixins/UsesDateFnsLocale';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import BaseItemCard from '@/components/BaseItemCard.vue';
import NotifiedRolesIndicator from '@/components/NotifiedRolesIndicator.vue';
import NotificationActions from '@/components/notification/NotificationActions.vue';
import NotificationCardDetails from '@/components/notification/NotificationCardDetails.vue';
import NotificationLevelIcon from '@/components/notification/NotificationLevelIcon.vue';
import Avatar from '@/components/Avatar.vue';

@Component({
    components: {
        Avatar,
        NotificationLevelIcon,
        NotificationCardDetails,
        NotificationActions,
        NotifiedRolesIndicator,
        BaseItemCard,

    },
})
export default class NotificationCard extends Mixins(
    UsesNotificationDetails,
    UsesNotificationMessage,
    UsesNotificationDate,
    UsesDateFnsLocale,
    FormatsDurationShort,
    TranslatesVehiclePhase,
) {
    @Prop({ default: false, type: Boolean })
    public showSubtitle!: boolean;

    @Prop({ default: false, type: Boolean })
    public opened!: boolean;
}

