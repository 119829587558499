import { Component, Vue } from 'vue-property-decorator';
import { enGB, nl } from 'date-fns/locale';

@Component
export default class UsesDateFnsLocale extends Vue {
    public get dateFnsLocale(): Locale {
        switch (this.$i18n.locale) {
            case 'nl':
                return nl;
            default:
                return enGB;
        }
    }
}
