















































import { Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import KnowledgeBaseEditor from '@/components/knowledge-base/KnowledgeBaseEditor.vue';
import FormatsDate from '@/components/mixins/FormatsDate';
import { Getter } from 'vuex-class';
import { User } from '@/core/interfaces/user';

@Component({
    components: { KnowledgeBaseEditor },
})
export default class KnowledgeBasePageView extends FormatsDate {
    @Prop({ required: true, type: Number })
    vehicleTypeId!: number;

    @Prop({ type: Number })
    notificationTypeId!: number;

    @Prop({ type: Number })
    farmId!: number;

    @Prop({ type: Number })
    sectionId!: number;

    @Getter('userById')
    userById!: (id: number) => User;

    loading = false;

    content = '';
    userId = 0;
    lastModified: Date | null = null;
    modalImage = '';

    editMode = false;

    @Watch('url', { immediate: true })
    async sync() {
        this.loading = true;
        this.editMode = !!this.$route.query.create;

        try {
            const response = (await axios.get(this.url, {
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0',
                },
            }));

            this.content = response.data;
            this.userId = Number(response.headers['x-userid']);
            this.lastModified = response.headers['last-modified'] ? new Date(response.headers['last-modified']) : null;
        } catch {
            this.content = '';
            this.lastModified = null;
            this.userId = 0;
        } finally {
            this.loading = false;
        }
    }

    get url(): string {
        if (this.notificationTypeId) {
            return `notification-types/${this.notificationTypeId}/page`;
        }

        if (this.farmId) {
            return `vehicle-types/${this.vehicleTypeId}/farms/${this.farmId}/page`;
        }

        if (this.sectionId) {
            return `vehicle-types/${this.vehicleTypeId}/sections/${this.sectionId}/page`;
        }

        return `vehicle-types/${this.vehicleTypeId}/page`;
    }

    get attachmentsUrl(): string {
        if (this.notificationTypeId) {
            return `notification-types/${this.notificationTypeId}/attachments`;
        }

        if (this.farmId) {
            return `vehicle-types/${this.vehicleTypeId}/farms/${this.farmId}/attachments`;
        }

        return `vehicle-types/${this.vehicleTypeId}/attachments`;
    }

    get user(): User {
        return this.userById(this.userId);
    }

    async save(data: string): Promise<void> {
        if (data === this.content) {
            this.editMode = false;
            return;
        }

        this.loading = true;

        await axios({
            method: 'put',
            url: this.url,
            headers: {
                'Content-Type': 'text/html',
            },
            data,
        });

        this.editMode = false;

        await this.sync();

        if (this.$route.query.create) {
            await this.$router.replace({ query: {} });
        }
    }

    openModalIfImage(event: MouseEvent): void {
        const target = event.target as HTMLElement;

        if (target.tagName === 'IMG' && target.hasAttribute('width')) {
            this.modalImage = target.getAttribute('src') || '';
            this.$bvModal.show('image-modal');
        }
    }
}

