




















import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { Getter, namespace } from 'vuex-class';
import { Phone } from '@/core/interfaces/phone';
import { User } from '@/core/interfaces/user';
import RelayConnectionsFilters, { RelayConnectionFiltersData } from '@/components/relay/RelayConnectionsFilters.vue';
import { mixins } from 'vue-class-component';
import UsesRelayStateRowStatus from '@/components/relay/usesRelayStateRowStatus';

const relayStore = namespace('relay');

@Component({
    components: { RelayConnectionsFilters },
})
export default class ConnectionsView extends mixins(UsesRelayStateRowStatus) {
    @relayStore.Getter('connections')
    connections!: RelayConnection[];

    @Getter('phoneByUdid')
    phoneByUdid!: (udid: string) => Phone;

    @Getter('userById')
    userById!: (id: number) => User;

    filters: RelayConnectionFiltersData | null = null;

    get columns(): LelyTableColumnsConfig<RelayConnection> {
        return [
            {
                header: 'IP',
                value: 'ip_address',
                sub: connection => [connection.type, connection.source]
                    .filter(str => str)
                    .join(' - '),
            },
            {
                header: 'State',
                value: 'state',
            },
            {
                header: 'Machine',
                value: 'name',
            },
            {
                header: 'User',
                value: connection => {
                    if (!connection.user_id) {
                        return '';
                    }

                    const user = this.userById(connection.user_id);

                    return user?.username || '';
                },
                sub: connection => {
                    if (!connection.phone_udid) {
                        return '';
                    }

                    const phone = this.phoneByUdid(connection.phone_udid);

                    if (!phone) {
                        return '';
                    }

                    return phone.device_info;
                },
            },
        ];
    }

    get filteredConnections(): RelayConnection[] {
        if (!this.filters) {
            return this.connections;
        }

        return this.connections
            .filter(connection => !this.filters?.state || connection.state === this.filters.state)
            .filter(connection => !this.filters?.machine || connection.name === this.filters.machine)
            .filter(connection => !this.filters?.userId || connection.user_id === this.filters.userId);
    }
}

