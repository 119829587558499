




import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import NotificationLevel from '@/core/enums/notificationLevel';

@Component
export default class NotificationLevelIcon extends Vue {
    @Prop({ type: String, required: true })
    protected notificationLevel!: NotificationLevel;

    get iconClass(): string[] {
        const classes = [this.notificationLevel.toLowerCase()];
        switch (this.notificationLevel) {
            case NotificationLevel.INFO:
                classes.push('lely-icon-information');
                break;
            default:
                classes.push('lely-icon-warning');
        }

        classes.push('icon-center');

        return classes;
    }
}
