




















































import { Component, Prop } from 'vue-property-decorator';
import { NotificationType } from '@/core/interfaces/notificationType';
import DashboardPosition from '@/components/dashboard/DashboardPosition.vue';
import DashboardDifference from '@/components/dashboard/DashboardDifference.vue';
import { Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import { VehicleType } from '@/core/interfaces/vehicleType';
import DashboardNotificationTypeFeedback from '@/core/interfaces/dashboard/dashboardNotificationTypeFeedback';

@Component({
    components: { DashboardDifference, DashboardPosition },
})
export default class DashboardNotificationTypesFeedbackWidget extends mixins(UsesNotificationMessage) {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Prop({ required: true })
    notificationTypes!: DashboardNotificationTypeFeedback[];

    @Getter('notificationTypeById')
    notificationTypeById!: (id: number) => NotificationType;

    get sortedNotificationTypes(): DashboardNotificationTypeFeedback[] {
        return this.notificationTypes.sort((a, b) => b.selected_period.positive_feedback - a.selected_period.positive_feedback);
    }

    get notificationTypeMessages(): string[] {
        return this.sortedNotificationTypes.map(notificationType => this.translateNotificationMessage(this.notificationTypeById(notificationType.notification_type_id), this.vehicleType));
    }
}

