






















































































import Component from 'vue-class-component';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import { Mixins } from 'vue-property-decorator';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import TranslatesNotificationLevel from '@/components/mixins/TranslatesNotificationLevel';
import UsesNotificationDate from '@/components/mixins/UsesNotificationDate';
import NotificationAttachments from '@/components/notification/NotificationAttachments.vue';
import NotificationLogTable from '@/components/notification/NotificationLogTable.vue';
import NotificationTimeline from '@/components/notification/NotificationTimeline.vue';
import NotificationLevelIcon from '@/components/notification/NotificationLevelIcon.vue';
import NotificationPhoneLog from '@/components/notification/NotificationPhoneLog.vue';
import FormatsDate from '@/components/mixins/FormatsDate';
import KnowledgeBaseLink from '@/components/KnowledgeBaseLink.vue';
import { Farm } from '@/core/interfaces/farm';

@Component({
    components: {
        KnowledgeBaseLink,
        NotificationPhoneLog,
        NotificationLevelIcon,
        NotificationTimeline,
        NotificationLogTable,
        NotificationAttachments,
    },
})
export default class NotificationCardDetails extends Mixins(
    UsesNotificationDetails,
    UsesNotificationMessage,
    UsesNotificationDate,
    TranslatesVehiclePhase,
    TranslatesNotificationLevel,
    FormatsDate,
) {
    get farm(): Farm {
        return this.$store.getters.farmById(this.notification.farm_id);
    }
}
