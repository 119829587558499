




import { Component, Prop, Vue } from 'vue-property-decorator';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import Color from 'color';

@Component({
    components: {},
})
export default class CategoryLabel extends Vue {
    @Prop({ required: true })
    category!: TicketCategory;

    get style(): any {
        return {
            'background-color': Color(this.category.color).lighten(0.8).toString(),
            color: this.category.color,
        };
    }
}

