





















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import { namespace } from 'vuex-class';
import DashboardMap from '@/components/vehicle-dashboard/DashboardMap.vue';
import DashboardCamera from '@/components/vehicle-dashboard/DashboardCamera.vue';
import Loader from '@/components/Loader.vue';
import { Farm } from '@/core/interfaces/farm';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';
import DashboardActionsModal from '@/components/vehicle-dashboard/DashboardActionsModal.vue';
import DashboardStatus from '@/components/vehicle-dashboard/DashboardStatus.vue';
import axios from 'axios';
import { MapData } from '@/core/interfaces/mapData';
import Mousetrap from 'mousetrap';
import { GeoJSON } from 'geojson';
import DashboardExosDriveOffset from '@/components/vehicle-dashboard/features/exos/DashboardExosDriveOffset.vue';
import DashboardLaserscanOffset from '@/components/vehicle-dashboard/features/DashboardLaserscanOffset.vue';
import DashboardDriveOffset from '@/components/vehicle-dashboard/features/DashboardDriveOffset.vue';
import { MapOffset } from '@/core/interfaces/mapOffset';

const vehicleDashboard = namespace('vehicleDashboard');

@Component({
    components: {
        DashboardExosDriveOffset,
        DashboardDriveOffset,
        DashboardStatus,
        Loader,
        DashboardCamera,
        DashboardMap,
    },
})
export default class VehicleDashboard extends Vue {
    @Prop({ required: true })
    private vehicle!: Vehicle;

    @vehicleDashboard.State('definition')
    private definition!: DashboardDefinition;

    @vehicleDashboard.State('paused')
    private paused!: boolean;

    @vehicleDashboard.State('maintenanceMode')
    private maintenanceMode!: boolean;

    @vehicleDashboard.Getter('realtimeMapLayerData')
    private mapRealtimeLayerData!: Record<string, GeoJSON>;

    private camera = false;
    private driveOffsetExos = false;
    private driveOffset = false;
    private mapBackground: Blob | null = null;
    private mapData: MapData | null = null;
    private mapOffset: MapOffset | null = null;

    get farm(): Farm {
        return this.$store.getters.farmById(this.vehicle.farm_id);
    }

    get mapLayers() {
        if (!this.mapData) {
            return null;
        }

        return this.mapData.layers;
    }

    get mapBackgroundResolution() {
        if (!this.mapData) {
            return null;
        }

        return this.mapData.background.resolution;
    }

    get mapBackgroundOffset() {
        if (!this.mapData) {
            return null;
        }

        return this.mapData.background.offset;
    }

    async mounted() {
        await this.$store.dispatch('vehicleDashboard/initVehicle', this.vehicle);

        if (!this.definition) {
            return;
        }

        const promisses: Promise<any>[] = [
            this.$store.dispatch('vehicleDashboard/loadPaused'),
            this.$store.dispatch('vehicleDashboard/loadMaintenanceMode'),
            this.$store.dispatch('vehicleDashboard/loadRealtimeMapLayers'),
            this.loadMapData(),
        ];

        if (this.definition.statusses.length) {
            promisses.push(this.$store.dispatch('vehicleDashboard/loadStatus', this.definition.statusses[0].topic));
        }

        await Promise.all(promisses);

        this.initShortcuts();
    }

    private initShortcuts(): void {
        Mousetrap.bind(this.$t('dashboard.shortcuts.cameras-toggle').toString(), () => {
            this.camera = !this.camera;
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.drive-offset-exos').toString(), () => {
            if (!this.maintenanceMode || !this.definition.features.drive_offset_exos) {
                return;
            }

            this.driveOffsetExos = !this.driveOffsetExos;
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.drive-offset-atj').toString(), () => {
            if (!this.maintenanceMode || !this.definition.features.drive_offset) {
                return;
            }

            this.driveOffset = !this.driveOffset;
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.laserscan-offset-atj').toString(), () => {
            this.openLaserscanOffsetModal();
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.pause-toggle').toString(), () => {
            this.setPause(!this.paused);
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.actions').toString(), e => {
            if (!this.maintenanceMode) {
                return;
            }

            e.stopPropagation();

            this.openActionsModal();
        });

        Mousetrap.bind(this.$t('dashboard.shortcuts.maintenance-mode-toggle').toString(), () => {
            this.setMaintenanceMode(!this.maintenanceMode);
        });
    }

    async loadMapData() {
        const responses = await Promise.all([
            axios.get<Blob>(`${process.env.VUE_APP_API_URL}/farms/${this.farm.id}/map/${this.vehicle.vehicle_type_id}/background`, {
                responseType: 'blob',
            }),
            axios.get<MapData>(`${process.env.VUE_APP_API_URL}/farms/${this.farm.id}/map/${this.vehicle.vehicle_type_id}/json`),
            axios.get<MapOffset>(`${process.env.VUE_APP_API_URL}/farms/${this.farm.id}/vehicle-types/${this.vehicle.vehicle_type_id}/map-offset`),
        ]);

        this.mapBackground = responses[0].data;
        this.mapData = responses[1].data;
        this.mapOffset = responses[2].data;
    }

    beforeDestroy() {
        this.$store.dispatch('vehicleDashboard/close');
        this.$store.dispatch('vehicleDashboard/reset');
    }

    setPause(paused: boolean) {
        this.$store.dispatch('vehicleDashboard/callPause', paused);
    }

    stop() {
        this.$store.dispatch('vehicleDashboard/callStop');
    }

    setMaintenanceMode(maintenanceMode: boolean) {
        this.$store.dispatch('vehicleDashboard/callMaintenanceMode', maintenanceMode);
    }

    openActionsModal() {
        if (!this.maintenanceMode) {
            return;
        }

        this.$modal.show(
            DashboardActionsModal,
            { actions: this.definition.actions },
            { adaptive: true, height: 'auto', classes: 'overflow-visible-modal' },
        );
    }

    openLaserscanOffsetModal() {
        if (!this.maintenanceMode) {
            return;
        }

        this.$modal.show(
            DashboardLaserscanOffset,
            {
                service: this.definition.features.laserscan_offset?.service,
                background: this.mapBackground,
                backgroundResolution: this.mapBackgroundResolution,
                backgroundOffset: this.mapBackgroundOffset,
                layers: this.mapData?.layers.filter(layer => this.definition.features.laserscan_offset?.layer_titles.includes(layer.title)),
            },
            { adaptive: true, height: 'auto', width: '70%', classes: 'overflow-visible-modal' },
        );
    }

    @Watch('maintenanceMode')
    updateMaintanenceMode() {
        if (!this.maintenanceMode) {
            this.driveOffset = false;
            this.driveOffsetExos = false;
        }
    }
}

