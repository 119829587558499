

























































import { Component, Prop } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import DashboardPosition from '@/components/dashboard/DashboardPosition.vue';
import DashboardDifference from '@/components/dashboard/DashboardDifference.vue';
import DashboardFeedbackVehicle from '@/core/interfaces/dashboard/dashboardFeedbackVehicle';
import Vue from 'vue';

@Component({
    components: { DashboardDifference, DashboardPosition },
})
export default class DashboardVehiclesFeedbackWidget extends Vue {
    @Getter('vehicleById')
    vehicleById!: (id: number) => Vehicle;

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Prop({ required: true })
    vehicles!: DashboardFeedbackVehicle[];

    get sortedVehicles(): DashboardFeedbackVehicle[] {
        return this.vehicles.sort((a, b) => {
            return b.selected_period.positive_feedback - a.selected_period.positive_feedback;
        });
    }
}

