import { Component, Vue } from 'vue-property-decorator';
import { intervalToDuration } from 'date-fns';

@Component
export default class FormatsDurationShort extends Vue {
    public formatDurationShort(duration: Duration): string {
        if ((duration.years && duration.years > 0) || (duration.months && duration.months > 0)) {
            return '';
        }

        if (duration.days && duration.days > 0) {
            return `${duration.days}${this.$t('date-formats.days-short')}${duration.hours}${this.$t('date-formats.hours-short')}${duration.minutes}${this.$t('date-formats.minutes-short')}`;
        }

        if (duration.hours && duration.hours > 0) {
            return `${duration.hours}${this.$t('date-formats.hours-short')}${duration.minutes}${this.$t('date-formats.minutes-short')}`;
        }

        if (duration.minutes && duration.minutes > 0) {
            return `${duration.minutes}${this.$t('date-formats.minutes-short')}`;
        }

        return '';
    }

    public formatDurationShortFromNow(date: Date): string {
        return this.formatDurationShort(intervalToDuration({
            start: new Date(),
            end: date,
        }));
    }
}
