
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';
import { degreesToRadians } from '@/helpers/math.helpers';

@Component
export default class DashboardDriveOffset extends Vue {
    @Prop({ required: true })
    private vehicle!: Vehicle;

    private distance = 0;
    private angle = 0;
    private ignoreBumper = false;

    get angleExtra(): string {
        if (this.angle === 0) {
            return '';
        }

        return this.angle > 0 ? this.$t('general.left').toString().toLowerCase() : this.$t('general.right').toString().toLowerCase();
    }

    reset() {
        this.distance = 0;
        this.angle = 0;
        this.ignoreBumper = false;
    }

    drive() {
        const definition = this.$store.state.vehicleDashboard.definition as DashboardDefinition;
        const driveService = definition.features.drive_offset?.drive_service;

        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
            return;
        }

        if (!driveService) {
            throw new Error('drive-service not found');
        }

        this.$store.dispatch('vehicleDashboard/callService', {
            service: driveService,
            args: {
                ignore_bumper: this.hasBumper && this.ignoreBumper,
                offset: this.distance,
            },
        });

        this.ignoreBumper = false;
    }

    turn() {
        const definition = this.$store.state.vehicleDashboard.definition as DashboardDefinition;
        const turnService = definition.features.drive_offset?.turn_service;

        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
            return;
        }

        if (!turnService) {
            throw new Error('turn-service not found');
        }

        this.$store.dispatch('vehicleDashboard/callService', {
            service: turnService,
            args: {
                ignore_bumper: this.ignoreBumper,
                angle: degreesToRadians(this.angle),
            },
        });

        this.ignoreBumper = false;
    }

    get hasBumper(): boolean {
        return (this.$store.state.vehicleDashboard.definition as DashboardDefinition).features.drive_offset?.has_bumper || false;
    }
}

