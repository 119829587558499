import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import { Ticket } from '@/core/interfaces/ticket';
import { TicketsState } from '@/store/modules/tickets/ticketsState';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import { ClosedTicketRequestFilters } from '@/components/tickets/ClosedTicketsFilters.vue';
import { LelyAuth, RelayService, Subscription } from '@tec/frontend-vue-shared';

let relayService: RelayService | null = null;
let ticketSubscriptions: Subscription<Ticket>[] = [];

function createRelay(): RelayService {
    if (!relayService) {
        if (!process.env.VUE_APP_TICKET_WS_URL) {
            throw new Error('VUE_APP_TICKET_WS_URL not provided');
        }

        relayService = new RelayService(process.env.VUE_APP_TICKET_WS_URL, 'farm_id', 'alarmcenter', () => String(LelyAuth.getAccessToken()), true);
    }

    return relayService;
}

const actions: ActionTree<TicketsState, RootState> = {
    syncAll: async(context: ActionContext<TicketsState, RootState>) => {
        await Promise.all([context.dispatch('syncOpenTickets'), context.dispatch('syncCategories')]);
    },
    syncOpenTickets: async(context: ActionContext<TicketsState, RootState>) => {
        const response = await axios.get<Ticket[]>('/tickets');
        context.commit('setOpenTickets', response.data);
    },
    syncClosedTickets: async(context: ActionContext<TicketsState, RootState>, payload: ClosedTicketRequestFilters) => {
        const response = (await axios.get<Ticket[]>('/tickets/closed', {
            params: payload,
        }));

        context.commit('setTotalNumberOfClosedTickets', Number(response.headers['lely-number-of-total'] || 0));
        context.commit('setClosedTickets', response.data);
    },
    syncMessages: async(context: ActionContext<TicketsState, RootState>, payload: number) => {
        const response = await axios.get<TicketMessage[]>(`/tickets/${payload}/messages`);
        context.commit('setTicketMessages', response.data);
    },
    addMessage: async(context: ActionContext<TicketsState, RootState>, payload: { ticketId: number; message: TicketMessage }) => {
        return (await axios.post<TicketMessage>(`/tickets/${payload.ticketId}/messages`, payload.message)).data;
    },
    updateMessage: async(context: ActionContext<TicketsState, RootState>, payload: { ticketId: number; messageId: number; body: string }) => {
        await axios.patch(`/tickets/${payload.ticketId}/messages/${payload.messageId}`, {
            body: payload.body,
        });
    },
    deleteMessage: async(context: ActionContext<TicketsState, RootState>, payload: { ticketId: number; messageId: number }) => {
        await axios.delete(`/tickets/${payload.ticketId}/messages/${payload.messageId}`);
    },
    addPhoto: async(context: ActionContext<TicketsState, RootState>, payload: { ticketId: number; messageId: number; attachment: Blob }) => {
        await axios.post(`/tickets/${payload.ticketId}/messages/${payload.messageId}/photo`, payload.attachment, {
            headers: {
                'Content-Type': payload.attachment.type,
            },
        });
    },
    syncTicket: async(context: ActionContext<TicketsState, RootState>, payload: number) => {
        const response = await axios.get<Ticket>(`/ticket/${payload}`);
        context.commit('setTicket', response.data);
    },
    syncCategories: async(context: ActionContext<TicketsState, RootState>) => {
        const response = await axios.get<TicketCategory[]>('/ticket-categories');
        context.commit('setCategories', response.data);
    },
    addTicket: async(context: ActionContext<TicketsState, RootState>, payload: Partial<Ticket>): Promise<Ticket> => {
        const response = await axios.post<Ticket>('/tickets', payload);
        context.commit('setTicket', response.data);

        return response.data;
    },
    updateTicket: async(context: ActionContext<TicketsState, RootState>, payload: Partial<Ticket>): Promise<Ticket> => {
        const response = await axios.patch<Ticket>(`/tickets/${payload.id}`, payload);
        context.commit('setTicket', response.data);

        return response.data;
    },
    subscribeTickets: async(context: ActionContext<TicketsState, RootState>) => {
        ticketSubscriptions.forEach(subscription => createRelay().unsubscribe(subscription));

        const ticketSubscription = await createRelay().subscribe<Ticket>(0, 'ticket');
        ticketSubscription.setCallback(ticket => context.commit('setTicket', ticket));

        const messageModifiedSubscription = await createRelay().subscribe<Ticket>(0, 'ticket_message_modified');
        messageModifiedSubscription.setCallback(message => context.commit('updateTicketMessage', message));

        const messageDeletedSubscription = await createRelay().subscribe<Ticket>(0, 'ticket_message_delete');
        messageDeletedSubscription.setCallback(message => context.commit('deleteTicketMessage', message));

        ticketSubscriptions = [
            ticketSubscription,
            messageModifiedSubscription,
            messageDeletedSubscription,
        ];
    },
};

export default actions;
