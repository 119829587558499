import { SettingsState } from '@/store/modules/settings/settingsState';
import { Rule } from '@/core/interfaces/rule';
import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { areIntervalsOverlapping, parseISO } from 'date-fns';
import { Timetable } from '@/core/interfaces/timetable';

const getters: GetterTree<SettingsState, RootState> = {
    rulesByVehicleType: (state: SettingsState) => (vehicleTypeId: number): Rule[] => state.rules
        .filter(rule => vehicleTypeId ? rule.vehicle_type_id === vehicleTypeId || rule.vehicle_type_id === null : rule.vehicle_type_id === null),
    timetable: (state: SettingsState) => (interval: { start: Date; end: Date }, roleIds?: number[]) => state.timetable
        .filter(timetable => roleIds ? roleIds.includes(timetable.role_id) : true)
        .filter(timetable => areIntervalsOverlapping(interval, {
            start: parseISO(timetable.start_date),
            end: parseISO(timetable.end_date),
        })),
    timetableById: (state: SettingsState) => (id: number): Timetable | undefined => state.timetable
        .find(timetable => timetable.id === id),
};

export default getters;
