



















import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import DropdownMenu from '@innologica/vue-dropdown-menu';
import { User } from '@/core/interfaces/user';
import { Getter } from 'vuex-class';

export interface ActionItem {
    label: string;
    icon: string;
    btnClass: string;
    condition?: () => boolean;
    action?: () => void;
}

@Component({
    components: {
        DropdownMenu,
    },
})
export default class Actions extends Vue {
    @Getter('currentUser')
    protected currentUser!: User;

    @Prop({ required: true })
    protected actions!: ActionItem[];

    @Prop({ required: false, default: false })
    protected smallBtn!: boolean;

    @Prop({ required: true })
    protected value!: boolean;

    get filteredActions(): ActionItem[] {
        return this.actions.filter(action => !action.condition || action.condition());
    }

    async execute(action: ActionItem) {
        this.$emit('input', false);

        if (action.action) {
            action.action();
        }
    }
}
