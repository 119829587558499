import { ActionContext, ActionTree } from 'vuex';
import { RunsState } from '@/store/modules/runs/runsState';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { RunsAndNotificationsPayload } from '@/store/modules/runs/mutations';

const actions: ActionTree<RunsState, RootState> = {
    syncRuns: async(context: ActionContext<RunsState, RootState>) => {
        const onlyOpen = axios.get<RunsAndNotificationsPayload>('/notifications', {
            params: {
                onlyOpen: true,
                limit: 500,
            },
        });
        const assigned = axios.get<RunsAndNotificationsPayload>('/notifications', {
            params: {
                onlyAssigned: true,
                limit: 500,
            },
        });
        const response = await Promise.all([onlyOpen, assigned]);

        const uniquePayload = response
            .map(response => response.data)
            .reduce((acc: RunsAndNotificationsPayload, item: RunsAndNotificationsPayload) => {
                item.notifications.forEach(itemNotification => {
                    if (!acc.notifications.some(notification => notification.id === itemNotification.id)) {
                        acc.notifications.push(itemNotification);
                    }
                });

                item.notification_runs.forEach(itemRun => {
                    if (!acc.notification_runs.some(run => run.id === itemRun.id)) {
                        acc.notification_runs.push(itemRun);
                    }
                });

                return acc;
            }, { notification_runs: [], notifications: [] } as RunsAndNotificationsPayload);

        context.commit('setRunsAndNotifications', uniquePayload);
    },
    syncRun: async({ commit }, runId) => {
        const res = await axios.get(`/notification-runs/${runId}`);
        commit('updateRun', res.data);
    },
    syncNotification: async({ commit }, id) => {
        const res = await axios.get(`/notifications/${id}`);
        commit('updateNotification', res.data);
    },
    closeRun: async(context: ActionContext<RunsState, RootState>, { run }) => {
        await axios.post(`/notification-runs/${run.id}/close`);
        await context.dispatch('syncRun', run.id);
    },
    closeNotification: async(context: ActionContext<RunsState, RootState>, { notification }) => {
        const response = await axios.post(`/notifications/${notification.id}/close`);
        await context.commit('updateNotification', response.data);
    },
    unassignNotification: async(context, { notification }) => {
        const response = await axios.post(`/notifications/${notification.id}/unassign`);
        await context.commit('updateNotification', response.data);
    },
    assignNotificationToUser: async(context, { user, notification }) => {
        const response = await axios.post(`/notifications/${notification.id}/assign-user`, {
            user_id: user === null ? null : user.id,
        });
        await context.commit('updateNotification', response.data);
    },
    assignNotificationToRole: async(context, { role, notification }) => {
        const response = await axios.post(`/notifications/${notification.id}/assign-role`, {
            role_id: role === null ? null : role.id,
        });
        await context.commit('updateNotification', response.data);
    },
    notifyNotification: async(context, { notification, notificationNotifyRequest }) => {
        const response = await axios.post(`/notifications/${notification.id}/notify`, notificationNotifyRequest);
        await context.commit('updateNotification', response.data);
    },
    resolveNotification: async(context, { notification, user }) => {
        const response = await axios.post(`/notifications/${notification.id}/resolve-user`, {
            user_id: user === null ? null : user.id,
        });
        await context.commit('updateNotification', response.data);
    },
};

export default actions;
