


























































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FormatsDate from '@/components/mixins/FormatsDate';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import UsesTicket from '@/components/mixins/UsesTicket';
import NewIndicator from '@/components/NewIndicator.vue';

@Component({
    components: { NewIndicator, CategoryLabel },
})
export default class TicketCard extends mixins(FormatsDate, UsesTicket) {
    get selected(): boolean {
        return this.$route.query.code === this.ticket.code;
    }

    get unseen(): boolean {
        return !this.$store.getters['tickets/isTicketSeen'](this.ticket.id);
    }

    get nrOfSeenMessages(): number {
        return this.$store.getters['tickets/nrOfSeenMessagesByTicket'](this.ticket.id);
    }

    get hasUnseenMessages(): boolean {
        return !this.unseen && this.ticket.message_count > this.nrOfSeenMessages;
    }
}

