var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"avatar",class:( _obj = {
        'avatar--me': _vm.isMe,
        'avatar--role': _vm.isRole,
        'avatar--user': _vm.isUser,
        'avatar--small': _vm.small
     }, _obj[("avatar--" + (this.type))] = true, _obj )},[_c('i',{staticClass:"icon-center",class:_vm.iconClass}),_vm._v(" "+_vm._s(_vm.whoShort)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }