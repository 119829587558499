




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Role } from '@/core/interfaces/role';
import { User } from '@/core/interfaces/user';

@Component({
    components: {},
})
export default class RoleForm extends Vue {
    @Action('saveRole')
    saveRole!: (payload: {role: Partial<Role>; users: number[]}) => Promise<void>;

    @Action('deleteRole')
    deleteRoleAction!: (id: number) => Promise<void>;

    @Getter('usersSet')
    users!: User[];

    @Getter('usersByRoleId')
    usersByRoleId!: (id: number) => User[];

    @Getter('userById')
    userById!: (id: number) => User;

    @Prop()
    initialRole?: Role;

    assignable = false;
    notifyable = false;
    codeName = '';
    selectedUserIds: number[] = [];

    async mounted(): Promise<void> {
        if (this.initialRole) {
            this.assignable = this.initialRole.assignable;
            this.notifyable = this.initialRole.notifyable;
            this.codeName = this.initialRole.code_name;
            this.selectedUserIds = this.usersByRoleId(this.initialRole.id).map(user => user.id);
        }
    }

    get roleFromForm(): Partial<Role> {
        return {
            id: this.initialRole?.id,
            assignable: this.assignable,
            notifyable: this.notifyable,
            code_name: this.codeName,
        };
    }

    async submit(): Promise<void> {
        if (!this.codeName) {
            return;
        }

        await this.saveRole({ role: this.roleFromForm, users: this.selectedUserIds });

        this.$emit('submitted');
    }

    async deleteRole(): Promise<void> {
        if (!this.initialRole?.id) {
            return;
        }

        const confirm = await this.$bvModal
            .msgBoxConfirm(this.$t('settings.roles.delete-confirmation', { role: this.initialRole.code_name }).toString(), {
                centered: true,
                size: 'sm',
            });

        if (!confirm) {
            return;
        }

        await this.deleteRoleAction(this.initialRole.id);

        this.$emit('deleted');
    }

    get selectedUsers(): User[] {
        return this.selectedUserIds.map(id => this.userById(id));
    }
}

