import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Run } from '@/core/interfaces/run';
import RunNotificationsModal from '@/components/run/RunNotificationsModal.vue';

@Component
export default class OpensRunNotificationsModal extends Vue {
    async openRunNotificationsModal(run: Run, initialNotificationId?: number, closedFn?: () => {}): Promise<void> {
        this.$modal.show(
            RunNotificationsModal,
            { run, initialNotificationId },
            { adaptive: true, height: 'auto', width: '95%', shiftY: 0.05 },
            {
                closed: () => closedFn ? closedFn() : undefined,
            },
        );
    }
}
