import { Component, Vue } from 'vue-property-decorator';
import { NotificationType } from '@/core/interfaces/notificationType';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { Notification } from '@/core/interfaces/notification';

@Component
export default class UsesNotificationMessage extends Vue {
    public get notificationMessage(): string {
        // @ts-ignore
        if (!this.notificationType || !this.vehicleType) {
            return '-';
        }

        // @ts-ignore
        return this.$i18n.t(`notification-codes.${this.vehicleType.name}.${this.notificationType.code}`, this.notification?.meta || []).toString() || '-';
    }

    translateNotificationMessage(notificationType: NotificationType, vehicleType: VehicleType, notification?: Notification): string {
        return this.$i18n.t(`notification-codes.${vehicleType.name}.${notificationType.code}`, notification?.meta || []).toString() || '-';
    }
}
