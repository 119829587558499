import { ActionContext, ActionTree } from 'vuex';
import { RelayState } from '@/store/modules/relay/relayState';
import { RootState } from '@/store/rootState';
import axios from 'axios';

const actions: ActionTree<RelayState, RootState> = {
    syncConnections: async(context: ActionContext<RelayState, RootState>) => {
        const response = await axios.get('https://lely-imow-relay.azurewebsites.net/api/info/connections');

        context.commit('setConnections', response.data);
    },

    syncMachines: async(context: ActionContext<RelayState, RootState>) => {
        const response = await axios.get('https://lely-imow-relay.azurewebsites.net/api/info/machines');

        context.commit('setMachines', response.data);
    },

    resetStats: async(context: ActionContext<RelayState, RootState>) => {
        await axios.post('https://lely-imow-relay.azurewebsites.net/api/info/reset');

        await Promise.all([
            context.dispatch('syncConnections'),
            context.dispatch('syncMachines'),
        ]);
    },
};

export default actions;
