










































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { Role } from '@/core/interfaces/role';
import { UserRole } from '@/core/interfaces/userRole';
import { User } from '@/core/interfaces/user';
import RoleForm from '@/components/settings/role/RoleForm.vue';

@Component({
    components: { RoleForm },
})
export default class RolesView extends Vue {
    @Getter('rolesSet')
    roles!: Role[];

    @Action('deleteRole')
    deleteRoleAction!: (role: number) => Promise<void>;

    @State('userRoles')
    userRoles!: UserRole[];

    @Getter('userById')
    userById!: (id: number) => User;

    formRole: Role | null = null;

    get userPerRole(): User[][] {
        return this.roles.map(role => this.userRoles
            .filter(userRole => userRole.role_id === role.id)
            .map(userRole => this.userById(userRole.user_id))
            .filter(user => !!user),
        );
    }

    editRole(role: Role): void {
        this.formRole = role;
        this.$bvModal.show('role-form');
    }

    addRole(): void {
        this.formRole = null;
        this.$bvModal.show('role-form');
    }

    async deleteRole(role: Role): Promise<void> {
        const confirm = await this.$bvModal
            .msgBoxConfirm(this.$t('settings.roles.delete-confirmation', { role: role.code_name }).toString(), {
                centered: true,
                size: 'sm',
            });

        if (!confirm) {
            return;
        }

        await this.deleteRoleAction(role.id);
    }
}

