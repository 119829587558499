






import { Component, Vue } from 'vue-property-decorator';
import NavigationTop from '@/components/navigation/NavigationTop.vue';

@Component({
    components: {
        NavigationTop,
    },
})
export default class LoginLayout extends Vue {

}

