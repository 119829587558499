







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { VehicleType } from '@/core/interfaces/vehicleType';
import vSelect from 'vue-select';
import { visibleVehicleTypeIds } from '@/store/getters';

@Component({
    components: { vSelect },
})
export default class VehiclesFilters extends Vue {
    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];

    @Getter('visibleVehicleTypeIds')
    visibleVehicleTypeIds!: number[];

    @Mutation('setVisibleVehicleTypeIds')
    setVisibleVehicleTypeIds!: (ids: number[]) => void;

    query = '';
    farmId: number | null = null;

    @Prop({ required: true })
    value!: { farmId: number | null; vehicleTypeIds: number[]; query: string };

    reset(): void {
        this.$emit('input', {
            query: '',
            farmId: null,
        });

        this.setVisibleVehicleTypeIds([]);
    }

    @Watch('value', { immediate: true })
    setFilters() {
        this.query = this.value.query;
        this.farmId = this.value.farmId;
    }

    @Watch('query')
    @Watch('farmId')
    emit() {
        this.$emit('input', {
            query: this.query,
            farmId: this.farmId,
        });
    }
}

