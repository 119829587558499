






import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({})
export default class GroupRunsButton extends Vue {
    get groupRuns(): boolean {
        return this.$store.state.groupRuns;
    }

    set groupRuns(value: boolean) {
        this.$store.commit('setGroupRuns', value);
    }
}
