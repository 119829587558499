











































import { Component, Vue } from 'vue-property-decorator';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import { Ticket } from '@/core/interfaces/ticket';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { format, parseISO } from 'date-fns';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import ClosedTicketsFilters, { ClosedTicketRequestFilters } from '@/components/tickets/ClosedTicketsFilters.vue';
import Loader from '@/components/Loader.vue';
import TicketDetails from '@/components/tickets/TicketDetails.vue';
import { Getter } from 'vuex-class';
import Pagination from '@/components/Pagination.vue';

@Component({
    components: { TicketDetails, ClosedTicketsFilters, SidebarLayout, Loader, Pagination },
})
export default class ClosedTicketsView extends Vue {
    loading = false;

    openedTicketId: number | null = null;

    page = 1;

    recordsPerPage = 50;

    filters: ClosedTicketRequestFilters | null = null;

    @Getter('tickets/closedTickets')
    tickets!: Ticket[];

    mounted(): void {
        this.$store.dispatch('tickets/syncAll');
        this.fetchClosedTickets();
    }

    get columns(): LelyTableColumnsConfig<Ticket> {
        return [
            {
                header: this.$t('tickets.category').toString(),
                value: ticket => this.$createElement(CategoryLabel, {
                    props: {
                        category: this.$store.getters['tickets/categoryById'](ticket.ticket_category_id),
                    },
                }),
                shrink: true,
            },
            {
                header: this.$t('tickets.subject').toString(),
                value: 'subject',
                sub: 'code',
                options: {
                    wrap: true,
                },
            },
            {
                header: this.$t('general.created').toString(),
                value: ticket => format(parseISO(ticket.created_at), 'yyyy-MM-dd'),
                sub: ticket => ticket.created_by_user
                    ? this.$store.getters.userById(ticket.created_by_user).username
                    : ticket.created_by_vehicle ? this.$store.getters.vehicleById(ticket.created_by_vehicle).name : '',
            },
            {
                header: this.$t('tickets.closed').toString(),
                value: ticket => ticket.closed_at ? format(parseISO(ticket.closed_at), 'yyyy-MM-dd') : null,
                sub: ticket => ticket.closed_at ? this.$store.getters.userById(ticket.closed_by).username : null,
            },
            {
                header: this.$t('general.farm').toString(),
                value: ticket => this.$store.getters.farmById(ticket.farm_id).name,
            },
            {
                header: this.$t('general.vehicle').toString(),
                value: ticket => ticket.vehicle_id ? this.$store.getters.vehicleById(ticket.vehicle_id).name : null,
            },
            {
                header: this.$t('tickets.assigned').toString(),
                value: ticket => ticket.assigned_to ? this.$store.getters.userById(ticket.assigned_to).username : null,
            },
        ];
    }

    get openedTicket(): Ticket | null {
        if (!this.openedTicketId) {
            return null;
        }

        return this.$store.getters['tickets/ticketById'](this.openedTicketId);
    }

    async fetchClosedTickets(filters?: ClosedTicketRequestFilters): Promise<void> {
        this.loading = true;

        if (filters) {
            this.filters = filters;
        }

        await this.$store.dispatch('tickets/syncClosedTickets', {
            ...this.filters,
            limit: this.recordsPerPage,
            offset: this.offset,
        });

        this.loading = false;
    }

    openTicket(ticket: Ticket): void {
        this.openedTicketId = ticket.id;
        this.$bvModal.show('ticket-modal');
    }

    get offset(): number {
        return (this.page - 1) * this.recordsPerPage;
    }

    get totalNumberOfClosedTickets(): number {
        return this.$store.state.tickets.totalNumberOfClosedTickets;
    }

    setPage(page: number) {
        this.page = page;

        this.fetchClosedTickets();
    }
}

