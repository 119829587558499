










import { Component, Mixins, Prop } from 'vue-property-decorator';
import NotifyLog from '@/core/interfaces/notifyLog';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';
import FormatsDate from '@/components/mixins/FormatsDate';
import GetChannelsFromNotifyLog from '@/components/mixins/GetChannelsFromNotifyLog';

@Component({})
export default class NotifiedRolesIndicator extends Mixins(FormatsDurationShort, FormatsDate, GetChannelsFromNotifyLog) {
    @Prop({ required: true })
    protected log!: NotifyLog[];

    get roles(): { name: string; isSent: boolean; tooltip: string }[] {
        if (!this.log) {
            return [];
        }

        return Object.entries(this.log.reduce((acc: Record<number, NotifyLog>, log: NotifyLog) => {
            if (typeof acc[log.role_id] === 'undefined') {
                acc[log.role_id] = log;
            } else {
                if ((log.send_at && !acc[log.role_id].send_at) ||
                    (Date.parse(log.send_at) < Date.parse(acc[log.role_id].send_at)) ||
                    (Date.parse(log.scheduled_at) < Date.parse(acc[log.role_id].scheduled_at))) {
                    acc[log.role_id] = log;
                }
            }

            return acc;
        }, {})).map(([roleId, log]) => {
            const channels = this.getChannelsFromNotifyLog(log);
            const role = this.$store.getters.roleById(roleId);

            return {
                name: role ? this.$t(`roles.${role.code_name}`).toString() : '',
                isSent: !!log.send_at,
                tooltip: !log.send_at
                    ? this.$t('notifications.notify-tooltip-send-in', {
                        duration: this.formatDurationShortFromNow(new Date(log.scheduled_at)),
                        channels: channels.join(', '),
                    }).toString()
                    : this.$t('notifications.notify-tooltip-send-at', {
                        date: this.formatDate(new Date(log.send_at)),
                        channels: channels.join(', '),
                    }).toString(),
            };
        });
    }
}
