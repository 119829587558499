import { render, staticRenderFns } from "./DashboardVehiclesWidget.vue?vue&type=template&id=f0ea8038&scoped=true&"
import script from "./DashboardVehiclesWidget.vue?vue&type=script&lang=ts&"
export * from "./DashboardVehiclesWidget.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardVehiclesWidget.vue?vue&type=style&index=0&id=f0ea8038&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0ea8038",
  null
  
)

export default component.exports