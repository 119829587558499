import { KnowledgeBaseState } from './knowledgeBaseState';
import { KnowledgeBaseSection } from '@/core/interfaces/knowledgeBaseSection';

const mutations = {
    setSections: (state: KnowledgeBaseState, payload: KnowledgeBaseSection[]) => {
        state.sections = payload;
    },
};

export default mutations;
