



















import { Component } from 'vue-property-decorator';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import { Getter } from 'vuex-class';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({
    components: { TopnavLayout },
})
export default class KnowledgeBaseIndexView extends UsesNotificationMessage {
    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];
}

