































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import AddTicketModal from '@/components/tickets/AddTicketModal.vue';
import TicketCard from '@/components/tickets/TicketCard.vue';
import { Ticket } from '@/core/interfaces/ticket';
import TicketDetails from '@/components/tickets/TicketDetails.vue';
import { Getter } from 'vuex-class';
import VisibleVehicleTypesFilter from '@/components/VisibleVehicleTypesFilter.vue';

@Component({
    components: { VisibleVehicleTypesFilter, TicketDetails, TicketCard, AddTicketModal, SidebarLayout },
})
export default class TicketsView extends Vue {
    @Prop({ required: true })
    view!: 'unassigned' | 'assigned';

    @Getter('tickets/openTickets')
    openTickets!: Ticket[];

    @Getter('tickets/assignedToMe')
    ticketsAssignedToMe!: Ticket[];

    @Getter('tickets/assignedToOthers')
    ticketsAssignedToOthers!: Ticket[];

    mounted(): void {
        this.$store.dispatch('tickets/syncAll');
    }

    get openedTicket(): Ticket | null {
        if (!this.$route.query.code) {
            return null;
        }

        return this.$store.getters['tickets/ticketByCode'](this.$route.query.code);
    }

    @Watch('openedTicket', { immediate: true })
    scrollToOpenedTicket(newValue: Ticket, oldValue: Ticket): void {
        if (!this.openedTicket || oldValue?.id === newValue?.id) {
            return;
        }

        this.$nextTick(() => {
            // @ts-ignore
            const ticketCard = ((this.$refs.openedTicket[0] as TicketCard).$el as HTMLElement);
            const ticketCardTop = ticketCard.offsetTop;

            const overview = (this.$refs.overview as HTMLElement);
            const overviewTop = overview.scrollTop;
            const overviewBottom = (overview.scrollTop + overview.offsetHeight);

            if (ticketCardTop < overviewTop || ticketCardTop > overviewBottom) {
                ticketCard.scrollIntoView({ block: 'center' });
            }
        });
    }
}

