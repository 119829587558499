import { Component, Vue } from 'vue-property-decorator';
import { Phone } from '@/core/interfaces/phone';
import { Getter } from 'vuex-class';
import { User } from '@/core/interfaces/user';

@Component
export default class UsesUsernameOfPhone extends Vue {
    @Getter('userById')
    userById!: (id: number) => User;

    public getUsernameOfPhone(phone: Phone): string {
        if (!phone.user_id) {
            return '-';
        }

        return this.userById(phone.user_id).username;
    }
}
