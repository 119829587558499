import { Component, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';

@Component
export default class FormatsDate extends Vue {
    public formatDate(date: Date | string, dateFormat = 'yyyy-MM-dd HH:mm'): string {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return format(date, dateFormat);
    }
}
