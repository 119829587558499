











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Rule } from '@/core/interfaces/rule';
import SettingsRuleTableRow from '@/components/settings/rule/SettingsRuleTableRow.vue';

@Component({
    components: { SettingsRuleTableRow },
})
export default class SettingsRuleTable extends Vue {
    @Prop({ required: true })
    protected rules!: Rule[];
}
