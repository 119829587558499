import { Module } from 'vuex';
import { RootState } from '@/store/rootState';
import { SettingsState } from './settingsState';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

const settings: Module<SettingsState, RootState> = {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};

export default settings;
