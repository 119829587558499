




import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import MapMixin from '@/components/mixins/MapMixin';
import L, { LayerGroup } from 'leaflet';
import { MapLayer } from '@/core/interfaces/mapLayer';
import { GeoJSON } from 'geojson';
import { RealtimeMapLayer } from '@/core/interfaces/realtimeMapLayer';
import { MapOffset } from '@/core/interfaces/mapOffset';

@Component
export default class DashboardMap extends Mixins(MapMixin) {
    @Prop({ required: true })
    private background!: Blob;

    @Prop({ required: true })
    private layers!: MapLayer[];

    @Prop()
    private realtimeLayers!: RealtimeMapLayer[];

    @Prop()
    private realtimeLayersData!: Record<string, GeoJSON>;

    @Prop({ required: true })
    private backgroundResolution!: number;

    @Prop()
    private backgroundOffset!: { x: number; y: number } | null;

    @Prop({ required: true })
    private mapOffset!: MapOffset;

    private realtimeLayerGroups: Record<string, { layer: LayerGroup; color: string }> = {};

    private async mounted(): Promise<void> {
        const offset = this.backgroundOffset ? this.getLatLngTupleFromOffset(this.backgroundOffset) : undefined;

        await this.initMap({
            elem: this.$refs.map as HTMLElement,
            offset,
            mapOffset: [this.mapOffset.offset_lat, this.mapOffset.offset_lng],
            background: {
                image: this.background,
                resolution: this.backgroundResolution,
                offset,
            },
            layers: this.layers,
        });

        if (this.realtimeLayers) {
            this.setRealtimeLayers();
        }
    }

    @Watch('realtimeLayers')
    private setRealtimeLayers(): void {
        if (!this.realtimeLayers || !this.realtimeLayers.length) {
            return;
        }

        this.realtimeLayers.forEach(realtimeLayer => {
            const layer = new L.LayerGroup();
            layer.addTo(this.map);

            const color = realtimeLayer.color || this.getColor();
            this.addLayerToControl(realtimeLayer.label, color, layer);

            this.realtimeLayerGroups[realtimeLayer.topic] = { layer, color };
        });
    }

    @Watch('realtimeLayersData')
    private setRealtimeLayersData(): void {
        Object.entries(this.realtimeLayersData).forEach(([topic, geojson]) => {
            // eslint-disable-next-line no-prototype-builtins
            if (!this.realtimeLayerGroups.hasOwnProperty(topic)) {
                return;
            }

            const layer = this.realtimeLayerGroups[topic].layer;

            layer.clearLayers();
            layer.addLayer(this.createGeoJSONLayer(geojson, this.realtimeLayerGroups[topic].color));
        });
    }
}

