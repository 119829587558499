import { render, staticRenderFns } from "./BaseItemCard.vue?vue&type=template&id=54a214fa&scoped=true&"
import script from "./BaseItemCard.vue?vue&type=script&lang=ts&"
export * from "./BaseItemCard.vue?vue&type=script&lang=ts&"
import style0 from "./BaseItemCard.vue?vue&type=style&index=0&id=54a214fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a214fa",
  null
  
)

export default component.exports