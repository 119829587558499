enum NotificationLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const NotificationLevelMapping = [
    NotificationLevel.INFO,
    NotificationLevel.WARNING,
    NotificationLevel.ERROR,
];

export default NotificationLevel;

export const AllNotificationLevels = [NotificationLevel.INFO, NotificationLevel.WARNING, NotificationLevel.ERROR];
