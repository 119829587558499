








import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RelayMachine } from '@/core/interfaces/relayMachine';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { RelayMachineStats } from '@/core/interfaces/relayMachineStats';
import { RelayTopic } from '@/core/interfaces/relayTopic';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { mixins } from 'vue-class-component';
import FormatsBytes from '@/components/mixins/FormatsBytes';
import RelayMachineClients from '@/components/relay/RelayClients.vue';

const relayStore = namespace('relay');

@Component({
    components: { RelayMachineClients },
})
export default class RelayMachineTopics extends mixins(FormatsBytes) {
    @relayStore.Getter('connectionByUuid')
    connectionByUuid!: (uuid: string) => RelayConnection;

    @Prop({ required: true })
    machine!: RelayMachine;

    get columns(): LelyTableColumnsConfig<{ topic: RelayTopic; stats: RelayMachineStats | null }> {
        return [
            {
                header: 'Topic',
                value: topic => topic.topic.name,
                sub: topic => topic.topic.type,
            },
            {
                header: 'Messages',
                right: true,
                value: topic => {
                    if (!topic.stats) {
                        return '';
                    }

                    return topic.stats.message_count;
                },
            },
            {
                header: 'Clients',
                right: true,
                value: topic => {
                    return topic.topic.clients.length;
                },
            },
            {
                header: 'Usage',
                right: true,
                value: (topic) => {
                    if (!topic.stats) {
                        return '';
                    }

                    return this.$createElement('p', [
                        this.$createElement('span', { class: 'd-inline-flex align-items-center' }, [
                            this.$createElement('i', { class: 'lely-icon-caret-right' }),
                            this.formatBytes(topic.stats?.bytes_to_machine),
                        ]),
                        this.$createElement('br'),
                        this.$createElement('span', { class: 'd-inline-flex align-items-center' }, [
                            this.$createElement('i', { class: 'lely-icon-caret-left' }),
                            this.formatBytes(topic.stats?.bytes_from_machine),
                        ]),
                    ]);
                },
            },
        ];
    }

    statsByTopic(topic: string): RelayMachineStats | null {
        return this.machine.stats.find(stats => stats.name === topic) || null;
    }

    get topics(): { topic: RelayTopic; stats: RelayMachineStats | null }[] {
        return this.machine.topics.map(topic => ({
            topic,
            stats: this.statsByTopic(topic.name),
        }));
    }
}

