import { Component, Prop, Vue } from 'vue-property-decorator';
import { NotificationType } from '@/core/interfaces/notificationType';
import { Notification } from '@/core/interfaces/notification';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import { Vehicle } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { User } from '@/core/interfaces/user';
import { Role } from '@/core/interfaces/role';

@Component
export default class UsesNotificationDetails extends Vue {
    @Prop({ required: true })
    public notification!: Notification;

    protected get vehicle(): Vehicle {
        return this.$store.getters.vehicleById(this.notification.vehicle_id);
    }

    protected get vehicleType(): VehicleType {
        return this.$store.getters.vehicleTypeById(this.notification.vehicle_type_id);
    }

    protected get notificationType(): NotificationType {
        return this.$store.getters.notificationTypeById(this.notification.notification_type_id);
    }

    protected get vehiclePhase(): VehiclePhase {
        return this.$store.getters.vehiclePhaseById(this.notification.vehicle_phase_id);
    }

    protected get assignedTo(): User | Role | null {
        if (this.notification.assigned_to_user_id) {
            return this.$store.getters.userById(this.notification.assigned_to_user_id);
        }

        if (this.notification.assigned_to_role_id) {
            return this.$store.getters.roleById(this.notification.assigned_to_role_id);
        }

        return null;
    }

    protected get resolvedBy(): User | null {
        if (this.notification.resolved_by_user_id) {
            return this.$store.getters.userById(this.notification.resolved_by_user_id);
        }

        return null;
    }

    protected get assignedToMe(): boolean {
        return this.$store.getters['runs/notificationIsAssignedToMe'](this.notification);
    }

    protected get assignedBy(): User {
        return this.$store.getters.userById(this.notification.assigned_by_user_id);
    }
}
