import { TicketsState } from '@/store/modules/tickets/ticketsState';
import { Ticket } from '@/core/interfaces/ticket';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import Vue from 'vue';
import { TicketMessage } from '@/core/interfaces/ticketMessage';

const setTicket = (state: TicketsState, payload: Ticket) => {
    const currentIndex = state.tickets.findIndex(tickets => tickets.id === payload.id);

    if (currentIndex > -1) {
        Vue.set(state.tickets, currentIndex, payload);
    } else {
        state.tickets.push(payload);
    }
};

const mutations = {
    setTicketMessages: (state: TicketsState, payload: TicketMessage[]) => {
        state.messages = payload;
    },
    updateTicketMessage: (state: TicketsState, payload: TicketMessage) => {
        const currentIndex = state.messages.findIndex(message => message.id === payload.id);

        if (currentIndex === -1) {
            state.messages.push(payload);
        } else {
            Vue.set(state.messages, currentIndex, payload);
        }
    },
    deleteTicketMessage: (state: TicketsState, payload: TicketMessage) => {
        state.messages = state.messages.filter(message => message.id !== payload.id);
    },
    setTicket,
    setCategories: (state: TicketsState, payload: TicketCategory[]) => {
        state.categories = payload;
    },
    setTicketSeen: (state: TicketsState, payload: Ticket) => {
        if (!state.seenTickets.map(ticket => ticket.id).includes(payload.id)) {
            state.seenTickets.push(payload);
        }
    },
    setMessageSeen: (state: TicketsState, payload: TicketMessage) => {
        if (!state.seenMessages.map(message => message.id).includes(payload.id)) {
            state.seenMessages.push(payload);
        }
    },
    setOpenTickets: (state: TicketsState, payload: Ticket[]) => {
        const idsInPayload = payload.map(ticket => ticket.id);

        state.tickets = state.tickets.filter(ticket => ticket.closed_at || idsInPayload.includes(ticket.id));
        payload.forEach(ticket => setTicket(state, ticket));
    },
    setClosedTickets: (state: TicketsState, payload: Ticket[]) => {
        const idsInPayload = payload.map(ticket => ticket.id);

        state.tickets = state.tickets.filter(ticket => !ticket.closed_at || idsInPayload.includes(ticket.id));
        payload.forEach(ticket => setTicket(state, ticket));

        state.closedTicketIds = payload.map(ticket => ticket.id);
    },
    setTotalNumberOfClosedTickets: (state: TicketsState, payload: number) => {
        state.totalNumberOfClosedTickets = payload;
    },
};

export default mutations;
