import { Module } from 'vuex';
import { RootState } from '@/store/rootState';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';
import { KnowledgeBaseState } from './knowledgeBaseState';

const knowledgeBase: Module<KnowledgeBaseState, RootState> = {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};

export default knowledgeBase;
