









































import arrayUnique from 'array-unique';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { RelayStateType } from '@/core/types/relayStateType';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { Phone } from '@/core/interfaces/phone';
import { User } from '@/core/interfaces/user';

const relayStore = namespace('relay');

export interface RelayConnectionFiltersData {
    machine: string | null;
    userId: number | null;
    state: RelayStateType | null;
}

@Component({
    components: {},
})
export default class RelayConectionsFilters extends Vue {
    @relayStore.Getter('connections')
    connections!: RelayConnection[];

    @Getter('phoneByUdid')
    phoneByUdid!: (udid: string) => Phone;

    @Getter('userById')
    userById!: (id: number) => User;

    machine: string | null = null;
    userId: number | null = null;
    state: RelayStateType | null = null;

    @Watch('machine')
    @Watch('userId')
    @Watch('state')
    change(): void {
        this.$emit('change', this.filters);
    }

    reset(): void {
        this.machine = null;
        this.userId = null;
        this.state = null;
    }

    get machineOptions(): string[] {
        return arrayUnique(this.connections.map(connection => connection.name || ''));
    }

    get userOptions(): { userId: number; username: string }[] {
        return arrayUnique(
            this.connections
                .filter(connection => connection.user_id)
                .map(connection => connection.user_id))
            .map(userId => this.userById(Number(userId)))
            .filter(user => !!user)
            .sort((a, b) => a.username.localeCompare(b.username))
            .map(user => ({
                userId: user.id,
                username: user.username,
            }));
    }

    get stateOptions(): RelayStateType[] {
        return arrayUnique(this.connections.map(connection => connection.state));
    }

    get filters(): RelayConnectionFiltersData {
        return {
            machine: this.machine || null,
            userId: this.userId || null,
            state: this.state || null,
        };
    }
}

