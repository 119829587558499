















































import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Notification } from '@/core/interfaces/notification';
import { MapData } from '@/core/interfaces/mapData';
import axios from 'axios';
import Loader from '@/components/Loader.vue';
import NotificationSnapshot from '@/components/notification/Snapshot/NotificationSnapshot.vue';
import { NotificationAttachment } from '@/core/interfaces/notificationAttachment';
import { MapOffset } from '@/core/interfaces/mapOffset';
import { Farm } from '@/core/interfaces/farm';
import { MapLayer } from '@/core/interfaces/mapLayer';

@Component({
    components: { NotificationSnapshot, Loader },
})
export default class NotificationAttachments extends Vue {
    @Prop({ required: true })
    private notification!: Notification;

    private selectedAttachmentIndex: number | null = null;
    private snapshot: { json: MapData; background: Blob } | null = null;
    private mapOffset: MapOffset | null = null;
    private mapLayers: MapLayer[] = [];
    private snapshotSelected = false;
    private fullscreen = false;

    protected async toggleFullscreen() {
        if (this.selectedAttachmentIndex && this.typePerAttachment[this.selectedAttachmentIndex] === 'video') {
            const attachments = this.$refs.attachment_item as HTMLElement[];
            const attachmentEl = attachments[this.selectedAttachmentIndex];

            const video = attachmentEl.querySelector('video') as HTMLVideoElement;

            if (video.requestFullscreen) {
                return video.requestFullscreen();
            }
        }

        const container = (this.$refs.attachments as HTMLElement);

        if (!document.fullscreenElement) {
            await container.requestFullscreen();
        } else if (document.exitFullscreen) {
            await document.exitFullscreen();
        }
    }

    async mounted(): Promise<void> {
        if (this.notification.attachments && this.notification.attachments.length) {
            this.selectedAttachmentIndex = 0;
        } else if (this.notification.snapshot) {
            this.snapshotSelected = true;
        }
    }

    get attachmentUrls(): string[] {
        return this.notification.attachments.map(attachment => `${process.env.VUE_APP_API_URL}/notifications/${this.notification.id}/attachments/${attachment.id}`);
    }

    get typePerAttachment(): ('photo' | 'video')[] {
        return this.notification.attachments
            .map(attachment => attachment.content_type.startsWith('video') ? 'video' : 'photo');
    }

    get farm(): Farm {
        return this.$store.getters.farmById(this.notification.farm_id);
    }

    @Watch('snapshotSelected')
    private async syncSnapshot() {
        if (!this.snapshotSelected || this.snapshot) {
            return;
        }

        const jsonPromise = axios.get<MapData>(`${process.env.VUE_APP_API_URL}/notifications/${this.notification.id}/snapshot/json`);
        const backgroundPromise = axios.get<Blob>(`${process.env.VUE_APP_API_URL}/notifications/${this.notification.id}/snapshot/background`, {
            responseType: 'blob',
        });
        const mapOffsetPromise = axios.get<MapOffset>(`${process.env.VUE_APP_API_URL}/farms/${this.farm.id}/vehicle-types/${this.notification.vehicle_type_id}/map-offset`);
        const mapDataPromise = axios.get<MapData>(`${process.env.VUE_APP_API_URL}/farms/${this.farm.id}/map/${this.notification.vehicle_type_id}/json`);

        const data = await Promise.all([jsonPromise, backgroundPromise, mapOffsetPromise, mapDataPromise]);
        this.snapshot = { json: data[0].data, background: data[1].data };
        this.mapOffset = data[2].data;
        this.mapLayers = data[3].data.layers;
    }
}
