import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Farm } from '@/core/interfaces/farm';
import { Vehicle } from '@/core/interfaces/vehicle';
import { Run } from '@/core/interfaces/run';
import NotificationLevel from '@/core/enums/notificationLevel';
import { intervalToDuration } from 'date-fns';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';
import NotifyLog from '@/core/interfaces/notifyLog';
import { Notification } from '@/core/interfaces/notification';

const levels = [NotificationLevel.INFO, NotificationLevel.WARNING, NotificationLevel.ERROR];

@Component
export default class UsesRunDetails extends Mixins(FormatsDurationShort) {
    @Prop({ required: true })
    protected run!: Run;

    @Prop({ default: () => [] })
    protected notifications!: Notification[];

    protected get farm(): Farm {
        return this.$store.getters.farmById(this.run.farm_id);
    }

    protected get vehicle(): Vehicle {
        return this.$store.getters.vehicleById(this.run.vehicle_id);
    }

    protected get vehicleType(): Vehicle {
        return this.$store.getters.vehicleTypeById(this.vehicle.vehicle_type_id);
    }

    protected get runDuration(): string {
        if (!this.run.end_date) {
            return '';
        }

        const duration = intervalToDuration({
            start: new Date(this.run.start_date),
            end: new Date(this.run.end_date),
        });

        return this.formatDurationShort(duration);
    }

    protected get highestNotificationLevel(): NotificationLevel {
        return this.notifications.reduce((acc: NotificationLevel, notification) => {
            if (levels.indexOf(notification.notification_level) > levels.indexOf(acc)) {
                return notification.notification_level;
            }

            return acc;
        }, NotificationLevel.INFO);
    }

    protected get cumalitiveLogs(): NotifyLog[] {
        return this.notifications.reduce((acc: NotifyLog[], notification) => {
            acc = [...acc, ...notification.notify_logs || []];

            return acc;
        }, []);
    }
}
