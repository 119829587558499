


































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FormatsDate from '@/components/mixins/FormatsDate';
import { Getter } from 'vuex-class';
import { User } from '@/core/interfaces/user';
import { format } from 'date-fns';
import axios from 'axios';

@Component({
    components: {},
})
export default class BrowserLogsView extends mixins(FormatsDate) {
    @Getter('usersSet')
    users!: User[];

    apps = ['alarmcenter', 'hmf'];

    app = 'hmf';

    date = format(new Date(), 'yyyy-MM-dd');

    userId: null | number = null;

    logs = '';

    async fetchLogs(): Promise<void> {
        this.logs = '';
        const url = `https://lely-alarmcenter-backend.azurewebsites.net/api/web/browserlog/${this.app}/${this.userId}/${this.date}`;
        const response = await axios.get(url);

        this.logs = response.data;
    }
}
