









import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component
export default class VehicleTypeSelectedWarning extends Vue {
    get selectedVehicleTypes(): string[] {
        return this.$store.getters.visibleVehicleTypes.map((vehicleType: VehicleType) => vehicleType.name);
    }
}
