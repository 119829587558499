





















import { Component, Prop } from 'vue-property-decorator';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import TicketMessageBubble from '@/components/tickets/TicketMessageBubble.vue';
import Vue from 'vue';

@Component({
    components: { TicketMessageBubble },
})
export default class TicketMessageItem extends Vue {
    @Prop({ required: true })
    message!: TicketMessage;

    @Prop({ required: true, type: Number })
    ticketId!: number;

    editting = false;

    async updateMessage(body: string): Promise<void> {
        await this.$store.dispatch('tickets/updateMessage', {
            ticketId: this.ticketId,
            messageId: this.message.id,
            body,
        });

        this.editting = false;
    }

    async deleteMessage(): Promise<void> {
        const response = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this message?', {
                size: 'sm',
                centered: true,
            });

        if (!response) {
            return;
        }

        await this.$store.dispatch('tickets/deleteMessage', {
            ticketId: this.ticketId,
            messageId: this.message.id,
        });
    }
}

