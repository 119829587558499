


















































import { Component, Prop } from 'vue-property-decorator';
import { NotificationType } from '@/core/interfaces/notificationType';
import DashboardPosition from '@/components/dashboard/DashboardPosition.vue';
import DashboardDifference from '@/components/dashboard/DashboardDifference.vue';
import { Getter } from 'vuex-class';
import DashboardNotificationType from '@/core/interfaces/dashboard/dashboardNotificationType';
import { mixins } from 'vue-class-component';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import { VehicleType } from '@/core/interfaces/vehicleType';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';

@Component({
    components: { DashboardDifference, DashboardPosition },
})
export default class DashboardNotificationTypesWidget extends mixins(UsesNotificationMessage, FormatsDurationShort) {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Prop({ required: true })
    notificationTypes!: DashboardNotificationType[];

    @Prop({ type: Boolean, default: true })
    showDuration!: boolean;

    @Prop({ type: Boolean, default: false })
    clickable!: boolean;

    @Getter('notificationTypeById')
    notificationTypeById!: (id: number) => NotificationType;

    get sortedNotificationTypes(): DashboardNotificationType[] {
        return this.notificationTypes.sort((a, b) => b.selected_period.duration_minutes - a.selected_period.duration_minutes);
    }

    get notificationTypeMessages(): string[] {
        return this.sortedNotificationTypes.map(notificationType => this.translateNotificationMessage(this.notificationTypeById(notificationType.notification_type_id), this.vehicleType));
    }
}

