



























































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import UsesTicket from '@/components/mixins/UsesTicket';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import TicketMessageItem from '@/components/tickets/TicketMessageItem.vue';

@Component({
    components: { TicketMessageItem, CategoryLabel },
})
export default class TicketConversation extends mixins(UsesTicket) {
    newMessageBody = '';
    newMessagePhoto: Blob | null = null;
    newMessagePhotoLoading = false;
    sending = false;
    loading = false;
    scrolled = false;
    dropping = false;

    @Watch('ticket.id', { immediate: true })
    async ticketUpdate(): Promise<void> {
        this.scrolled = false;
        this.newMessageBody = '';
        this.newMessagePhoto = null;
        this.fetchMessages().then();

        await this.$nextTick();
        (this.$refs.newMessage as HTMLElement)?.focus();
    }

    @Watch('messages')
    scrollDown(): void {
        this.$nextTick(() => {
            if (!this.$refs.messages) {
                return;
            }

            (this.$refs.messages as HTMLElement).scrollTop = (this.$refs.messages as HTMLElement).scrollHeight + 100;
        });
    }

    async fetchMessages(): Promise<void> {
        this.loading = true;
        await this.$store.dispatch('tickets/syncMessages', this.ticket.id);
        this.loading = false;
    }

    async sendMessage(): Promise<void> {
        if (!this.newMessageBody && !this.newMessagePhoto) {
            return;
        }

        this.sending = true;

        const msg = await this.$store.dispatch('tickets/addMessage', {
            ticketId: this.ticket.id,
            message: {
                body: this.newMessageBody,
            },
        });

        if (this.newMessagePhoto) {
            await this.$store.dispatch('tickets/addPhoto', {
                messageId: msg.id,
                ticketId: this.ticket.id,
                attachment: this.newMessagePhoto,
            });
        }

        await this.fetchMessages();

        this.sending = false;
        this.newMessageBody = '';
        (this.$refs.newMessage as HTMLElement).innerHTML = '';

        await this.$nextTick();
        (this.$refs.newMessage as HTMLElement).focus();
    }

    async addPhoto(): Promise<void> {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = () => {
            if (!input.files?.length) {
                return;
            }

            this.newMessagePhoto = input.files[0];
        };

        input.click();
    }

    get messages(): TicketMessage[] {
        return this.$store.getters['tickets/messagesByTicket'](this.ticket.id);
    }

    @Watch('messages')
    updateMessagesSeen(): void {
        this.messages.forEach(message => this.$store.commit('tickets/setMessageSeen', message));
    }

    mediaLoaded(): void {
        if (!this.scrolled) {
            this.scrollDown();
        }
    }

    get newMessagePhotoSrc(): string {
        if (!this.newMessagePhoto) {
            return '';
        }

        return URL.createObjectURL(this.newMessagePhoto);
    }

    async onInput(evt: any): Promise<void> {
        this.newMessageBody = evt.target.innerText;

        const imgSrc = evt.target.querySelector('img')?.src;

        if (!imgSrc) {
            return;
        }

        (this.$refs.newMessage as HTMLElement).innerText = (this.$refs.newMessage as HTMLElement).innerText;

        this.newMessagePhotoLoading = true;
        this.newMessagePhoto = await (await fetch(imgSrc)).blob();
        this.newMessagePhotoLoading = false;
    }

    async dropHandler(event: DragEvent): Promise<void> {
        this.dropping = false;

        if (!event.dataTransfer?.files.length) {
            return;
        }

        const file = event.dataTransfer.files[0];

        if (!file.type.startsWith('image/')) {
            return;
        }

        this.newMessagePhoto = file;
    }
}

