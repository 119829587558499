































import { Component, Vue } from 'vue-property-decorator';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';

@Component({
    components: { TopnavLayout },
})
export default class RelayView extends Vue {
    mounted(): void {
        this.refresh();
    }

    async refresh(): Promise<void> {
        await Promise.all([
            this.$store.dispatch('relay/syncConnections'),
            this.$store.dispatch('relay/syncMachines'),
        ]);
    }

    async resetStats(): Promise<void> {
        const response = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to reset the stats, this can not be undone', {
                size: 'sm',
            });

        if (!response) {
            return;
        }

        await this.$store.dispatch('relay/resetStats');

        await this.$bvModal.msgBoxOk('Stats have been reset', {
            size: 'sm',
        });
    }
}

