


















import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TimelineItem from '@/components/TimelineItem.vue';
import NotifyLog from '@/core/interfaces/notifyLog';
import { parseISO } from 'date-fns';
import { Role } from '@/core/interfaces/role';
import { Getter } from 'vuex-class';
import GetChannelsFromNotifyLog from '@/components/mixins/GetChannelsFromNotifyLog';

@Component({
    components: { TimelineItem },
})
export default class NotificationTimelineNotificationLogItem extends mixins(GetChannelsFromNotifyLog) {
    @Getter('roleById')
    roleById!: (id: number) => Role;

    @Prop({ required: true })
    protected notifyLog!: NotifyLog;

    @Prop({ default: true })
    protected differentDateThanPrevious!: boolean;

    @Prop({ default: false })
    protected cleared!: boolean;

    get date(): Date {
        if (this.cleared) {
            return parseISO(this.notifyLog.cleared_at);
        }

        return this.notifyLog.send_at ? parseISO(this.notifyLog.send_at) : parseISO(this.notifyLog.scheduled_at);
    }

    get role(): Role {
        return this.roleById(this.notifyLog.role_id);
    }

    get channels(): string[] {
        return this.getChannelsFromNotifyLog(this.notifyLog);
    }
}
