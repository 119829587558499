import { Component, Vue } from 'vue-property-decorator';
import { Notification } from '@/core/interfaces/notification';
import {
    itemIsRunWithNotifications,
    NotificationOrRunWithNotificationsHolder,
    RunWithNotifications,
} from '@/core/interfaces/notificationOrRunWithNotificationsHolder';
import { parseISO } from 'date-fns';
import { Run } from '@/core/interfaces/run';

const getSortingDate = (item: NotificationOrRunWithNotificationsHolder): number => {
    return itemIsRunWithNotifications(item) ? parseISO(item.run.start_date).getTime() : parseISO(item.created_at).getTime();
};

@Component
export default class GroupsNotifications extends Vue {
    groupNotificationsByRun(notifications: Notification[], runs: Run[]): NotificationOrRunWithNotificationsHolder[] {
        return notifications
            .reduce((acc: NotificationOrRunWithNotificationsHolder[], notification) => {
                if (!notification.notification_run_id) {
                    acc.push(notification);

                    return acc;
                }

                const idx = acc.findIndex(item => itemIsRunWithNotifications(item) && item.run.id === notification.notification_run_id);
                if (idx > -1 && itemIsRunWithNotifications(acc[idx])) {
                    (acc[idx] as RunWithNotifications).notifications.push(notification);
                } else {
                    const run = runs.find(run => run.id === notification.notification_run_id);

                    if (!run) {
                        acc.push(notification);

                        return acc;
                    }

                    acc.push({
                        run,
                        notifications: [
                            notification,
                        ],
                    });
                }

                return acc;
            }, [])
            .sort((a, b) => getSortingDate(b) - getSortingDate(a));
    }

    itemIsRunWithNotifications(item: NotificationOrRunWithNotificationsHolder): item is RunWithNotifications {
        return itemIsRunWithNotifications(item);
    }
}
