






import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import OpensRunNotificationsModal from '@/components/mixins/OpensRunNotificationsModal';
import axios from 'axios';
import { Run } from '@/core/interfaces/run';

@Component({})
export default class App extends mixins(OpensRunNotificationsModal) {
    @Watch('$route.query.run', { immediate: true })
    watchRun(): void {
        if (this.$route.query.run) {
            this.openRun(Number(this.$route.query.run), this.$route.query.notification ? Number(this.$route.query.notification) : undefined);
        }
    }

    async openRun(runId: number, notificationId?: number): Promise<void> {
        return this.openRunNotificationsModal(
            (await axios.get<Run>(`/notification-runs/${runId}`)).data,
            notificationId,
            () => this.$router.push({
                query: {
                    run: undefined,
                    notification: undefined,
                },
            }),
        );
    }
}

