









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import { DashboardDefinition } from '@/core/interfaces/dashboardDefinition';

@Component
export default class DashboardExosDriveOffset extends Vue {
    @Prop({ required: true })
    private vehicle!: Vehicle;

    private distance = 0;
    private backAngle = 0;
    private frontAngle = 0;

    get frontAngleExtra() {
        return this.calculateAngleExtra(this.frontAngle);
    }

    get backAngleExtra() {
        return this.calculateAngleExtra(this.backAngle);
    }

    calculateAngleExtra(angle: number) {
        if (angle === 0) {
            return '';
        }

        return angle < 0 ? this.$t('general.left').toString().toLowerCase() : this.$t('general.right').toString().toLowerCase();
    }

    reset() {
        this.distance = 0;
        this.backAngle = 0;
        this.frontAngle = 0;
    }

    execute() {
        const definition = this.$store.state.vehicleDashboard.definition as DashboardDefinition;
        const service = definition.features.drive_offset_exos?.service;

        if (!service) {
            throw new Error('No service found');
        }

        this.$store.dispatch('vehicleDashboard/callService', {
            service,
            args: {
                offset: this.distance,
                front_steering_angle: this.frontAngle,
                rear_steering_angle: this.backAngle,
            },
        });
    }
}

