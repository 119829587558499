




























































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import NewIndicator from '@/components/NewIndicator.vue';
import { Run } from '@/core/interfaces/run';
import AddTicketModal from '@/components/tickets/AddTicketModal.vue';
import { Ticket } from '@/core/interfaces/ticket';

@Component({
    components: { AddTicketModal, NewIndicator },
})
export default class NavigationSidebar extends Vue {
    @Getter('runs/openNotifications')
    protected openNotifications!: Notification[];

    @Getter('runs/assignedNotifications')
    protected assignedNotifications!: Notification[];

    @Getter('runs/notificationsAssignedToMe')
    protected assignedToMeNotifications!: Notification[];

    @Getter('tickets/assignedToMe')
    protected ticketsAssignedToMe!: Ticket[];

    @Getter('tickets/openTickets')
    protected openTickets!: Ticket[];

    @Getter('tickets/unseenMessagesTicketsAssignedToMe')
    protected unseenMessagesTicketsAssignedToMe!: number;
}

