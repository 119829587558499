

















import { Component, Prop, Vue } from 'vue-property-decorator';
import tinymce from 'tinymce/tinymce';

import 'tinymce/models/dom/model';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/table';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/code';
import axios from 'axios';

@Component({
    components: {},
})
export default class KnowledgeBasePageView extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ required: true })
    attachmentsUrl!: string;

    mounted(): void {
        tinymce.init({
            target: this.$refs.editor as HTMLElement,
            license_key: 'gpl',
            skin: false,
            auto_focus: true,
            content_css: [
                '/tinymce/content.css',
            ],
            plugins: [
                'lists',
                'autolink',
                'image',
                'table',
                'code',
                'quickbars',
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic | alignleft aligncenter alignright alignjustify' +
                ' | bullist numlist outdent indent | ' +
                'removeformat | numlist bullist | quickimage table | code',
            file_picker_types: 'image',
            file_picker_callback: async(cb, value, meta) => {
                const url = await this.uploadFile();

                cb(url);
            },
            images_upload_handler: async(blobInfo, progress) => {
                return this.uploadBlob(blobInfo.blob());
            },
            table_default_attributes: {
                class: 'table',
            },
            promotion: false,
        });
    }

    beforeDestroy() {
        tinymce?.activeEditor?.destroy();
    }

    submit(): void {
        this.$emit('input', tinymce?.activeEditor?.getContent());
    }

    async uploadFile(): Promise<string> {
        return new Promise((resolve) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.onchange = async(e) => {
                if (!e.target) {
                    return;
                }

                const fileList = (e.target as HTMLInputElement).files;

                if (!fileList) {
                    return;
                }

                const file = fileList[0];

                return this.uploadBlob(file);
            };

            input.click();
        });
    }

    async uploadBlob(blob: Blob): Promise<string> {
        const response = await axios.post(this.attachmentsUrl, blob, {
            headers: {
                'Content-Type': blob.type,
            },
        });

        return `${process.env.VUE_APP_API_URL}/${this.attachmentsUrl}/${response.data.id}`;
    }
}
