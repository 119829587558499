













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { Vehicle as VehicleEntity } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { Notification } from '@/core/interfaces/notification';
import OpensDashboardModal from '@/components/mixins/OpensDashboardModal';
import Pulse from '@/components/Pulse.vue';
import { RelayMachine } from '@/core/interfaces/relayMachine';
import NotificationLevel from '@/core/enums/notificationLevel';

@Component({
    components: { Pulse },
})
export default class Vehicles extends Mixins(OpensDashboardModal) {
    @Prop({ required: true })
    vehicles!: VehicleEntity[];

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Getter('relay/machineByName')
    machineByName!: (name: string) => RelayMachine;

    @Getter('runs/notificationsByVehicle')
    notificationsByVehicle!: (id: number) => Notification[];

    get machineRelayStates(): boolean[] {
        return this.vehicles.map(vehicle => this.machineByName(vehicle.name)?.is_connected === true);
    }

    get vehicleHasOpenAlarm(): boolean[] {
        return this.vehicles.map(vehicle => !!this.notificationsByVehicle(vehicle.id)
            .filter(notification => notification.notification_level !== NotificationLevel.INFO)
            .length);
    }
}

