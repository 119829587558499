

































import { Component, Mixins, Prop } from 'vue-property-decorator';
import UsesRunDetails from '@/components/mixins/UsesRunDetails';
import FormatsDate from '@/components/mixins/FormatsDate';
import Vue from 'vue';
import BaseItemCard from '@/components/BaseItemCard.vue';
import NotifiedRolesIndicator from '@/components/NotifiedRolesIndicator.vue';
import Avatar from '@/components/Avatar.vue';
import RunActions from '@/components/run/RunActions.vue';
import RunCardNotifications from '@/components/run/RunCardNotifications.vue';

@Component({
    components: { RunCardNotifications, RunActions, Avatar, NotifiedRolesIndicator, BaseItemCard },
})
export default class RunCard extends Mixins(UsesRunDetails, FormatsDate) {
    @Prop()
    viewPreferenceEventBus!: Vue;
}

