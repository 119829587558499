export default function filtersToUrlParams(filters: Record<string, any>): Record<string, string> {
    return Object.entries(filters).reduce((acc: Record<string, any>, param) => {
        if (param[1] === null) {
            return acc;
        } else if (Array.isArray(param[1])) {
            param[1].forEach((value, index) => {
                acc[`${param[0]}[${index}]`] = value;
            });
        } else {
            acc[param[0]] = param[1];
        }

        return acc;
    }, {});
}
