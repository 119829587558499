



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import { Farm } from '@/core/interfaces/farm';
import { Getter } from 'vuex-class';
import vSelect from 'vue-select';
import { NotificationType } from '@/core/interfaces/notificationType';
import { Rule } from '@/core/interfaces/rule';
import SettingsRuleModalTimetable from '@/components/settings/rule/SettingsRuleModalTimetable.vue';
import SettingsRuleModalActions from '@/components/settings/rule/SettingsRuleModalActions.vue';
import { Role } from '@/core/interfaces/role';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { AllNotificationLevels } from '@/core/enums/notificationLevel';

@Component({
    components: { SettingsRuleModalActions, SettingsRuleModalTimetable, Modal, vSelect },
})
export default class SettingsRuleModal extends Vue {
    @Prop({ required: true })
    protected rule!: Rule;

    protected notificationType: NotificationType | null = null;

    @Getter('farmsSet')
    protected farms!: Farm[];

    @Getter('rolesSet')
    protected roles!: Role[];

    @Getter('vehicleTypesSet')
    protected vehicleTypes!: VehicleType[];

    @Getter('notificationTypesSet')
    protected notificationTypes!: NotificationType[];

    @Getter('vehiclePhasesSet')
    protected vehiclePhases!: VehiclePhase[];

    @Getter('vehicleTypeById')
    protected vehicleTypeById!: (id: number) => VehicleType;

    protected notificationLevels = AllNotificationLevels;

    protected selectedNotificationTypes: number[] = [];
    protected selectedFarms: number[] = [];
    protected selectedNotificationLevels: string[] = [];
    protected selectedVehiclePhases: number[] = [];
    protected label = '';

    protected always = true;

    mounted(): void {
        this.selectedNotificationTypes = this.rule.notification_types;
        this.selectedFarms = this.rule.farms;
        this.selectedNotificationLevels = this.rule.notification_levels;
        this.selectedVehiclePhases = this.rule.vehicle_phases;
        this.label = this.rule.label;
    }

    get farmOptions(): { title: string; id: number | null }[] {
        return this.farms
            .map(farm => ({
                title: farm.name,
                id: farm.id,
            }));
    }

    get vehicleTypeOptions(): { title: string; id: number | null }[] {
        return this.vehicleTypes
            .filter(vehicleType => vehicleType.id)
            .map(vehicleType => {
                return {
                    title: vehicleType.name,
                    id: vehicleType.id,
                };
            });
    }

    get notificationTypesWithTranslatedMessage(): { title: string; id: number | null }[] {
        return this.notificationTypes
            .filter(notificationType => this.rule.vehicle_type_id ? notificationType.vehicle_type_id === this.rule.vehicle_type_id : true)
            .map(notificationType => {
                const vehicleType = this.vehicleTypeById(notificationType.vehicle_type_id);
                const title = `${this.$i18n.t(`notification-codes.${vehicleType.name}.${notificationType.code}`)} (${notificationType.code})`;

                return {
                    title,
                    id: notificationType.id,
                };
            });
    }

    get vehiclePhasesWithMessage(): { id: number | null; title: string }[] {
        return this.vehiclePhases
            .filter(vehiclePhase => this.rule.vehicle_type_id ? vehiclePhase.vehicle_type_id === this.rule.vehicle_type_id : true)
            .map(vehiclePhase => {
                const vehicleType = this.vehicleTypeById(vehiclePhase.vehicle_type_id);
                const title = ` ${this.$i18n.t(`vehicle-phases.${vehicleType.name}.${vehiclePhase.code_name}`)}`;
                return {
                    title,
                    id: vehiclePhase.id,
                };
            })
            .sort((a, b) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
    }

    @Watch('rule', { immediate: true })
    updateAlwaysFromRule() {
        this.always = this.rule.timetable.filter(timetable => timetable.weekday !== null).length === 0;
    }

    @Watch('rule', { deep: true, immediate: true })
    addEmptyTimetable(): void {
        if (this.rule.timetable.every(timetable => timetable.weekday !== null)) {
            this.rule.timetable.push({ weekday: null, time_to: null, time_from: null });
        }
    }

    @Watch('rule', { deep: true, immediate: true })
    addEmptyAction(): void {
        const allRolesUsed = this.roles.every(role => this.rule.notify_actions.some(rule => rule.role_id === role.id));

        if (!allRolesUsed && this.rule.notify_actions.every(action => !!action.role_id)) {
            this.rule.notify_actions.push({
                role_id: null,
                notify_threshold_minutes: 0,
                call: false,
                sms: false,
                app: false,
            });
        }
    }

    @Watch('always')
    updateAlways(): void {
        if (this.always) {
            this.rule.timetable = [];
        }
    }

    async save() {
        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
            return;
        }

        const rule = {
            id: this.rule.id || undefined,
            label: this.label,
            farms: this.selectedFarms,
            vehicle_type_id: this.rule.vehicle_type_id,
            vehicle_phases: this.selectedVehiclePhases,
            notification_types: this.selectedNotificationTypes,
            notification_levels: this.selectedNotificationLevels,
            timetable: this.rule.timetable.filter(timetable => timetable.weekday !== null),
            notify_actions: this.rule.notify_actions.filter(action => !!action.role_id),
        } as Rule;

        await this.$store.dispatch('settings/saveRule', rule);

        this.$emit('close');
    }

    close() {
        this.$emit('close');
    }
}
