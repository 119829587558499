import { ActionContext, ActionTree } from 'vuex';
import { KnowledgeBaseState } from './knowledgeBaseState';
import { RootState } from '@/store/rootState';
import axios from 'axios';

const actions: ActionTree<KnowledgeBaseState, RootState> = {
    fetchSections: async(context: ActionContext<KnowledgeBaseState, RootState>, vehicleTypeId: number) => {
        const response = (await axios.get(`/vehicle-types/${vehicleTypeId}/sections`)).data;

        context.commit('setSections', response);
    },

    addSection: async(context: ActionContext<KnowledgeBaseState, RootState>, payload: {
        vehicleTypeId: number;
        name: string;
    }) => {
        const response = (await axios.post(`/vehicle-types/${payload.vehicleTypeId}/sections`, {
            name: payload.name,
        })).data;

        await context.dispatch('fetchSections', payload.vehicleTypeId);

        return response;
    },

    updateSection: async(context: ActionContext<KnowledgeBaseState, RootState>, payload: {
        vehicleTypeId: number;
        id: number;
        name: string;
        order: number;
    }) => {
        await axios.put(`/vehicle-types/${payload.vehicleTypeId}/sections/${payload.id}`, {
            name: payload.name,
            order: payload.order,
        });
    },

    deleteSection: async(context: ActionContext<KnowledgeBaseState, RootState>, payload: {
        vehicleTypeId: number;
        id: number;
    }) => {
        await axios.delete(`/vehicle-types/${payload.vehicleTypeId}/sections/${payload.id}`);

        return context.dispatch('fetchSections', payload.vehicleTypeId);
    },
};

export default actions;
