




import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import Loader from '@/components/Loader.vue';
import { MapData } from '@/core/interfaces/mapData';
import MapMixin from '@/components/mixins/MapMixin';
import { MapOffset } from '@/core/interfaces/mapOffset'
import { MapLayer } from '@/core/interfaces/mapLayer'

@Component({
    components: {Loader},
})
export default class NotificationSnapshot extends Mixins(MapMixin) {
    @Prop({required: true})
    private snapshot!: MapData;

    @Prop({required: true})
    private background!: Blob;

    @Prop({required: false})
    private mapOffset!: MapOffset;

    @Prop({required: true})
    private center!: [number, number];

    @Prop({required: true})
    private mapLayers!: MapLayer[];

    private async mounted() {
        const offset = this.snapshot.background.offset ? this.getLatLngTupleFromOffset(this.snapshot.background.offset) : undefined;

        await this.initMap({
            elem: this.$refs.map as HTMLElement,
            center: this.center,
            offset,
            mapOffset: [this.mapOffset.offset_lat, this.mapOffset.offset_lng],
            background: {image: this.background, resolution: this.snapshot.background.resolution},
            layers: this.snapshot.layers,
        });

        this.drawLayers(this.mapLayers, offset);
    }
}

