import { render, staticRenderFns } from "./NotificationLevelIcon.vue?vue&type=template&id=7e9a6a36&scoped=true&"
import script from "./NotificationLevelIcon.vue?vue&type=script&lang=ts&"
export * from "./NotificationLevelIcon.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationLevelIcon.vue?vue&type=style&index=0&id=7e9a6a36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9a6a36",
  null
  
)

export default component.exports