









import { Component, Vue } from 'vue-property-decorator';
import SettingsOperatorTimeblocks from '@/components/settings/timetable/SettingsOperatorTimeblocks.vue';

@Component({
    components: {
        SettingsOperatorTimeblocks,
    },
})
export default class TimetableView extends Vue {
}

