








































































































import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { namespace } from 'vuex-class';
import { RelayMachine } from '@/core/interfaces/relayMachine';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import RelayMachineTopics from '@/components/relay/RelayMachineTopics.vue';
import { mixins } from 'vue-class-component';
import FormatsBytes from '@/components/mixins/FormatsBytes';
import RelayMachineClients from '@/components/relay/RelayClients.vue';
import UsesRelayStateRowStatus from '@/components/relay/usesRelayStateRowStatus';

const relayStore = namespace('relay');

@Component({
    components: { RelayMachineClients, RelayMachineTopics },
})
export default class MachinesView extends mixins(FormatsBytes, UsesRelayStateRowStatus) {
    @relayStore.Getter('machines')
    machines!: RelayMachine[];

    @relayStore.Getter('connectionByUuid')
    connectionByUuid!: (uuid: string) => RelayConnection;

    get columns(): LelyTableColumnsConfig<RelayMachine> {
        return [
            {
                header: 'Machine',
                value: 'machine',
            },
            {
                header: 'State',
                value: 'state',
                sub: machine => `${machine.topics.length} topics`,
            },
            {
                header: 'Usage',
                right: true,
                value: machine => {
                    const bytes = this.totalBytesByMachine(machine);

                    if (bytes) {
                        return this.formatBytes(bytes);
                    }

                    return '-';
                },
                sub: machine => `in ${this.totalMessageCountByMachine(machine)} messages`,
            },
        ];
    }

    totalMessageCountByMachine(machine: RelayMachine): number {
        return machine.stats
            .flatMap(stats => stats.message_count)
            .reduce((acc, num) => acc + num, 0);
    }

    totalBytesByMachine(machine: RelayMachine): number {
        return machine.stats
            .flatMap(stats => stats.bytes_from_machine + stats.bytes_to_machine)
            .reduce((acc, num) => acc + num, 0);
    }
}

