










import { Component, Prop, Vue } from 'vue-property-decorator';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import axios from 'axios';
import { VNode } from 'vue';
import { BIconPlayFill } from 'bootstrap-vue';

@Component({
    components: {
        BIconPlayFill,
    },
})
export default class TicketMessageMedia extends Vue {
    @Prop({ required: true })
    message!: TicketMessage;

    @Prop({ required: true, type: Number })
    ticketId!: number;

    imageLoaded = false;

    imageDataUrl = '';

    modalLoading = false;

    mounted(): void {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio === 1) {
                    this.loadImage();
                    observer.disconnect();
                }
            });
        }, {
            root: document.querySelector('.messages'),
            rootMargin: '0px',
            threshold: 1.0,
        });

        observer.observe(this.$el);
    }

    async showModal(): Promise<void> {
        const h = this.$createElement;

        let messageVNode: VNode;

        if (this.message.is_video) {
            this.modalLoading = true;
            const video = await this.getVideo();
            this.modalLoading = false;
            const sourceNode = h('source', {
                attrs: {
                    src: video.url,
                    type: video.type,
                },
            });

            messageVNode = h('b-embed', {
                attrs: {
                    allowfullscreen: true,
                    controls: true,
                    autoplay: true,
                },
                props: {
                    type: 'video',
                },
            }, [sourceNode]);
        } else {
            messageVNode = h('b-img', {
                props: {
                    src: this.imageDataUrl,
                    center: true,
                    fluid: true,
                },
            });
        }

        const messageWrapper = h('div', {
            attrs: {
                class: 'message-modal-wrapper',
            },
        }, [messageVNode]);

        await this.$bvModal.msgBoxOk([messageWrapper], {
            buttonSize: 'sm',
            centered: true,
            size: 'xl',
        });
    }

    async loadImage(): Promise<void> {
        if (this.imageLoaded) {
            return;
        }

        this.imageLoaded = true;

        const url = this.message.is_video
            ? `/tickets/${this.ticketId}/messages/${this.message.id}/thumbnail`
            : `/tickets/${this.ticketId}/messages/${this.message.id}/photo`;

        const response = await axios.get<Blob>(url,
            { responseType: 'blob' });

        this.imageDataUrl = URL.createObjectURL(response.data);
    }

    async getVideo(): Promise<{ url: string; type: string }> {
        const url = `/tickets/${this.ticketId}/messages/${this.message.id}/video`;

        const response = await axios.get<Blob>(url,
            { responseType: 'blob' });

        return {
            url: URL.createObjectURL(response.data),
            type: response.headers['content-type'],
        };
    }
}

