


















































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Phone } from '@/core/interfaces/phone';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { User } from '@/core/interfaces/user';
import { mixins } from 'vue-class-component';
import FormatsDate from '@/components/mixins/FormatsDate';
import PhoneForm from '@/components/settings/phone/PhoneForm.vue';
import SendSimpleForm from '@/components/settings/phone/SendSimpleForm.vue';
import SendRawForm from '@/components/settings/phone/SendRawForm.vue';

@Component({
    components: { SendRawForm, SendSimpleForm, PhoneForm },
})
export default class PhonesView extends mixins(FormatsDate) {
    @Getter('phonesSet')
    phones!: Phone[];

    @Action('deletePhone')
    deletePhoneAction!: (phone: number) => Promise<void>;

    @Getter('userById')
    userById!: (id: number) => User;

    formPhone: Phone | null = null;
    sendPhone: Phone | null = null;

    actions: LelyTableActionsConfig<Phone> = [
        {
            name: 'send',
            label: 'Send',
            icon: 'lely-icon-send',
            dropdown: () => [
                {
                    label: 'Simple',
                    name: 'simple',
                },
                {
                    label: 'Raw JSON',
                    name: 'raw',
                },
            ],
        },
        {
            label: 'Edit',
            name: 'edit',
            icon: 'lely-icon-edit',
        },
        {
            label: 'Delete',
            name: 'delete',
            icon: 'lely-icon-trash',
        },
    ];

    get columns(): LelyTableColumnsConfig<Phone> {
        return [
            {
                header: 'User',
                value: phone => this.userById(phone.user_id)?.username || '-',
            },
            {
                header: 'Device',
                value: 'device_info',
            },
            {
                header: 'App',
                value: 'app_buildnumber',
                sub: 'app_version',
            },
            {
                header: 'Last seen',
                value: phone => phone.last_seen_at ? this.formatDate(phone.last_seen_at) : '-',
            },
            {
                header: 'Push notifications',
                value: 'use_push_notifications',
                type: 'check',
                center: true,
                shrink: true,
            },
            {
                header: 'Incoming call',
                value: 'use_incoming_call',
                type: 'check',
                center: true,
                shrink: true,
            },
            {
                header: 'Operator call',
                value: 'use_operator_call',
                type: 'check',
                center: true,
                shrink: true,
            },

        ];
    }

    editPhone(phone: Phone): void {
        this.formPhone = phone;
        this.$bvModal.show('phone-form');
    }

    sendToPhone(event: { item: Phone; action: 'raw' | 'simple' }): void {
        this.sendPhone = event.item;

        switch (event.action) {
            case 'raw':
                this.$bvModal.show('send-raw-form');
                break;
            default:
                this.$bvModal.show('send-simple-form');
        }
    }

    async deletePhone(phone: Phone): Promise<void> {
        const username = this.userById(phone.user_id).username;

        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${phone.device_info} of ${username}?`, {
            size: 'sm',
            centered: true,
        });

        if (!confirm) {
            return;
        }

        await this.deletePhoneAction(phone.id);
    }
}
