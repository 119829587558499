






























import Component from 'vue-class-component';
import Vue from 'vue';
import Modal from '@/components/Modal.vue';
import { Getter } from 'vuex-class';
import { User } from '@/core/interfaces/user';
import { Role } from '@/core/interfaces/role';
import { Prop } from 'vue-property-decorator';

export type AssignResult = { type: 'role' | 'user'; value: User | Role };

@Component({
    components: { Modal },
})
export default class RunUsersModal extends Vue {
    @Getter('usersSet')
    protected users!: User[];

    @Getter('assignableRolesSet')
    protected roles!: Role[];

    @Prop({ type: Boolean, default: true })
    protected rolesSelectable!: boolean;

    @Prop()
    protected title!: string;

    protected assignTo: AssignResult | null = null;

    save() {
        this.$emit('close', this.assignTo);
    }

    close() {
        this.$emit('close');
    }
}
