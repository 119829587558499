









import { Component, Prop } from 'vue-property-decorator';
import KnowledgeBaseEditor from '@/components/knowledge-base/KnowledgeBaseEditor.vue';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { NotificationType } from '@/core/interfaces/notificationType';
import { RawLocation } from 'vue-router';
import { Farm } from '@/core/interfaces/farm';

@Component({
    components: { KnowledgeBaseEditor },
})
export default class KnowledgeBaseNavItem extends UsesNotificationMessage {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Prop()
    notificationType!: NotificationType;

    @Prop()
    farm!: Farm;

    @Prop({ type: Boolean })
    active!: boolean;

    get to(): RawLocation {
        return this.notificationType ? {
            name: 'knowledge-base.vehicle-type.notification-type',
            params: {
                vehicleTypeName: this.vehicleType.name,
                notificationTypeCode: String(this.notificationType.code),
            },
        } : {
            name: 'knowledge-base.vehicle-type.farm',
            params: {
                vehicleTypeName: this.vehicleType.name,
                farmId: String(this.farm.id),
            },
        };
    }

    get content(): string {
        if (this.notificationType) {
            return this.notificationMessage;
        }

        if (this.farm) {
            return this.farm.name;
        }

        return '';
    }

    get code(): string {
        if (!this.notificationType) {
            return '';
        }

        return String(this.notificationType.code);
    }
}

