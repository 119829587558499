













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Role } from '@/core/interfaces/role';
import { User } from '@/core/interfaces/user';
import { Timetable } from '@/core/interfaces/timetable';
import { format, parseISO, setHours, setMinutes } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import nextDay from 'date-fns/nextDay';
import UsesDateFnsLocale from '@/components/mixins/UsesDateFnsLocale';
import FormatsDate from '@/components/mixins/FormatsDate';

const settings = namespace('settings');

@Component({})
export default class SettingsTimetableEventForm extends Mixins(UsesDateFnsLocale, FormatsDate) {
    @Getter('roleByName')
    roleByName!: (name: string) => Role;

    @Getter('usersByRoleId')
    usersByRoleId!: (id: number) => User[];

    @settings.Action('saveTimetable')
    saveTimetable!: (timetable: Partial<Timetable>) => Promise<void>;

    @settings.Action('deleteTimetable')
    deleteTimetableAction!: (id: number) => Promise<void>;

    @Prop({ required: true })
    initialTimetable!: Partial<Timetable>;

    timeFrom = '';
    timeUntil = '';

    assignee: number | null = null;

    saving = false;

    async mounted(): Promise<void> {
        const startDate = this.initialTimetable?.start_date;
        const endDate = this.initialTimetable?.end_date;

        if (startDate) {
            this.timeFrom = format(parseISO(startDate), 'HH:mm');
        }

        if (endDate) {
            this.timeUntil = format(parseISO(endDate), 'HH:mm');
        }

        if (this.initialTimetable?.user_id) {
            this.assignee = this.initialTimetable.user_id;
        }
    }

    get timetableFromForm(): Partial<Timetable> {
        return {
            id: this.initialTimetable?.id,
            user_id: Number(this.assignee),
            role_id: this.operatorRole.id,
            start_date: this.startDate?.toISOString(),
            end_date: this.endDate?.toISOString(),
        };
    }

    async submit(): Promise<void> {
        if (!this.startDate || !this.endDate) {
            return;
        }

        this.saving = true;

        try {
            await this.saveTimetable(this.timetableFromForm);

            this.$emit('submitted');
        } catch (e) {
            // noop
        } finally {
            this.saving = false;
        }
    }

    async deleteTimetable(): Promise<void> {
        if (!this.initialTimetable?.id) {
            return;
        }

        const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
            size: 'sm',
            centered: true,
        });

        if (!confirm) {
            return;
        }

        await this.deleteTimetableAction(this.initialTimetable.id);
        this.$emit('deleted');
    }

    get startDate(): Date | undefined {
        if (!this.timeFrom || !this.initialTimetable?.start_date) {
            return undefined;
        }
        const [startTimeHours, startTimeMinutes] = this.timeFrom.split(':').map(val => Number(val));

        return setMinutes(setHours(parseISO(this.initialTimetable.start_date), startTimeHours), startTimeMinutes);
    }

    get endDate(): Date | undefined {
        if (!this.timeFrom || !this.initialTimetable?.end_date) {
            return undefined;
        }

        const [endTimeHours, endTimeMinutes] = this.timeUntil.split(':').map(val => Number(val));

        return setMinutes(setHours(parseISO(this.initialTimetable.end_date), endTimeHours), endTimeMinutes);
    }

    get operators(): User[] {
        return this.usersByRoleId(this.operatorRole.id);
    }

    get operatorRole(): Role {
        return this.roleByName('operator');
    }
}

