import { ActionContext } from 'vuex';
import { VehicleDashboardState } from '@/store/modules/vehicleDashboard/vehicleDashboardState';
import { RootState } from '@/store/rootState';
import { Vehicle } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import axios from 'axios';
import { LelyAuth, RelayService, Subscription } from '@tec/frontend-vue-shared';

const relayService = new RelayService(process.env.VUE_APP_RELAY_URL, 'machine', 'alarmcenter', () => String(LelyAuth.getAccessToken()));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscriptions: Record<string, Subscription<any>> = {};

const actions = {
    close: () => {
        relayService.close();
    },
    reset: (context: ActionContext<VehicleDashboardState, RootState>) => {
        context.commit('setMachine', null);
        context.commit('setCamera', null);
        context.commit('setCameraTopic', null);
        context.commit('setStatus', null);
        context.commit('setStatusTopic', null);
        context.commit('setDefinition', null);
        context.commit('setPaused', false);
        context.commit('setLaserscan', null);
        context.commit('setFootprint', null);
        context.commit('setMaintenanceMode', false);
        context.commit('clearRealtimeMapLayers');
    },
    initVehicle: async(context: ActionContext<VehicleDashboardState, RootState>, vehicle: Vehicle) => {
        context.commit('setMachine', vehicle.name);

        const vehicleType = context.rootGetters.vehicleTypeById(vehicle.vehicle_type_id);
        await context.dispatch('loadDefinition', vehicleType);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadDefinition: async(context: ActionContext<VehicleDashboardState, RootState>, vehicleType: VehicleType) => {
        const res = await axios.get(`/vehicle-types/${vehicleType.id}/dashboard-definition`);

        context.commit('setDefinition', res.data);
    },
    loadCamera: async(context: ActionContext<VehicleDashboardState, RootState>, topic: string) => {
        if (!context.state.machine) {
            return;
        }

        await context.dispatch('closeCamera');
        context.commit('setCameraTopic', topic);

        const subscription = await relayService.stringSubscribe(context.state.machine, topic);
        subscription.setCallback(response => {
            context.commit('setCamera', response.data);
        });

        subscriptions.camera = subscription;
    },
    loadStatus: async(context: ActionContext<VehicleDashboardState, RootState>, topic: string) => {
        if (!context.state.machine) {
            return;
        }

        await context.dispatch('closeStatus');
        context.commit('setStatusTopic', topic);

        const subscription = await relayService.stringSubscribe(context.state.machine, topic);
        subscription.setCallback(response => {
            context.commit('setStatus', response.data);
        });

        subscriptions.status = subscription;
    },
    loadPaused: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.pause?.topic) {
            return;
        }

        const subscription = await relayService.boolSubscribe(context.state.machine, context.state.definition.pause.topic);
        subscription.setCallback(response => {
            context.commit('setPaused', response.data);
        });
    },
    loadMaintenanceMode: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine) {
            return;
        }

        if (context.state.definition?.maintenance_mode?.topic) {
            const subscription = await relayService.boolSubscribe(context.state.machine, context.state.definition.maintenance_mode.topic);
            subscription.setCallback(response => {
                context.commit('setMaintenanceMode', response.data);
            });
        } else if (context.state.definition?.maintenance_mode_username?.topic) {
            const subscription = await relayService.subscribe<{
                username: string;
                enabled: string;
            }>(context.state.machine, context.state.definition.maintenance_mode_username.topic);
            subscription.setCallback(response => {
                context.commit('setMaintenanceMode', response.enabled);
            });
        }
    },
    loadSingleLaserscan: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.features?.laserscan_offset?.laserscan_topic) {
            return;
        }

        context.commit('setLaserscan', null);

        const subscription = await relayService.stringSubscribe(context.state.machine, context.state.definition?.features?.laserscan_offset?.laserscan_topic);
        subscription.setCallback(response => {
            const geojson = JSON.parse(response.data).geojson;
            context.commit('setLaserscan', geojson);
            relayService.unsubscribe(subscription);
        });
    },
    loadSingleFootprint: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.features?.laserscan_offset?.footprint_topic) {
            return;
        }

        context.commit('setFootprint', null);

        const subscription = await relayService.stringSubscribe(context.state.machine, context.state.definition?.features?.laserscan_offset?.footprint_topic);
        subscription.setCallback(response => {
            const geojson = JSON.parse(response.data).geojson;
            context.commit('setFootprint', geojson);
            relayService.unsubscribe(subscription);
        });
    },
    loadSingleRobotPosition: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.features?.laserscan_offset?.robotposition_topic) {
            return;
        }

        context.commit('setRobotPosition', null);

        const subscription = await relayService.subscribe<{x: number; y: number}>(
            context.state.machine,
            context.state.definition?.features?.laserscan_offset?.robotposition_topic);

        subscription.setCallback(response => {
            context.commit('setRobotPosition', { x: response.x, y: response.y });
            relayService.unsubscribe(subscription);
        });
    },
    loadRealtimeMapLayers: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.realtime_map_layers) {
            return;
        }

        const promisses = context.state.definition.realtime_map_layers.map(async(realtimeMapLayer) => {
            const subscription = await relayService.stringSubscribe(context.state.machine as string, realtimeMapLayer.topic);

            subscription.setCallback(data => {
                let geojson;

                try {
                    geojson = JSON.parse(data.data).geojson;

                    context.commit('setRealtimeMapLayer', {
                        topic: realtimeMapLayer.topic,
                        geojson,
                    });
                } catch (e) {
                    console.warn('Invalid JSON for realtimeMapLayer', data);
                }
            });
        });

        await Promise.all(promisses);
    },
    closeCamera: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        context.commit('setCamera', null);
        context.commit('setCameraTopic', null);

        if (subscriptions.camera) {
            await relayService.unsubscribe(subscriptions.camera);
            delete subscriptions.camera;
        }
    },
    closeStatus: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        context.commit('setStatusTopic', null);
        context.commit('setStatus', null);

        if (subscriptions.status) {
            await relayService.unsubscribe(subscriptions.status);
            delete subscriptions.camera;
        }
    },
    callService: async(context: ActionContext<VehicleDashboardState, RootState>, payload: { service: string; args: Record<string, unknown> }) => {
        if (!context.state.machine) {
            return;
        }

        return await relayService.callService(context.state.machine, payload.service, payload.args);
    },
    callPause: async(context: ActionContext<VehicleDashboardState, RootState>, payload: boolean) => {
        if (!context.state.machine || !context.state.definition?.pause?.service) {
            return;
        }

        await relayService.callService(context.state.machine, context.state.definition.pause.service, { data: payload });
    },
    callStop: async(context: ActionContext<VehicleDashboardState, RootState>) => {
        if (!context.state.machine || !context.state.definition?.stop?.service) {
            return;
        }

        await relayService.callService(context.state.machine, context.state.definition.stop.service);
    },
    callMaintenanceMode: async(context: ActionContext<VehicleDashboardState, RootState>, payload: boolean) => {
        if (!context.state.machine) {
            return;
        }

        if (context.state.definition?.maintenance_mode?.service) {
            await relayService.callService(context.state.machine, context.state.definition.maintenance_mode.service, { data: payload });
        } else if (context.state.definition?.maintenance_mode_username?.service) {
            await relayService.callService(context.state.machine, context.state.definition.maintenance_mode_username.service, {
                enable: payload,
                username: context.rootGetters.currentUser.username,
            });
        }
    },
};

export default actions;
