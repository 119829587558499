import { Component, Vue } from 'vue-property-decorator';
import { LelyTableRowStatus } from '@tec/frontend-vue-shared';
import { RelayStateType } from '@/core/types/relayStateType';

@Component
export default class UsesRelayStateRowStatus extends Vue {
    public getRelayStateRowStatus(state: RelayStateType): LelyTableRowStatus {
        return state !== 'Open' ? 'warning' : undefined;
    }
}
