import { DashboardState } from '@/store/modules/dashboard/dashboardState';
import DashboardVehicle from '@/core/interfaces/dashboard/dashboardVehicle';
import DashboardNotificationType from '@/core/interfaces/dashboard/dashboardNotificationType';
import DashboardNotificationTypeFeedback from '@/core/interfaces/dashboard/dashboardNotificationTypeFeedback';

const mutations = {
    setDashboard: (state: DashboardState, payload: {
        vehicles: DashboardVehicle[];
        notificationTypes: DashboardNotificationType[];
        notificationTypesFeedback: DashboardNotificationTypeFeedback[];
    }) => {
        state.vehicles = payload.vehicles;
        state.notificationTypes = payload.notificationTypes;
        state.notificationTypesFeedback = payload.notificationTypesFeedback;
    },
};

export default mutations;
