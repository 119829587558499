
































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Vehicle } from '@/core/interfaces/vehicle';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import { Ticket } from '@/core/interfaces/ticket';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';

@Component({
    components: { CategoryLabel },
})
export default class AddTicketForm extends Vue {
    farmId: number | null = null;
    subject = '';
    vehicleId: number | null = null;
    categoryId: number | null = null;

    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('tickets/categories')
    categories!: TicketCategory[];

    async submit(): Promise<void> {
        const isValid = (this.$refs.form as HTMLFormElement).reportValidity();

        if (!isValid) {
            return;
        }

        const ticket = await this.$store.dispatch('tickets/addTicket', {
            farm_id: this.farmId,
            vehicle_id: this.vehicleId,
            ticket_category_id: this.categoryId,
            subject: this.subject,
        } as Ticket);

        this.$emit('submitted', ticket);
    }

    @Watch('categories', { immediate: true })
    setFirstCategory(): void {
        this.categoryId = this.categories[0].id;
    }

    get vehicleOptions(): Vehicle[] {
        if (!this.farmId) {
            return [];
        }

        return this.$store.getters.vehiclesByFarm(this.farmId);
    }
}

