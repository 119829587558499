
















import { Component, Mixins } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { Vehicle as VehicleEntity } from '@/core/interfaces/vehicle';
import OpensDashboardModal from '@/components/mixins/OpensDashboardModal';
import VehiclesTable from '@/components/vehicles/VehiclesTable.vue';
import { Farm } from '@/core/interfaces/farm';
import VehiclesFilters from '@/components/vehicles/VehiclesFilters.vue';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({
    components: { VehiclesFilters, VehiclesTable, SidebarLayout },
})
export default class Vehicles extends Mixins(OpensDashboardModal) {
    @Getter('vehiclesSet')
    private vehicles!: VehicleEntity[];

    @Getter('farmById')
    private farmById!: (id: number) => Farm;

    @Getter('visibleVehicleTypes')
    private visibleVehicleTypes!: VehicleType[];

    filters: { farmId: number | null; query: string } = {
        farmId: null,
        query: '',
    };

    mounted(): void {
        this.$store.dispatch('relay/syncMachines');
    }

    get vehiclesSorted() {
        return this.vehicles.sort((a, b) => {
            const farmA = this.farmById(a.farm_id);
            const farmB = this.farmById(b.farm_id);

            return farmA.name.localeCompare(farmB.name);
        })
            .filter(vehicle => vehicle.name.toLowerCase().includes(this.filters.query.toLowerCase()))
            .filter(vehicle => {
                let farmValid = true;
                let vehicleTypeValid = true;

                if (this.filters.farmId) {
                    farmValid = vehicle.farm_id === this.filters.farmId;
                }

                if (this.visibleVehicleTypes.length) {
                    vehicleTypeValid = this.visibleVehicleTypes.map(vehicleType => vehicleType.id).includes(vehicle.vehicle_type_id);
                }

                return vehicleTypeValid && farmValid;
            });
    }
}

