
































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import { Vehicle } from '@/core/interfaces/vehicle';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { NotificationType } from '@/core/interfaces/notificationType';
import { User } from '@/core/interfaces/user';
import { format } from 'date-fns';
import { TicketCategory } from '@/core/interfaces/ticketCategory';
import CategoryLabel from '@/components/tickets/CategoryLabel.vue';
import vSelect from 'vue-select';

export interface ClosedTicketRequestFilters {
    assigned_to?: number;
    subject?: string;
    farm_id?: number;
    vehicle_id?: number;
    vehicle_type_id?: number;
    ticket_category_id?: number;
    created_from?: string;
    created_until?: string;
}

@Component({
    components: {
        vSelect,
        CategoryLabel,
    },
})
export default class ClosedTicketsFilters extends Vue {
    @Getter('farmsSet')
    farms!: Farm[];

    @Getter('vehiclesSet')
    vehicles!: Vehicle[];

    @Getter('vehicleTypesSet')
    vehicleTypes!: VehicleType[];

    @Getter('notificationTypesSet')
    notificationTypes!: NotificationType[];

    @Getter('usersSet')
    users!: User[];

    @Getter('tickets/categories')
    categories!: TicketCategory[];

    createdFrom: string | null = null;
    createdUntil: string | null = null;
    farmId: number | null = null;
    vehicleTypeId: number | null = null;
    vehicleId: number | null = null;
    assignedTo: number | null = null;
    subject: string | null = null;
    categoryId: number | null = null;
    todayDate = format(new Date(), 'yyyy-MM-dd');

    get vehiclesFiltered(): {label: string; id: number}[] {
        let vehicles = this.vehicles;

        if (this.farmId) {
            vehicles = vehicles.filter(vehicle => vehicle.farm_id === this.farmId);
        }

        if (this.vehicleTypeId) {
            vehicles = vehicles.filter(vehicle => vehicle.vehicle_type_id === this.vehicleTypeId);
        }

        return vehicles.sort((a, b) => {
            const farmA = this.$store.getters.farmById(a.farm_id) as Farm;
            const farmB = this.$store.getters.farmById(b.farm_id) as Farm;

            return farmA.name.localeCompare(farmB.name);
        }).map(vehicle => ({
            label: `${this.$store.getters.farmById(vehicle.farm_id).name} - ${vehicle.name}`,
            id: vehicle.id,
        }));
    }

    get filtersEmpty(): boolean {
        return Object.values(this.filters).every(value => !value);
    }

    get filters(): ClosedTicketRequestFilters {
        const filters: ClosedTicketRequestFilters = {};

        if (this.assignedTo) {
            filters.assigned_to = this.assignedTo;
        }

        if (this.createdFrom) {
            filters.created_from = this.createdFrom;
        }

        if (this.createdUntil) {
            filters.created_until = this.createdUntil;
        }

        if (this.vehicleId) {
            filters.vehicle_id = this.vehicleId;
        }

        if (this.vehicleTypeId) {
            filters.vehicle_type_id = this.vehicleTypeId;
        }

        if (this.farmId) {
            filters.farm_id = this.farmId;
        }

        if (this.subject) {
            filters.subject = this.subject;
        }

        if (this.categoryId) {
            filters.ticket_category_id = this.categoryId;
        }

        return filters;
    }

    reset(): void {
        this.createdFrom = null;
        this.createdUntil = null;
        this.assignedTo = null;
        this.vehicleId = null;
        this.vehicleTypeId = null;
        this.farmId = null;
        this.subject = null;
        this.categoryId = null;
    }

    @Watch('filters')
    emit(): void {
        this.$emit('submit', this.filters);
    }
}

