import { SettingsState } from '@/store/modules/settings/settingsState';
import { Rule } from '@/core/interfaces/rule';
import { Timetable } from '@/core/interfaces/timetable';
import Vue from 'vue';

const mutations = {
    setRules: (state: SettingsState, payload: Rule[]) => {
        state.rules = payload;
    },
    updateTimetable: (state: SettingsState, payload: Timetable) => {
        const currentIndex = state.timetable.findIndex(timetable => timetable.id === payload.id);

        if (currentIndex > -1) {
            Vue.set(state.timetable, currentIndex, payload);
        } else {
            state.timetable.push(payload);
        }
    },
    deleteTimetable: (state: SettingsState, payload: number) => {
        const index = state.timetable.findIndex(timetable => timetable.id === payload);

        if (index > -1) {
            Vue.delete(state.timetable, index);
        }
    },
    setVisibleTimetable: (state: SettingsState, payload: number[]) => {
        state.visibleTimetableRoles = payload;
    },

    toggleVisibleTimetable: (state: SettingsState, payload: number) => {
        const visible = state.visibleTimetableRoles.includes(payload);

        if (!visible) {
            state.visibleTimetableRoles.push(payload);
        } else {
            state.visibleTimetableRoles = state.visibleTimetableRoles.filter(id => id !== payload);
        }
    },
};

export default mutations;
