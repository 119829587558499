





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { KnowledgeBaseSection } from '@/core/interfaces/knowledgeBaseSection';
import draggable from 'vuedraggable';
import { VehicleType } from '@/core/interfaces/vehicleType';

@Component({
    components: {
        draggable,
    },
})
export default class KnowledgeBaseReorderModal extends Vue {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Getter('knowledgeBase/sections')
    sections!: KnowledgeBaseSection[];

    list: KnowledgeBaseSection[] = [];
    edittingSectionName = '';
    edittingSection: KnowledgeBaseSection | null = null;

    created(): void {
        this.updateList();
    }

    updateList(): void {
        this.list = [...this.sections];
    }

    async saveOrder(): Promise<void> {
        await Promise.all(this.list.map((section, index) => this.$store.dispatch('knowledgeBase/updateSection', {
            vehicleTypeId: this.vehicleType.id,
            id: section.id,
            order: index,
            name: section.name,
        })));

        await this.$store.dispatch('knowledgeBase/fetchSections', this.vehicleType.id);
    }

    async deleteSection(sectionId: number): Promise<void> {
        const section = this.$store.getters['knowledgeBase/sectionById'](sectionId);

        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${section.name}?`, {
            size: 'sm',
        });

        if (!confirm) {
            return;
        }

        await this.$store.dispatch('knowledgeBase/deleteSection', {
            vehicleTypeId: this.vehicleType.id,
            id: sectionId,
        });

        if (String(this.$route.params.sectionId) === String(sectionId)) {
            await this.$router.push({
                name: 'knowledge-base.vehicle-type.page',
                params: {
                    vehicleTypeName: this.vehicleType.name,
                },
            });
        }

        this.updateList();
    }

    async saveSectionName(): Promise<void> {
        if (this.edittingSection) {
            await this.$store.dispatch('knowledgeBase/updateSection', {
                id: this.edittingSection.id,
                vehicleTypeId: this.vehicleType.id,
                name: this.edittingSectionName,
                order: this.edittingSection.order,
            });
            await this.$store.dispatch('knowledgeBase/fetchSections', this.vehicleType.id);

            this.$bvModal.hide('section-name');

            this.updateList();
        } else {
            const section = await this.$store.dispatch('knowledgeBase/addSection', {
                vehicleTypeId: this.vehicleType.id,
                name: this.edittingSectionName,
            });

            this.$bvModal.hide('section-name');

            this.updateList();
        }
    }
}
