


































import { Component, Prop, Watch } from 'vue-property-decorator';
import NotificationLevel from '@/core/enums/notificationLevel';
import { State } from 'vuex-class';
import RunViewPreference from '@/core/enums/runViewPreference';
import Vue from 'vue';

@Component({
    components: {},
})
export default class BaseItemCard extends Vue {
    @Prop({ required: true })
    level!: NotificationLevel;

    @Prop({ type: Boolean })
    showSubtitle!: boolean;

    @Prop({ type: Boolean })
    elevate!: boolean;

    @Prop()
    viewPreferenceEventBus!: Vue;

    @Prop({ type: Boolean })
    isNew!: boolean;

    @Prop({ type: Boolean })
    opened!: boolean;

    @State('runViewPreference')
    runViewPreference!: RunViewPreference;

    open = false;
    wasOpened = false;
    collapseId!: string;

    created(): void {
        if (this.viewPreferenceEventBus) {
            this.viewPreferenceEventBus.$on(RunViewPreference.COLLAPSED, () => {
                this.open = false;
            });
            this.viewPreferenceEventBus.$on(RunViewPreference.EXPANDED, () => {
                this.open = true;
            });

            this.open = this.runViewPreference === RunViewPreference.EXPANDED;
        }

        this.collapseId = 'collapse-' + Math.random();

        if (this.opened) {
            this.open = true;
        }
    }

    protected get cardClass(): Record<string, boolean> {
        return {
            [`level-${this.level.toLowerCase()}`]: true,
            open: this.open,
            elevate: this.elevate,
            'is-new': this.isNew,
        };
    }

    @Watch('open')
    protected setOpened(open: boolean) {
        if (open) {
            this.wasOpened = true;
        }
    }
}

