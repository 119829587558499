



































































import { Component, Prop } from 'vue-property-decorator';
import DashboardVehicle from '@/core/interfaces/dashboard/dashboardVehicle';
import { Vehicle } from '@/core/interfaces/vehicle';
import { Getter } from 'vuex-class';
import { Farm } from '@/core/interfaces/farm';
import DashboardPosition from '@/components/dashboard/DashboardPosition.vue';
import { mixins } from 'vue-class-component';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';
import { intervalToDuration } from 'date-fns';
import DashboardDifference from '@/components/dashboard/DashboardDifference.vue';

@Component({
    components: { DashboardDifference, DashboardPosition },
})
export default class DashboardVehiclesWidget extends mixins(FormatsDurationShort) {
    @Getter('vehicleById')
    vehicleById!: (id: number) => Vehicle;

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Prop({ required: true })
    vehicles!: DashboardVehicle[];

    @Prop({ type: Boolean, default: true })
    showDowntime!: boolean;

    @Prop({ type: Boolean, default: false })
    clickable!: boolean;

    @Prop({ default: 0 })
    periodDurationInMinutes!: number;

    calculateDowntimePercentage(vehicle: DashboardVehicle): number {
        return ((this.periodDurationInMinutes - vehicle.selected_period.uptime) / this.periodDurationInMinutes) * 100;
    }

    get sortedVehicles(): DashboardVehicle[] {
        return this.vehicles.sort((a, b) => {
            if (this.showDowntime) {
                const uptimeDiff = this.calculateDowntimePercentage(b) - this.calculateDowntimePercentage(a);

                if (uptimeDiff !== 0) {
                    return uptimeDiff;
                }

                return b.selected_period.alarms - a.selected_period.alarms;
            }

            return b.selected_period.alarms - a.selected_period.alarms;
        });
    }

    get downtimePercentagesSelectedPeriod(): number[] {
        return this.sortedVehicles.map(vehicle => Math.round(this.calculateDowntimePercentage(vehicle) * 10) / 10);
    }

    get downtimeVariantSelectedPeriod(): string[] {
        return this.downtimePercentagesSelectedPeriod.map(downtime => {
            if (downtime < 3) {
                return 'warning';
            } else {
                return 'danger';
            }
        });
    }

    get formattedDowntimeDurations(): string[] {
        return this.sortedVehicles.map(vehicle =>
            this.formatDowntime(this.periodDurationInMinutes - vehicle.selected_period.uptime));
    }

    formatDowntime(downtime: number): string {
        return this.formatDurationShort(intervalToDuration({
            start: new Date(0),
            end: new Date(downtime * 60 * 1000),
        }));
    }

    get maxDowntimePercentage(): number {
        return Math.max(...this.downtimePercentagesSelectedPeriod);
    }

    get downtimes(): { selected: number; previous: number }[] {
        return this.sortedVehicles.map(vehicle => {
            const selected = Math.max(this.periodDurationInMinutes - vehicle.selected_period.uptime, 0);
            const previous = Math.max(this.periodDurationInMinutes - vehicle.previous_period.uptime, 0);

            return {
                previous,
                selected,
            };
        });
    }
}

