














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Pagination extends Vue {
    @Prop({ required: true })
    private totalNumberOfRecords!: number;

    @Prop({ required: true })
    private recordsPerPage!: number;

    @Prop({ required: true })
    private selectedPage!: number;

    private get numberOfPages() {
        return Math.ceil(this.totalNumberOfRecords / this.recordsPerPage);
    }

    private get pages(): number[] {
        return [...Array(this.numberOfPages).keys()].map(p => p + 1);
    }
}

