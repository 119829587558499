













import { Component, Prop, Vue } from 'vue-property-decorator';
import { RuleTimetable } from '@/core/interfaces/RuleTimetable';
import SettingsRuleModalTimetableRow from '@/components/settings/rule/SettingsRuleModalTimetableRow.vue';

@Component({
    components: { SettingsRuleModalTimetableRow },
})
export default class SettingsRuleModalTimetable extends Vue {
    @Prop({ default: () => [] })
    protected value!: RuleTimetable[];

    protected updateItem(index: number, timetable: RuleTimetable): void {
        this.$set(this.value, index, timetable);
        this.$emit('input', this.value);
    }

    protected deleteItem(index: number): void {
        this.$delete(this.value, index);
    }
}
