import { RelayState } from '@/store/modules/relay/relayState';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { RelayMachine } from '@/core/interfaces/relayMachine';

const mutations = {
    setConnections: (state: RelayState, payload: RelayConnection[]) => {
        state.connections = payload;
    },

    setMachines: (state: RelayState, payload: RelayMachine[]) => {
        state.machines = payload;
    },
};

export default mutations;
