



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { NotifyAction } from '@/core/interfaces/notifyAction';
import SettingsRuleModalActionsRow from '@/components/settings/rule/SettingsRuleModalActionsRow.vue';
import { RuleTimetable } from '@/core/interfaces/RuleTimetable';
import { Getter } from 'vuex-class';
import { Role } from '@/core/interfaces/role';

@Component({
    components: { SettingsRuleModalActionsRow },
})
export default class SettingsRuleModalActions extends Vue {
    @Prop({ default: () => [] })
    protected value!: NotifyAction[];

    @Getter('rolesSet')
    protected roles!: Role[];

    protected updateItem(index: number, timetable: RuleTimetable): void {
        this.$set(this.value, index, timetable);
        this.$emit('input', this.value);
    }

    protected deleteItem(index: number): void {
        this.$delete(this.value, index);
    }

    get usedRoles(): Role[] {
        return this.roles.filter(role => !this.value.some(action => action.role_id === role.id));
    }
}
