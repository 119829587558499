import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { DashboardState } from '@/store/modules/dashboard/dashboardState';

const getters: GetterTree<DashboardState, RootState> = {
    vehicles: state => state.vehicles,
    notificationTypes: state => state.notificationTypes,
    notificationTypesFeedback: state => state.notificationTypesFeedback,
};

export default getters;
