





























import { Component, Mixins } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Rule } from '@/core/interfaces/rule';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import SettingsRuleTable from '@/components/settings/rule/SettingsRuleTable.vue';
import vSelect from 'vue-select';
import SettingsRuleModal from '@/components/settings/rule/SettingsRuleModal.vue';
import { deepClone } from '@/helpers';
import { VehicleType } from '@/core/interfaces/vehicleType';

const settings = namespace('settings');

@Component({
    components: { SettingsRuleTable, vSelect },
})
export default class SettingsRules extends Mixins(UsesNotificationMessage) {
    @settings.Getter('rulesByVehicleType')
    protected rulesByVehicleType!: (vehicleTypeId: number | null) => Rule[];

    @Getter('vehicleTypesSet')
    protected vehicleTypes!: VehicleType[];

    @Getter('isAdmin')
    isAdmin!: boolean;

    protected selectedVehicleType: VehicleType | null = null;

    get vehicleTypeSelectOptions(): { name: string; vehicleType: VehicleType | null }[] {
        return [
            this.vehicleTypeGeneralOption,
            ...this.vehicleTypeOptions,
        ];
    }

    get vehicleTypeOptions(): { name: string; vehicleType: VehicleType }[] {
        return this.vehicleTypes.map(vehicleType => {
            return {
                name: `${vehicleType.name}`,
                vehicleType,
            };
        });
    }

    get vehicleTypeGeneralOption(): { name: string; vehicleType: null } {
        return {
            name: `${this.$t('rules.vehicle-type-general-rules')}`,
            vehicleType: null,
        };
    }

    get rules() {
        return this.rulesByVehicleType(this.selectedVehicleType?.id || null);
    }

    protected showModal(rule?: Rule | null): void {
        if (rule) {
            rule = deepClone(rule) as Rule;
        } else {
            rule = {
                id: null,
                label: '',
                farms: [],
                vehicle_phases: [],
                vehicle_type_id: this.selectedVehicleType ? this.selectedVehicleType.id : null,
                notification_types: [],
                notification_levels: [],
                timetable: [],
                notify_actions: [],
            };
        }

        this.$modal.show(SettingsRuleModal, {
            rule,
        }, { adaptive: true, height: 'auto' });
    }

    protected async removeRule(rule: Rule) {
        await this.$store.dispatch('settings/deleteRule', rule);
    }
}
