
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Loader from '@/components/Loader.vue';
import RunsTableFilters from '@/components/run/runs-table/RunsTableFilters.vue';
import RunsTable from '@/components/run/runs-table/RunsTable.vue';
import { RunsRequestFilters } from '@/core/interfaces/runsRequestFilters';
import RunsTableViewMode from '@/core/enums/runsTableViewMode';
import RunViewPreferenceControl from '@/components/run/RunViewPreferenceControl.vue';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import BlankSlate from '@/components/BlankSlate.vue';
import Pagination from '@/components/Pagination.vue';
import { NotificationOrRunWithNotificationsHolder } from '@/core/interfaces/notificationOrRunWithNotificationsHolder';
import axios, { CancelTokenSource } from 'axios';
import filtersToUrlParams from '@/core/FiltersToUrlParams';
import GroupRunsButton from '@/components/run/GroupRunsButton.vue';
import GroupsNotifications from '@/components/mixins/GroupsNotifications';
import { Run } from '@/core/interfaces/run';
import { Notification } from '@/core/interfaces/notification';

let runsTableCancelToken: CancelTokenSource | null = null;

@Component({
    components: {
        GroupRunsButton,
        SidebarLayout,
        RunViewPreferenceControl,
        Pagination,
        BlankSlate,
        RunsTable,
        RunsTableFilters,
        Loader,
    },
})
export default class RunsTableView extends GroupsNotifications {
    @Prop({ type: String })
    view!: RunsTableViewMode;

    page = 1;
    filters: RunsRequestFilters | null = null;

    recordsPerPage = 50;

    viewPreferenceEventBus = new Vue();

    @Watch('view', { immediate: true })
    protected async loadRuns() {
        window.scrollTo(0, 0);

        const filters = this.filters || {};

        filters.onlyOpen = this.view === RunsTableViewMode.OPEN;
        filters.archived = this.view === RunsTableViewMode.ARCHIVED;
        filters.offset = this.offset;
        filters.limit = this.recordsPerPage;

        if (this.$store.state.visibleNotificationLevels.length) {
            filters.notificationLevels = this.$store.state.visibleNotificationLevels;
        }

        if (this.$store.state.visibleVehicleTypeIds.length) {
            filters.vehicleTypeIds = this.$store.state.visibleVehicleTypeIds;
        }

        this.$store.commit('runs/setTableRunsAndNotifications', {});
        this.$store.commit('runs/setTableTotalNumber', 0);

        if (runsTableCancelToken) {
            runsTableCancelToken.cancel();
        }

        try {
            runsTableCancelToken = axios.CancelToken.source();
            const res = await axios.get('/notifications', {
                params: filtersToUrlParams(filters),
                cancelToken: runsTableCancelToken.token,
            });

            this.$store.commit('runs/setTableRunsAndNotifications', res.data);
            this.$store.commit('runs/setTableTotalNumber', res.headers['lely-number-of-total']);
            this.$store.commit('runs/setTableLoading', false);
        } catch (thrown) {
            if (axios.isCancel(thrown)) {
                return;
            }

            this.$store.commit('runs/setTableLoading', false);
            throw thrown;
        }
    }

    setFilters(filters: RunsRequestFilters) {
        this.page = 0;
        this.filters = filters;

        this.loadRuns();
    }

    setPage(page: number) {
        this.page = page;

        this.loadRuns();
    }

    get offset() {
        return (this.page - 1) * this.recordsPerPage;
    }

    get groupRuns(): boolean {
        return this.$store.state.groupRuns;
    }

    get items(): NotificationOrRunWithNotificationsHolder[] {
        if (!this.notifications) {
            return [];
        }

        if (this.groupRuns) {
            return this.groupNotificationsByRun(this.notifications, this.runs);
        }

        return this.notifications;
    }

    get notifications(): Notification[] {
        return this.$store.getters['runs/tableNotifications'];
    }

    get runs(): Run[] {
        return this.$store.getters['runs/tableRuns'];
    }

    get loading(): boolean {
        return this.$store.getters['runs/tableLoading'];
    }

    get totalNumberOfRecords(): number {
        return this.$store.getters['runs/tableTotalNumberOfRecords'];
    }
}

