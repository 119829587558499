


































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { RuleTimetable } from '@/core/interfaces/RuleTimetable';
import UsesDateFnsLocale from '@/components/mixins/UsesDateFnsLocale';
import { format } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import nextDay from 'date-fns/nextDay';

const validTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

@Component({
    components: {},
})
export default class SettingsRuleModalTimetableRow extends Mixins(UsesDateFnsLocale) {
    @Prop({ required: true })
    protected value!: RuleTimetable;

    hoursFrom = '';
    hoursTo = '';

    get isEmptyRow(): boolean {
        return this.value.weekday === null;
    }

    @Watch('hoursFrom')
    updateHoursFrom(): void {
        if (this.hoursFrom.match(validTimeRegex)) {
            this.value.time_from = this.hoursFrom;
        }
    }

    @Watch('hoursTo')
    updateHoursTo(): void {
        if (this.hoursTo.match(validTimeRegex)) {
            this.value.time_to = this.hoursTo;
        }
    }

    @Watch('value', { immediate: true, deep: true })
    setTimes(): void {
        if (this.value.time_from) {
            this.hoursFrom = this.value.time_from;
        } else {
            this.hoursFrom = '';
        }

        if (this.value.time_to) {
            this.hoursTo = this.value.time_to;
        } else {
            this.hoursTo = '';
        }
    }

    get weekdays() {
        return [0, 1, 2, 3, 4, 5, 6].map(weekday => {
            return format(nextDay(new Date(), weekday), 'EEEEEE', { locale: this.dateFnsLocale });
        });
    }
}
