























import { Component, Vue } from 'vue-property-decorator';
import AddTicketForm from '@/components/tickets/AddTicketForm.vue';

@Component({
    components: {
        AddTicketForm,
    },
})
export default class AddTicketModal extends Vue {
    get id(): string {
        return `add-ticket-modal-${Date.now()}`;
    }
}
