
















import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import FormatsDate from '@/components/mixins/FormatsDate';
import NotificationLevelIcon from '@/components/notification/NotificationLevelIcon.vue';
import { Notification } from '@/core/interfaces/notification';

@Component({
    components: { NotificationLevelIcon },
})
export default class RunCardNotificationsRow extends Mixins(
    UsesNotificationDetails,
    UsesNotificationMessage,
    TranslatesVehiclePhase,
    FormatsDate,
) {
    protected get rowClass(): Record<string, boolean> {
        return {
            [`level-${this.notification.notification_level.toLowerCase()}`]: true,
        };
    }
}
