import { RunsState } from '@/store/modules/runs/runsState';

const state: RunsState = {
    runs: [],
    notifications: [],
    tableNotifications: [],
    tableRuns: [],
    tableLoading: false,
    tableTotalNumberOfRecords: 0,
};

export default state;
