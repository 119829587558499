









import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import TimelineItem from '@/components/TimelineItem.vue';
import NotifyLog from '@/core/interfaces/notifyLog';
import { parseISO } from 'date-fns';

@Component({
    components: { TimelineItem },
})
export default class NotificationTimelineEventItem extends Vue {
    @Prop({ required: true })
    protected type!: 'created_at' | 'cleared_at';

    @Prop({ default: false })
    protected date!: Date;

    @Prop({ default: true })
    protected differentDateThanPrevious!: boolean;

    get icon(): string {
        return this.type === 'created_at' ? 'lely-icon-alarm' : 'lely-icon-close';
    }

    get text(): string {
        if (this.type === 'created_at') {
            return 'Notification was <strong>reported</strong>';
        }

        return 'Notification was <strong>cleared</strong>';
    }
}
