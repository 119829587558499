import { TicketsState } from '@/store/modules/tickets/ticketsState';

const state: TicketsState = {
    tickets: [],
    closedTicketIds: [],
    totalNumberOfClosedTickets: 0,
    categories: [],
    messages: [],
    seenTickets: [],
    seenMessages: [],
};

export default state;
