













import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { RelayConnection } from '@/core/interfaces/relayConnection';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { RelayClient } from '@/core/interfaces/relayClient';
import { Phone } from '@/core/interfaces/phone';
import { User } from '@/core/interfaces/user';
import { mixins } from 'vue-class-component';
import UsesRelayStateRowStatus from '@/components/relay/usesRelayStateRowStatus';

const relayStore = namespace('relay');

@Component({
    components: {},
})
export default class RelayClients extends mixins(UsesRelayStateRowStatus) {
    @relayStore.Getter('connectionByUuid')
    connectionByUuid!: (uuid: string) => RelayConnection;

    @Getter('phoneByUdid')
    phoneByUdid!: (udid: string) => Phone;

    @Getter('userById')
    userById!: (id: number) => User;

    @Prop({ required: true })
    clients!: RelayClient[];

    get columns(): LelyTableColumnsConfig<RelayClient> {
        return [
            {
                header: 'IP',
                value: client => {
                    const connection = this.connectionByUuid(client.uuid);

                    if (!connection) {
                        return '-';
                    }

                    return connection.ip_address;
                },
                sub: client => {
                    const str = [];
                    if (client.is_auth) {
                        str.push('auth');
                    }
                    if (client.is_admin) {
                        str.push('admin');
                    }

                    return str.join(', ');
                },
            },
            {
                header: 'State',
                value: 'state',
            },
            {
                header: 'User',
                value: client => {
                    if (!client.user_id) {
                        return '-';
                    }
                    const user = this.userById(client.user_id);

                    return user?.username || '-';
                },
                sub: client => {
                    if (!client.phone_udid) {
                        return '';
                    }
                    const phone = this.phoneByUdid(client.phone_udid);

                    return phone?.device_info || '';
                },
            },
        ];
    }
}

