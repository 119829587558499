








































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Rule } from '@/core/interfaces/rule';
import { Getter } from 'vuex-class';
import { NotificationType } from '@/core/interfaces/notificationType';
import { VehiclePhase } from '@/core/interfaces/vehiclePhase';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import UsesDateFnsLocale from '@/components/mixins/UsesDateFnsLocale';
import { format } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import nextDay from 'date-fns/nextDay';
import GetChannelsFromNotifyLog from '@/components/mixins/GetChannelsFromNotifyLog';
import { Role } from '@/core/interfaces/role';
import { VehicleType } from '@/core/interfaces/vehicleType';
import TranslatesNotificationLevel from '@/components/mixins/TranslatesNotificationLevel';
import { Farm } from '@/core/interfaces/farm';

@Component({
    components: {},
})
export default class SettingsRuleTableRow extends Mixins(TranslatesVehiclePhase, UsesDateFnsLocale, GetChannelsFromNotifyLog, TranslatesNotificationLevel) {
    @Prop()
    protected rule!: Rule;

    @Getter('notificationTypeById')
    protected notificationTypeById!: (id: number) => NotificationType;

    @Getter('roleById')
    protected roleById!: (id: number) => Role;

    @Getter('vehiclePhaseById')
    protected vehiclePhaseById!: (id: number) => VehiclePhase;

    @Getter('vehicleTypeById')
    protected vehicleTypeById!: (id: number) => VehicleType;

    @Getter('farmById')
    protected farmById!: (id: number) => Farm;

    @Getter('isAdmin')
    isAdmin!: boolean;

    get notificationTypes(): NotificationType[] {
        return this.rule.notification_types.map(type => this.notificationTypeById(type));
    }

    get notificationTypeAndTranslatedMessage(): { notificationType: NotificationType; message: string }[] | null {
        return this.notificationTypes.map(notificationType => {
            if (!this.vehicleType) {
                return {
                    notificationType,
                    message: '-',
                };
            }

            const message = this.$i18n.t(`notification-codes.${this.vehicleType.name}.${notificationType.code}`).toString();

            return {
                notificationType,
                message,
            };
        });
    }

    get farms(): Farm[] {
        return this.rule.farms.map(farmId => this.farmById(farmId));
    }

    get vehiclePhases(): string | null {
        return this.rule.vehicle_phases
            .map(phase => this.vehiclePhaseById(phase))
            .map(phase => this.translateVehiclePhase(phase))
            .join(', ');
    }

    get notificationLevels(): string | null {
        return this.rule.notification_levels
            .map(level => this.translateNotificationLevel(level))
            .join(', ');
    }

    get vehicleType(): VehicleType | null {
        if (!this.rule.vehicle_type_id) {
            return null;
        }

        return this.vehicleTypeById(this.rule.vehicle_type_id);
    }

    get timetableSummary(): string[] {
        if (!this.rule.timetable) {
            return [];
        }
        return this.rule.timetable.map(timetable => {
            const date = nextDay(new Date(), timetable.weekday);
            const weekday = format(date, 'EEEEEE', { locale: this.dateFnsLocale });

            return `${weekday}: ${timetable.time_from} - ${timetable.time_to}`;
        });
    }

    get actions(): string[] {
        if (!this.rule.notify_actions) {
            return [];
        }

        return this.rule.notify_actions
            .sort((a, b) => a.notify_threshold_minutes - b.notify_threshold_minutes)
            .map(action => {
                const role = this.roleById(Number(action.role_id));
                const threshold = action.notify_threshold_minutes || 0;

                return threshold
                    ? this.$t('rules.rule-description', {
                        role: role ? role.code_name : '',
                        threshold,
                        channels: this.getChannelsFromNotifyLog(action),
                    }).toString()
                    : this.$t('rules.rule-description-direct', {
                        role: role ? role.code_name : '',
                        channels: this.getChannelsFromNotifyLog(action),
                    }).toString();
            });
    }
}
