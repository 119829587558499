























import { Component, Prop, Watch } from 'vue-property-decorator';
import { TicketMessage } from '@/core/interfaces/ticketMessage';
import { mixins } from 'vue-class-component';
import FormatsDate from '@/components/mixins/FormatsDate';
import TicketMessageMedia from '@/components/tickets/TicketMessageMedia.vue';

@Component({
    components: { TicketMessageMedia },
})
export default class TicketMessageBubble extends mixins(FormatsDate) {
    @Prop({ required: true })
    message!: TicketMessage;

    @Prop({ required: true, type: Number })
    ticketId!: number;

    @Prop({ required: true, type: Boolean })
    editting!: boolean;

    updateMessageBody = '';

    @Watch('editting')
    updateMessageBodyValue(): void {
        this.updateMessageBody = this.message.body;
    }
}

