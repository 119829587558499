





































import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import NotifyLog from '@/core/interfaces/notifyLog';
import FormatsDate from '@/components/mixins/FormatsDate';
import GetChannelsFromNotifyLog from '@/components/mixins/GetChannelsFromNotifyLog';

@Component({
    components: {},
})
export default class NotificationLogTable extends Mixins(FormatsDate, GetChannelsFromNotifyLog) {
    @Prop({ required: true })
    protected log!: NotifyLog[];
}
