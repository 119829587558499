






































import { Component, Prop } from 'vue-property-decorator';
import { Phone } from '@/core/interfaces/phone';
import axios from 'axios';
import { mixins } from 'vue-class-component';
import UsesUsernameOfPhone from '@/components/settings/phone/UsesUsernameOfPhone';
import PushNotificationType from '@/core/interfaces/pushNotificationType';

@Component({
    components: {},
})
export default class SendSimpleForm extends mixins(UsesUsernameOfPhone) {
    @Prop({ required: true })
    phone!: Phone;

    PushNotificationType = PushNotificationType;

    title = '';
    body = '';
    error = '';
    pushNotificationType = PushNotificationType.eSTANDARD;

    async submit(): Promise<void> {
        this.error = '';

        try {
            await axios.post(`${process.env.VUE_APP_API_URL}/phones/${this.phone.id}/send_message`, {
                title: this.title,
                body: this.body,
                message_type: this.pushNotificationType,
            });
        } catch (err) {
            this.error = String(err);
            return;
        }

        this.$emit('close');
    }
}

