







































import { Component, Vue } from 'vue-property-decorator';
import NotificationCard from '@/components/notification/NotificationCard.vue';
import RunCard from '@/components/run/RunCard.vue';
import RunBlankSlate from '@/components/BlankSlate.vue';
import RunViewPreferenceControl from '@/components/run/RunViewPreferenceControl.vue';
import SidebarLayout from '@/components/views/layouts/SidebarLayout.vue';
import VisibleVehicleTypesFilter from '@/components/VisibleVehicleTypesFilter.vue';
import { NotificationOrRunWithNotificationsHolder } from '@/core/interfaces/notificationOrRunWithNotificationsHolder';
import RunOrNotificationCard from '@/components/run/RunOrNotificationCard.vue';
import GroupsNotifications from '@/components/mixins/GroupsNotifications';
import GroupRunsButton from '@/components/run/GroupRunsButton.vue';
import VehicleTypeSelectedWarning from '@/components/run/VehicleTypeSelectedWarning.vue';

@Component({
    components: {
        VehicleTypeSelectedWarning,
        GroupRunsButton,
        RunOrNotificationCard,
        RunViewPreferenceControl,
        VisibleVehicleTypesFilter,
        RunBlankSlate,
        RunCard,
        NotificationCard,
        SidebarLayout,
    },
})
export default class AssignedRuns extends GroupsNotifications {
    viewPreferenceEventBus = new Vue();

    get assignedToOthersItems(): NotificationOrRunWithNotificationsHolder[] {
        return this.groupRuns
            ? this.groupNotificationsByRun(this.$store.getters['runs/notificationsAssignedToOthers'], this.$store.getters['runs/runs'])
            : this.$store.getters['runs/notificationsAssignedToOthers'];
    }

    get assignedToMeItems(): NotificationOrRunWithNotificationsHolder[] {
        return this.groupRuns
            ? this.groupNotificationsByRun(this.$store.getters['runs/notificationsAssignedToMe'], this.$store.getters['runs/runs'])
            : this.$store.getters['runs/notificationsAssignedToMe'];
    }

    get groupRuns(): boolean {
        return this.$store.state.groupRuns;
    }
}

