




















import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import RunCardNotificationsRow from '@/components/run/RunCardNotificationsRow.vue';
import NotificationCard from '@/components/notification/NotificationCard.vue';
import { Notification } from '@/core/interfaces/notification';
import { Run } from '@/core/interfaces/run';
import RunNotificationsModal from '@/components/run/RunNotificationsModal.vue';

@Component({
    components: { NotificationCard, RunCardNotificationsRow },
})
export default class RunCardNotifications extends Mixins(
    UsesNotificationMessage,
    TranslatesVehiclePhase,
) {
    @Prop({ required: true })
    notifications!: Notification[];

    @Prop({ required: true })
    run!: Run;

    @Prop({ type: Boolean })
    showRunNotificationsLink!: boolean;

    @Prop({ type: Number, default: null })
    initialNotificationId!: number;

    async openRunNotificationsModal(run: Run): Promise<void> {
        this.$modal.show(
            RunNotificationsModal,
            { run },
            { adaptive: true, height: 'auto', width: '95%', shiftY: 0.05 },
        );
    }
}
