



























import { Component, Mixins } from 'vue-property-decorator';
import NotificationLevelIcon from '@/components/notification/NotificationLevelIcon.vue';
import Avatar from '@/components/Avatar.vue';
import UsesRunDetails from '@/components/mixins/UsesRunDetails';
import FormatsDate from '@/components/mixins/FormatsDate';
import RunActions from '@/components/run/RunActions.vue';
import NotifiedRolesIndicator from '@/components/NotifiedRolesIndicator.vue';

@Component({
    components: { NotifiedRolesIndicator, RunActions, Avatar, NotificationLevelIcon },
})
export default class RunsTableRow extends Mixins(
    UsesRunDetails,
    FormatsDate,
) {

}

