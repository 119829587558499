
















import { Component, Vue } from 'vue-property-decorator';
import DropdownMenu from '@innologica/vue-dropdown-menu';
import { State } from 'vuex-class';

@Component({
    components: {
        DropdownMenu,
    },
})
export default class LocaleSelect extends Vue {
    showLanguages = false;

    @State('locales')
    private locales!: Record<string, string>;

    get currentLocale() {
        return this.locales[this.$i18n.locale];
    }

    get availableLocales(): [string, string][] {
        return Object.entries(this.locales);
    }

    setLocale(key: string) {
        this.$i18n.locale = key;
        this.showLanguages = false;
        window.localStorage.setItem('lely_alarmcenter_locale', key);
    }
}

