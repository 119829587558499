import { Module } from 'vuex';
import { VehicleDashboardState } from '@/store/modules/vehicleDashboard/vehicleDashboardState';
import { RootState } from '@/store/rootState';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

const vehicleDashboard: Module<VehicleDashboardState, RootState> = {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};

export default vehicleDashboard;
