import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Farm } from '@/core/interfaces/farm';
import { Vehicle } from '@/core/interfaces/vehicle';
import { User } from '@/core/interfaces/user';
import FormatsDurationShort from '@/components/mixins/FormatsDurationShort';
import { Ticket } from '@/core/interfaces/ticket';
import { TicketCategory } from '@/core/interfaces/ticketCategory';

@Component
export default class UsesTicket extends Mixins(FormatsDurationShort) {
    @Prop({ required: true })
    ticket!: Ticket;

    get category(): TicketCategory {
        return this.$store.getters['tickets/categoryById'](this.ticket.ticket_category_id);
    }

    get farm(): Farm {
        return this.$store.getters.farmById(this.ticket.farm_id);
    }

    get assignedTo(): User | null {
        if (!this.ticket.assigned_to) {
            return null;
        }

        return this.$store.getters.userById(this.ticket.assigned_to);
    }

    get createdByUser(): User | null {
        if (!this.ticket.created_by_user) {
            return null;
        }

        return this.$store.getters.userById(this.ticket.created_by_user);
    }

    get createdByVehicle(): Vehicle | null {
        if (!this.ticket.created_by_vehicle) {
            return null;
        }

        return this.$store.getters.vehicleById(this.ticket.created_by_vehicle);
    }

    get createdBy(): string {
        return this.createdByUser?.username || this.createdByVehicle?.name || '';
    }

    get createdByType(): 'vehicle' | 'user' {
        if (this.createdByVehicle) {
            return 'vehicle';
        }

        return 'user';
    }

    get closedBy(): User {
        return this.$store.getters.userById(this.ticket.closed_by);
    }

    get status(): 'open' | 'closed' | 'assigned' {
        if (this.ticket.closed_at) {
            return 'closed';
        }

        if (this.ticket.assigned_to) {
            return 'assigned';
        }

        return 'open';
    }

    get vehicle(): Vehicle | null {
        if (!this.ticket.vehicle_id) {
            return null;
        }

        return this.$store.getters.vehicleById(this.ticket.vehicle_id);
    }
}
