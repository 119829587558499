import { Run } from '@/core/interfaces/run';
import { Notification } from '@/core/interfaces/notification';

export interface RunWithNotifications {
    run: Run;
    notifications: Notification[];
}

export type NotificationOrRunWithNotificationsHolder = Notification | RunWithNotifications;

export const itemIsRunWithNotifications =
    (item: NotificationOrRunWithNotificationsHolder): item is RunWithNotifications => !('notification_type_id' in item);
