






















import { Component, Prop, Watch } from 'vue-property-decorator';
import MapMixin from '@/components/mixins/MapMixin';
import { GeoJSON } from 'geojson';
import L, { CircleMarker, FeatureGroup, LatLng, LatLngBounds, Point, Polygon } from 'leaflet';
import 'leaflet-path-transform';
import { namespace } from 'vuex-class';
import Loader from '@/components/Loader.vue';
import { MapLayer } from '@/core/interfaces/mapLayer';

const latLng2Point = (latLng: LatLng) => {
    return new L.Point(latLng.lng, latLng.lat);
};

const point2LatLng = (point: L.Point) => {
    return new L.LatLng(point.y, point.x);
};

const rotatePoint = (center: L.Point, point: L.Point, yaw: number) => {
    const angle = yaw * (Math.PI / 180);

    // translate to center
    const p2 = [point.x - center.x, point.y - center.y];
    // rotate using matrix rotation
    const p3 = [Math.cos(angle) * p2[0] - Math.sin(angle) * p2[1], Math.sin(angle) * p2[0] + Math.cos(angle) * p2[1]];
    // translate back to center
    const p4 = [p3[0] + center.x, p3[1] + center.y];

    return new L.Point(p4[0], p4[1]);
};

const vehicleDashboard = namespace('vehicleDashboard');
@Component({
    components: { Loader },
})
export default class DashboardLaserscanOffset extends MapMixin {
    @Prop({ required: true })
    protected service!: string;

    @Prop({ required: true })
    protected background!: Blob;

    @Prop({ required: true })
    protected backgroundResolution!: number;

    @Prop({ required: true })
    protected backgroundOffset!: { x: number; y: number };

    @Prop({ default: () => [] })
    private layers!: MapLayer[];

    @vehicleDashboard.State('laserscan')
    protected laserscan!: GeoJSON;

    @vehicleDashboard.State('footprint')
    protected footprint!: GeoJSON;

    @vehicleDashboard.State('robotPosition')
    protected robotPosition!: { x: number; y: number };

    protected laserscanLayer: L.GeoJSON | null = null;
    protected laserscanPointsLayer: L.FeatureGroup | null = null;
    protected footprintLayer: L.Polygon | null = null;
    protected robotPositionLayer: L.Path | null = null;
    protected offset: { x: number; y: number } = { x: 0, y: 0 };
    protected rotation = 0;

    async mounted() {
        const offset = this.backgroundOffset ? this.getLatLngTupleFromOffset(this.backgroundOffset) : undefined;

        await this.initMap({
            elem: this.$refs.map as HTMLElement,
            offset,
            background: {
                image: this.background,
                resolution: this.backgroundResolution,
                offset,
            },
            layers: this.layers,
        });

        this.map.whenReady(() => {
            this.$store.dispatch('vehicleDashboard/loadSingleLaserscan');
            this.$store.dispatch('vehicleDashboard/loadSingleFootprint');
            this.$store.dispatch('vehicleDashboard/loadSingleRobotPosition');
        });

        // @ts-ignore
        this.$options.offsetCounter = 0;
        // @ts-ignore
        this.$options.offset = null;
        // @ts-ignore
        this.$options.totalRotation = 0;
        // @ts-ignore
        this.$options.rotation = null;
        // @ts-ignore
        this.$options.rads = 0;
        // @ts-ignore
        this.$options.origOffset = null;
        // @ts-ignore
        this.$options.robotPosition = null;
        // @ts-ignore
        this.$options.origFootprintLatLngs = null;

        this.map.on('zoomend', () => {
            if (this.robotPositionLayer) {
                // @ts-ignore
                this.robotPositionLayer.transform.reset();
            }
        });
    }

    @Watch('robotPosition')
    drawRobotPositionLayer() {
        if (this.robotPositionLayer) {
            // @ts-ignore
            this.robotPositionLayer.transform.disable();
            this.map.removeLayer(this.robotPositionLayer);
        }

        if (!this.robotPosition) {
            return;
        }

        const point = new L.Point(this.robotPosition.x, this.robotPosition.y);
        const latLngs = this.circleToLatLngs(new L.Circle(point2LatLng(point), 0.45));

        this.robotPositionLayer = new L.Polygon(latLngs, {
            // @ts-ignore
            transform: true,
            draggable: true,
            color: '#FD3535',
        }).addTo(this.map);

        // @ts-ignore
        this.robotPositionLayer.transform.setOptions({
            rotation: true,
            scaling: false,
            handlerOptions: {
                color: '#FD3535',
            },
            boundsOptions: {
                weight: 0,
            },
            rotateHandleOptions: {
                color: '#FD3535',
                fillColor: '#FD3535',
            },
        }).enable();

        // @ts-ignore
        this.$options.origOffset = latLng2Point(this.robotPositionLayer.getBounds().getCenter());

        this.robotPositionLayer.on('drag', () => this.updateOffset());
        this.robotPositionLayer.on('dragend', () => this.updateOffset(true));

        this.robotPositionLayer.on('rotate', e => {
            // @ts-ignore
            this.$options.rotation = 360 - (e.rotation * (180 / Math.PI));
            this.updateOffset();
        });

        this.robotPositionLayer.on('rotateend', e => {
            // @ts-ignore
            this.$options.rotation = 0;
            // @ts-ignore
            this.$options.totalRotation = (this.$options.totalRotation + (360 - (e.rotation * (180 / Math.PI)))) % 360;
            // @ts-ignore
            this.$options.rads = (this.$options.rads + e.rotation);

            this.updateOffset(true);
        });
    }

    @Watch('footprint')
    drawFootprintLayer() {
        if (this.footprintLayer) {
            // @ts-ignore
            this.map.removeLayer(this.footprintLayer);
        }

        if (!this.footprint) {
            return;
        }

        const geoJSON = L.geoJSON(this.footprint, {
            interactive: false,
        });

        this.footprintLayer = geoJSON.getLayers()[0] as Polygon;
        this.footprintLayer.addTo(this.map);
        // @ts-ignore
        this.$options.origFootprintLatLngs = [...this.footprintLayer.getLatLngs()[0]];
    }

    updateText() {
        // @ts-ignore
        this.offset = this.$options.offset;
        // @ts-ignore
        this.rotation = this.$options.totalRotation;
    }

    updateOffset(force = false) {
        // @ts-ignore
        if (!this.robotPositionLayer || !this.$options.origOffset) {
            return;
        }

        // @ts-ignore
        this.$options.offsetCounter++;

        // @ts-ignore
        if (!force && this.$options.offsetCounter % 10 !== 0) {
            return;
        }

        // @ts-ignore
        const bounds = new L.LatLngBounds(this.robotPositionLayer.getLatLngs());
        // @ts-ignore
        this.$options.robotPosition = latLng2Point(bounds.getCenter());
        // @ts-ignore
        if (!this.$options.robotPosition) {
            return;
        }

        // @ts-ignore
        this.$options.offset = this.$options.robotPosition.subtract(this.$options.origOffset);
        this.updateLaserscanLayer();
        this.updateFootprintLayer();
        this.updateText();
    }

    save() {
        // @ts-ignore
        if (!this.$options.offset) {
            this.$emit('close');
            return;
        }

        const args = {
            // @ts-ignore
            dx: this.$options.offset.x,
            // @ts-ignore
            dy: this.$options.offset.y,
            // @ts-ignore
            dh: this.$options.rads * -1,
        };

        this.$store.dispatch('vehicleDashboard/callService', { service: this.service, args });
        this.$emit('close');
    }

    @Watch('laserscanLayer')
    @Watch('footprintLayer')
    zoomToBounds() {
        if (!this.laserscanLayer || !this.footprintLayer) {
            return;
        }

        const bounds = new LatLngBounds(this.laserscanLayer.getBounds().getSouthWest(), this.laserscanLayer.getBounds().getNorthEast());
        const footprintBounds = new LatLngBounds(this.footprintLayer.getBounds().getSouthWest(), this.footprintLayer.getBounds().getNorthEast());

        bounds.extend(footprintBounds);

        this.map.fitBounds(bounds);
    }

    @Watch('laserscan')
    drawLaserscanLayer() {
        if (this.laserscanLayer) {
            this.map.removeLayer(this.laserscanLayer);
        }

        if (!this.laserscan) {
            return;
        }

        const laserscanPointOptions = {
            interactive: false,
            radius: 2,
            weight: 0,
            fillColor: '#00ff00',
            opacity: 1,
            fillOpacity: 0.8,
        };

        this.laserscanLayer = L.geoJSON(
            this.laserscan,
            {
                pointToLayer: (feature, latlng) => {
                    const marker = L.circleMarker(latlng, laserscanPointOptions);
                    // @ts-ignore
                    marker._origLatLng = latlng;

                    return marker;
                },
            },
        ).addTo(this.map);

        this.laserscanPointsLayer = this.laserscanLayer.getLayers()[0] as FeatureGroup;
    }

    updateLaserscanLayer() {
        if (!this.laserscanPointsLayer) {
            return;
        }

        this.laserscanPointsLayer.eachLayer(circleMarker => {
            const rotatedPoint = rotatePoint(
                // @ts-ignore
                this.$options.robotPosition,
                // @ts-ignore
                latLng2Point(circleMarker._origLatLng).add(this.$options.offset),
                // @ts-ignore
                this.$options.totalRotation + this.$options.rotation,
            );
            const latlng = point2LatLng(rotatedPoint);

            (circleMarker as CircleMarker).setLatLng(latlng);
        });
    }

    updateFootprintLayer() {
        if (!this.footprintLayer) {
            return;
        }

        // @ts-ignore
        const robotPosition = this.$options.robotPosition;
        // @ts-ignore
        const offset = this.$options.offset as Point;
        // @ts-ignore
        const origLatLngs = this.$options.origFootprintLatLngs as LatLng[];
        // @ts-ignore
        const rotation = this.$options.totalRotation + this.$options.rotation;

        const newLatlngs = (this.footprintLayer.getLatLngs()[0] as LatLng[]).map((latLng, latLngIndex) => {
            const rotatedPoint = rotatePoint(
                robotPosition,
                latLng2Point(origLatLngs[latLngIndex]).add(offset),
                rotation,
            );

            return point2LatLng(rotatedPoint);
        });

        this.footprintLayer.setLatLngs([newLatlngs]);
    }

    async reset() {
        if (this.laserscanLayer) {
            this.laserscanLayer.remove();
        }

        if (this.footprintLayer) {
            this.footprintLayer.remove();
        }

        if (this.robotPositionLayer) {
            // @ts-ignore
            this.robotPositionLayer.transform.disable();
            this.robotPositionLayer.remove();
        }

        await this.$store.dispatch('vehicleDashboard/loadSingleLaserscan');
        await this.$store.dispatch('vehicleDashboard/loadSingleFootprint');
        await this.$store.dispatch('vehicleDashboard/loadSingleRobotPosition');

        // @ts-ignore
        this.$options.totalRotation = 0;
        // @ts-ignore
        this.$options.rotation = 0;
        // @ts-ignore
        this.$options.offset = null;
        // @ts-ignore
        this.$options.rads = 0;
        // @ts-ignore
        this.$options.robotPosition = null;
        // @ts-ignore
        this.$options.origFootprintLatLngs = null;

        this.rotation = 0;
        this.offset = { x: 0, y: 0 };
    }
}

