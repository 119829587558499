import { Component, Mixins } from 'vue-property-decorator';
import { Notification } from '@/core/interfaces/notification';
import FormatsDate from '@/components/mixins/FormatsDate';

@Component
export default class UsesNotificationDate extends Mixins(FormatsDate) {
    protected notification!: Notification;

    public get notificationDate(): string {
        return this.formatDate(new Date(this.notification.created_at));
    }
}
