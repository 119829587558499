import Axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

const instance = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const cachedAxios = setupCache(instance, {
    storage: buildWebStorage(localStorage, 'axios-cache:'),
});

export default cachedAxios;
