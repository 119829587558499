









































































































import { Component, Watch } from 'vue-property-decorator';
import TopnavLayout from '@/components/views/layouts/TopnavLayout.vue';
import DashboardVehiclesWidget from '@/components/dashboard/widgets/DashboardVehiclesWidget.vue';
import DashboardNotificationTypesWidget from '@/components/dashboard/widgets/DashboardNotificationTypesWidget.vue';
import { differenceInMinutes, endOfYesterday, formatISO, startOfDay, startOfToday, subDays } from 'date-fns';
import DashboardVehicleTypeTabs from '@/components/dashboard/DashboardVehicleTypeTabs.vue';
import { Getter } from 'vuex-class';
import { VehicleType } from '@/core/interfaces/vehicleType';
import PeriodSelector from '@/components/PeriodSelector.vue';
import DashboardVehicle from '@/core/interfaces/dashboard/dashboardVehicle';
import { Vehicle } from '@/core/interfaces/vehicle';
import axios from 'axios';
import DashboardNotificationType from '@/core/interfaces/dashboard/dashboardNotificationType';
import { mixins } from 'vue-class-component';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import { NotificationType } from '@/core/interfaces/notificationType';
import NotificationLevel, { NotificationLevelMapping } from '@/core/enums/notificationLevel';
import DashboardNotificationTypesFeedbackWidget
    from '@/components/dashboard/widgets/DashboardNotificationTypesFeedbackWidget.vue';
import DashboardFeedbackVehicle from '@/core/interfaces/dashboard/dashboardFeedbackVehicle';
import DashboardVehiclesFeedbackWidget from '@/components/dashboard/widgets/DashboardVehiclesFeedbackWidget.vue';

@Component({
    components: {
        DashboardVehiclesFeedbackWidget,
        DashboardNotificationTypesFeedbackWidget,
        PeriodSelector,
        DashboardVehicleTypeTabs,
        DashboardNotificationTypesWidget,
        DashboardVehiclesWidget,
        TopnavLayout,
    },
})
export default class DashboardView extends mixins(UsesNotificationMessage) {
    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    notificationLevel = NotificationLevel;

    loading = false;

    period: { start: Date; end: Date } | null = null;

    levels: NotificationLevel[] = [
        NotificationLevel.ERROR,
        NotificationLevel.WARNING,
        NotificationLevel.INFO,
    ];

    vehicleNotificationTypes: null | DashboardNotificationType[] = null;
    notificationTypeVehicles: null | DashboardVehicle[] = null;
    notificationTypeFeedbackVehicles: null | DashboardFeedbackVehicle[] = null;

    created(): void {
        const end = endOfYesterday();
        const start = startOfDay(subDays(startOfToday(), 7));

        this.period = {
            start,
            end,
        };
    }

    mounted(): void {
        if (this.$route.query.vehicle_id) {
            this.fetchVehicle(Number(this.$route.query.vehicle_id));
        }

        if (this.$route.query.notification_type_id) {
            this.fetchNotificationType(Number(this.$route.query.notification_type_id));
        }

        if (this.$route.query.notification_type_feedback_id) {
            this.fetchNotificationTypeFeedback(Number(this.$route.query.notification_type_feedback_id));
        }
    }

    @Watch('vehicleTypeId')
    @Watch('period')
    @Watch('levels')
    async fetch() {
        if (!this.vehicleTypeId || !this.period) {
            return;
        }

        this.loading = true;

        await this.$store.dispatch('dashboard/fetch', {
            dateFrom: formatISO(this.period.start),
            dateUntil: formatISO(this.period.end),
            vehicleTypeId: this.vehicleTypeId,
            levels: this.levelParams,
        });

        this.loading = false;
    }

    get periodDurationInMinutes(): number {
        if (!this.period) {
            return 0;
        }

        return differenceInMinutes(this.period.end, this.period.start) + 1;
    }

    get vehicleTypeId(): number | null {
        return Number(this.$route.query.vehicle_type_id) || null;
    }

    showVehicle(vehicle: DashboardVehicle | null): void {
        this.$router.push({
            name: 'dashboard',
            query: {
                ...this.$route.query,
                vehicle_id: vehicle ? String(vehicle.vehicle_id) : undefined,
            },
        });
    }

    showNotificationType(notificationType: DashboardNotificationType | null): void {
        this.$router.push({
            name: 'dashboard',
            query: {
                ...this.$route.query,
                notification_type_id: notificationType ? String(notificationType.notification_type_id) : undefined,
            },
        });
    }

    showNotificationTypeFeedback(notificationType: DashboardNotificationType | null): void {
        this.$router.push({
            name: 'dashboard',
            query: {
                ...this.$route.query,
                notification_type_feedback_id: notificationType ? String(notificationType.notification_type_id) : undefined,
            },
        });
    }

    @Watch('$route.query.vehicle_id')
    async fetchVehicle(vehicleId: number): Promise<void> {
        this.vehicleNotificationTypes = null;

        if (!vehicleId || !this.period) {
            return;
        }

        this.vehicleNotificationTypes = (await axios.get(`/dashboard-analytics/vehicles/${vehicleId}`, {
            params: {
                date_from: this.period.start,
                date_until: this.period.end,
                ...this.levelParams,
            },
        })).data;
    }

    get modalVehicle(): Vehicle | null {
        if (!this.$route.query.vehicle_id) {
            return null;
        }

        return this.$store.getters.vehicleById(this.$route.query.vehicle_id);
    }

    @Watch('$route.query.notification_type_id')
    async fetchNotificationType(notificationTypeId: number): Promise<void> {
        this.notificationTypeVehicles = null;

        if (!notificationTypeId || !this.period) {
            return;
        }

        this.notificationTypeVehicles = (await axios.get(`/dashboard-analytics/notification-types/${notificationTypeId}`, {
            params: {
                date_from: this.period.start,
                date_until: this.period.end,
            },
        })).data;
    }

    @Watch('$route.query.notification_type_feedback_id')
    async fetchNotificationTypeFeedback(notificationTypeId: number): Promise<void> {
        this.notificationTypeFeedbackVehicles = null;

        if (!notificationTypeId || !this.period) {
            return;
        }

        this.notificationTypeFeedbackVehicles = (await axios.get(`/dashboard-analytics/notification-types/${notificationTypeId}/feedback`, {
            params: {
                date_from: this.period.start,
                date_until: this.period.end,
            },
        })).data;
    }

    get modalNotificationType(): NotificationType | null {
        if (!this.$route.query.notification_type_id) {
            return null;
        }

        return this.$store.getters.notificationTypeById(this.$route.query.notification_type_id);
    }

    get modalNotificationTypeFeedback(): NotificationType | null {
        if (!this.$route.query.notification_type_feedback_id) {
            return null;
        }

        return this.$store.getters.notificationTypeById(this.$route.query.notification_type_feedback_id);
    }

    get levelParams(): Record<string, string> {
        return this.levels.reduce((acc: Record<string, string>, item, idx) => {
            acc[`levels[${idx}]`] = String(NotificationLevelMapping.indexOf(item));

            return acc;
        }, {});
    }
}
