import { Module } from 'vuex';
import { RootState } from '@/store/rootState';
import { DashboardState } from './dashboardState';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

const settings: Module<DashboardState, RootState> = {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};

export default settings;
