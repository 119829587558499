













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { NotifyAction } from '@/core/interfaces/notifyAction';
import { Role } from '@/core/interfaces/role';
import TranslatesRole from '@/components/mixins/TranslatesRole';
import { Getter } from 'vuex-class';

@Component({
    components: {},
})
export default class SettingsRuleModalActionsRow extends Mixins(TranslatesRole) {
    @Prop({ default: () => [] })
    protected value!: NotifyAction;

    @Prop({ required: true })
    protected usedRoles!: Role[];

    @Getter('rolesSet')
    protected roles!: Role[];

    get availableRoles() {
        return this.roles.filter(role => this.value.role_id === role.id || this.usedRoles.some(usedRole => usedRole.id === role.id));
    }

    get isEmptyRow(): boolean {
        return this.value.role_id === null;
    }
}
