



















import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FormatsDate from '@/components/mixins/FormatsDate';

@Component({
    components: {},
})
export default class NotificationTimelineItem extends mixins(FormatsDate) {
    @Prop({ required: true })
    date!: Date;

    @Prop({ default: true })
    showFullDate!: boolean;

    @Prop({ default: false })
    scheduled!: boolean;
}
