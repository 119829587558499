






import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
    components: {},
})
export default class Timeline extends Vue {

}
