



















import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VehicleType } from '@/core/interfaces/vehicleType';
import { NotificationType } from '@/core/interfaces/notificationType';
import { Farm } from '@/core/interfaces/farm';
import { RawLocation } from 'vue-router';
import axios from 'axios';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';

@Component
export default class KnowledgeBaseLink extends mixins(UsesNotificationMessage) {
    @Prop({ required: true })
    mode!: 'farm-vehicle-type' | 'notification-type';

    @Prop({ required: true })
    vehicleType!: VehicleType;

    @Prop()
    notificationType!: NotificationType;

    @Prop()
    farm!: Farm;

    knowledgeBasePage: string | null = null;

    async mounted(): Promise<void> {
        this.knowledgeBasePage = await this.fetchKnowledgeBasePage();
    }

    async fetchKnowledgeBasePage(): Promise<string | null> {
        try {
            const response = (await axios.get(this.knowledgeBasePageLink, {
                headers: {
                    'X-Lely-Skip-Auth-Interceptor': 'true',
                },
            }));

            return response.data;
        } catch (e) {
            return null;
        }
    }

    get hasKnowledgeBasePage(): boolean {
        return !!this.knowledgeBasePage;
    }

    get knowledgeBasePageLink(): string {
        return this.mode === 'farm-vehicle-type'
            ? `vehicle-types/${this.vehicleType.id}/farms/${this.farm.id}/page`
            : `notification-types/${this.notificationType.id}/page`;
    }

    get knowledgeBaseLink(): RawLocation {
        return this.mode === 'farm-vehicle-type'
            ? {
                name: 'knowledge-base.vehicle-type.farm',
                params: {
                    vehicleTypeName: this.vehicleType?.name,
                    farmId: String(this.farm.id),
                },
                query: {
                    create: this.hasKnowledgeBasePage ? null : 'true',
                },
            } : {
                name: 'knowledge-base.vehicle-type.notification-type',
                params: {
                    vehicleTypeName: this.vehicleType?.name,
                    notificationTypeCode: String(this.notificationType.code),
                },
                query: {
                    create: this.hasKnowledgeBasePage ? null : 'true',
                },
            };
    }

    get popover(): { html: boolean; content: string; title: string } | null {
        if (!this.hasKnowledgeBasePage) {
            return null;
        }

        const div = document.createElement('div');
        div.innerHTML = this.knowledgeBasePage || '';
        let content = div.innerText.substring(0, 250);

        if (content.length === 250) {
            content += '...';
        }

        const title = this.mode === 'farm-vehicle-type'
            ? `${this.farm.name} - ${this.vehicleType.name}`
            : `${this.notificationType.code} - ${this.notificationMessage}`;

        return {
            html: true,
            content,
            title,
        };
    }
}
