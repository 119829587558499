








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class DashboardDifference extends Vue {
    @Prop({ type: Number, required: true })
    currentValue!: number;

    @Prop({ type: Number, required: true })
    previousValue!: number;

    @Prop()
    formatter!: (value: number) => string;

    get variant(): string {
        if (this.delta === 0 || isNaN(this.delta)) {
            return 'neutral';
        }

        if (this.delta < 0) {
            return 'success';
        } else {
            return 'warning';
        }
    }

    get delta(): number {
        if (!this.isDifferent) {
            return 0;
        }

        return Math.round((this.currentValue - this.previousValue) / this.previousValue * 100);
    }

    get displayValue(): string {
        if (!this.isDifferent) {
            return '-';
        }

        if (isFinite(this.delta)) {
            return `${this.delta}%`;
        }

        return '∞';
    }

    get formattedPrevious(): string {
        if (!this.previousValue) {
            return '-';
        }

        if (this.formatter) {
            return this.formatter(this.previousValue);
        }

        return this.previousValue.toLocaleString();
    }

    get icon(): string {
        if (this.delta < 0) {
            return 'lely-icon-caret-down';
        } else if (this.delta > 0) {
            return 'lely-icon-caret-up';
        }

        return '';
    }

    get isDifferent(): boolean {
        return this.currentValue !== this.previousValue;
    }

    get prefix(): string {
        if (this.delta > 0) {
            return '+';
        } else if (this.delta < 0) {
            return '-';
        }

        return '';
    }
}

