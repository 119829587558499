







import { Component, Vue } from 'vue-property-decorator';
import SettingsRules from '@/components/settings/rule/SettingsRules.vue';

@Component({
    components: { SettingsRules },
})
export default class RulesView extends Vue {

}

