import { ActionContext, ActionTree } from 'vuex';
import { SettingsState } from '@/store/modules/settings/settingsState';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { Rule } from '@/core/interfaces/rule';
import { Timetable } from '@/core/interfaces/timetable';

const actions: ActionTree<SettingsState, RootState> = {
    syncSettings: async(context: ActionContext<SettingsState, RootState>) => {
        await Promise.all([
            context.dispatch('syncRules'),
        ]);
    },
    syncRules: async(context: ActionContext<SettingsState, RootState>) => {
        const response = await axios.get('/notification-rules');
        context.commit('setRules', response.data);
    },
    syncTimetable: async(context: ActionContext<SettingsState, RootState>, params: { dateFrom: Date; dateUntil: Date }) => {
        const timetableResponse = (await axios.get('/timetable', {
            params,
        })).data as Timetable[];
        timetableResponse.forEach((timetable: Timetable) => context.commit('updateTimetable', timetable));

        context.state.timetable
            .filter(timetable => !timetableResponse.some(item => item.id === timetable.id))
            .forEach(timetable => context.commit('deleteTimetable', timetable.id));
    },
    saveTimetable: async(context: ActionContext<SettingsState, RootState>, payload: Timetable) => {
        if (payload.id) {
            const response = await axios.put(`/timetable/${payload.id}`, payload);
            context.commit('updateTimetable', response.data);
        } else {
            const response = await axios.post('/timetable', payload);
            context.commit('updateTimetable', response.data);
        }
    },
    deleteTimetable: async(context: ActionContext<SettingsState, RootState>, id: number) => {
        await axios.delete(`/timetable/${id}`);
        context.commit('deleteTimetable', id);
    },
    saveRule: async(context, rule: Rule) => {
        if (rule.id) {
            await axios.put(`/notification-rules/${rule.id}`, rule);
        } else {
            await axios.post('notification-rules', rule);
        }

        await context.dispatch('syncSettings');
    },
    deleteRule: async(context, rule: Rule) => {
        await axios.delete(`/notification-rules/${rule.id}`);

        await context.dispatch('syncSettings');
    },
};

export default actions;
