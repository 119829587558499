












import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import { User } from '@/core/interfaces/user';
import { Getter } from 'vuex-class';
import UsesRunDetails from '@/components/mixins/UsesRunDetails';
import Actions from '@/components/Actions.vue';
import OpensDashboardModal from '@/components/mixins/OpensDashboardModal';
import OpensRunNotificationsModal from '@/components/mixins/OpensRunNotificationsModal';

interface ActionItem {
    label: string;
    icon: string;
    btnClass: string;
    condition?: () => boolean;
    action?: () => void;
}

@Component({
    components: {
        Actions,
    },
})
export default class RunActions extends Mixins(UsesRunDetails, OpensDashboardModal, OpensRunNotificationsModal) {
    protected open = false;

    @Getter('currentUser')
    protected currentUser!: User;

    @Prop({ default: false })
    protected small!: boolean;

    @Prop({ type: Boolean })
    protected showViewRunNotifications!: boolean;

    get actions(): ActionItem[] {
        return [
            {
                label: this.$t('notification-actions.link').toString(),
                icon: 'lely-icon-link',
                btnClass: 'btn btn-secondary',
                action: async() => {
                    const url = `${window.location.origin}${this.$router.resolve({
                        path: '',
                        query: {
                            run: String(this.run.id),
                        },
                    }).href}`;

                    await navigator.clipboard.writeText(url);

                    this.$bvToast.toast(this.$t('notification-actions.link-copied').toString(), {
                        title: this.$t('notification-actions.link').toString(),
                        variant: 'success',
                        autoHideDelay: 5000,
                    });
                },
            },
            {
                label: this.$t('run-actions.view-run-notifications', { count: this.run.notifications_count }) as string,
                icon: 'lely-icon-close',
                btnClass: 'btn btn-secondary',
                condition: () => this.showViewRunNotifications,
                action: () => this.openRunNotificationsModal(this.run),
            },
            {
                label: this.$t('run-actions.close') as string,
                icon: 'lely-icon-close',
                btnClass: 'btn btn-secondary',
                condition: () => !this.run.end_date,
                action: async() => {
                    await this.$store.dispatch('runs/closeRun', {
                        run: this.run,
                    });
                },
            },
        ];
    }
}
