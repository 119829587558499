













import { Component, Prop, Vue } from 'vue-property-decorator';
import RunCard from '@/components/run/RunCard.vue';
import NotificationCard from '@/components/notification/NotificationCard.vue';
import {
    itemIsRunWithNotifications,
    NotificationOrRunWithNotificationsHolder,
    RunWithNotifications,
} from '@/core/interfaces/notificationOrRunWithNotificationsHolder';

@Component({
    components: {
        RunCard,
        NotificationCard,
    },
})
export default class Runs extends Vue {
    @Prop({ required: true })
    public viewPreferenceEventBus!: Vue;

    @Prop({ required: true })
    public runOrNotification!: NotificationOrRunWithNotificationsHolder;

    public itemIsRun(item: NotificationOrRunWithNotificationsHolder): item is RunWithNotifications {
        return itemIsRunWithNotifications(item);
    }
}
