

























import Component from 'vue-class-component';
import { Notification } from '@/core/interfaces/notification';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import NotificationTimelineNotificationLogItem
    from '@/components/notification/NotificationTimelineNotificationLogItem.vue';
import NotificationTimelineEventItem from '@/components/notification/NotificationTimelineEventItem.vue';
import Timeline from '@/components/Timeline.vue';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import NotificationPhoneLog from '@/components/notification/NotificationPhoneLog.vue';

@Component({
    components: { Timeline, NotificationTimelineNotificationLogItem, NotificationTimelineEventItem, NotificationPhoneLog },
})
export default class NotificationTimeline extends Vue {
    @Prop({ required: true })
    protected notification!: Notification;

    get components(): { date: Date; component: string; props: any }[] {
        const created = {
            component: 'NotificationTimelineEventItem',
            date: parseISO(this.notification.created_at),
            props: {
                type: 'created_at',
                date: parseISO(this.notification.created_at),
            },
        };

        const logs = this.notification.notify_logs.map(notifyLog => {
            const date = notifyLog.send_at ? parseISO(notifyLog.send_at) : parseISO(notifyLog.scheduled_at);

            return {
                component: 'NotificationTimelineNotificationLogItem',
                date,
                props: {
                    notifyLog,
                },
            };
        });

        const clearedLogs = this.notification.notify_logs
            .filter(notifyLog => !!notifyLog.cleared_at)
            .map(notifyLog => {
                const date = parseISO(notifyLog.cleared_at);

                return {
                    component: 'NotificationTimelineNotificationLogItem',
                    date,
                    props: {
                        cleared: true,
                        notifyLog,
                    },
                };
            });

        const items: { component: string; props: any; date: Date }[] = [created, ...logs, ...clearedLogs];

        if (this.notification.cleared_at) {
            const date = parseISO(this.notification.cleared_at);

            const cleared = {
                component: 'NotificationTimelineEventItem',
                date,
                props: {
                    type: 'cleared_at',
                    date,
                },
            };

            items.push(cleared);
        }

        let lastDate: Date | null = null;

        return items
            .sort((a, b) => isAfter(a.date, b.date) ? 1 : -1)
            .map(item => {
                item.props.differentDateThanPrevious = lastDate ? !isSameDay(lastDate, item.date) : true;
                lastDate = item.date;

                return item;
            });
    }
}
