












import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { User } from '@/core/interfaces/user';
import { Getter } from 'vuex-class';
import Actions from '@/components/Actions.vue';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import NotificationActionMessageModal from '@/components/notification/NotificationActionMessageModal.vue';
import { NotificationNotifyRequest } from '@/core/interfaces/notificationNotifyRequest';
import RunUsersModal, { AssignResult } from '@/components/run/RunUsersModal.vue';
import OpensDashboardModal from '@/components/mixins/OpensDashboardModal';

interface ActionItem {
    label: string;
    icon: string;
    btnClass: string;
    condition?: () => boolean;
    action?: () => void;
}

@Component({
    components: {
        Actions,
    },
})
export default class NotificationActions extends Mixins(UsesNotificationDetails, OpensDashboardModal) {
    protected open = false;

    @Getter('currentUser')
    protected currentUser!: User;

    get actions(): ActionItem[] {
        return [
            {
                label: this.$t('notification-actions.link').toString(),
                icon: 'lely-icon-link',
                btnClass: 'btn btn-secondary',
                condition: () => !!this.notification.notification_run_id,
                action: async() => {
                    const url = `${window.location.origin}/${this.$router.resolve({
                        path: '',
                        query: {
                            run: String(this.notification.notification_run_id),
                            notification: String(this.notification.id),
                        },
                    }).href}`;

                    await navigator.clipboard.writeText(url);

                    this.$bvToast.toast(this.$t('notification-actions.link-copied').toString(), {
                        title: this.$t('notification-actions.link').toString(),
                        variant: 'success',
                        autoHideDelay: 5000,
                    });
                },
            },
            {
                label: this.$t('notification-actions.notify').toString(),
                icon: 'lely-icon-send',
                btnClass: 'btn btn-secondary',
                action: async() => {
                    const notificationNotifyRequest = await this.showNotifyModal();

                    await this.$store.dispatch('runs/notifyNotification', {
                        notification: this.notification,
                        notificationNotifyRequest,
                    });
                },
            },
            {
                label: this.$t('notification-actions.assign') as string,
                icon: 'lely-icon-person-assign',
                btnClass: 'btn btn-secondary',
                action: async() => {
                    const assignResult = await this.showUsersModal(this.$t('runs.assign').toString(), true);

                    switch (assignResult.type) {
                        case 'user':
                            await this.$store.dispatch('runs/assignNotificationToUser', {
                                notification: this.notification,
                                user: assignResult.value,
                            });
                            return;
                        case 'role':
                            await this.$store.dispatch('runs/assignNotificationToRole', {
                                notification: this.notification,
                                role: assignResult.value,
                            });
                    }
                },
            },
            {
                label: this.$t('notification-actions.unassign') as string,
                icon: 'lely-icon-person-unassign',
                btnClass: 'btn btn-secondary',
                condition: () => !!this.notification.assigned_to_user_id || !!this.notification.assigned_to_role_id,
                action: async() => {
                    await this.$store.dispatch('runs/unassignNotification', {
                        notification: this.notification,
                    });
                },
            },
            {
                label: this.$t('notification-actions.assign-to-me') as string,
                icon: 'lely-icon-person',
                btnClass: 'btn btn-secondary',
                condition: () => !this.assignedToMe,
                action: async() => {
                    await this.$store.dispatch('runs/assignNotificationToUser', {
                        notification: this.notification,
                        user: this.currentUser,
                    });
                },
            },
            {
                label: this.$t('notification-actions.close') as string,
                icon: 'lely-icon-close',
                btnClass: 'btn btn-secondary',
                condition: () => !this.notification.ended_at,
                action: async() => {
                    await this.$store.dispatch('runs/closeNotification', { notification: this.notification });
                },
            },
            {
                label: this.$t('notification-actions.resolve') as string,
                icon: 'lely-icon-check',
                btnClass: 'btn btn-secondary',
                condition: () => !this.notification.resolved_by_user_id,
                action: async() => {
                    const assignResult = await this.showUsersModal(this.$t('runs.resolve').toString(), false);

                    await this.$store.dispatch('runs/resolveNotification', {
                        notification: this.notification,
                        user: assignResult.value,
                    });
                },
            },
            {
                label: this.$t('notification-actions.resolve-by-me') as string,
                icon: 'lely-icon-person-approve',
                btnClass: 'btn btn-secondary',
                condition: () => !this.notification.resolved_by_user_id,
                action: async() => this.$store.dispatch('runs/resolveNotification', {
                    notification: this.notification,
                    user: this.currentUser,
                }),
            },
            {
                label: this.$t('notification-actions.unresolve') as string,
                icon: 'lely-icon-refresh',
                btnClass: 'btn btn-secondary',
                condition: () => !!this.notification.resolved_by_user_id,
                action: async() => this.$store.dispatch('runs/resolveNotification', {
                    notification: this.notification,
                    user: null,
                }),
            },
        ];
    }

    async showNotifyModal(): Promise<NotificationNotifyRequest> {
        return new Promise((resolve, reject) => {
            this.$modal.show(
                NotificationActionMessageModal,
                {},
                { height: 'auto' },
                {
                    'before-close': (event: { params: NotificationNotifyRequest }) => {
                        if (event.params) {
                            resolve(event.params);
                        } else {
                            reject(new Error('No result'));
                        }
                    },
                },
            );
        });
    }

    async showUsersModal(title: string, rolesSelectable: boolean, onlyOperators = false): Promise<AssignResult> {
        return new Promise((resolve, reject) => {
            this.$modal.show(
                RunUsersModal,
                { title, rolesSelectable, onlyOperators },
                { adaptive: true, height: 'auto' },
                {
                    'before-close': (event: { params: AssignResult | undefined }) => {
                        if (event.params !== undefined) {
                            resolve(event.params);
                        } else {
                            reject(new Error('No result'));
                        }
                    },
                },
            );
        });
    }
}
