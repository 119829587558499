export const formatHours = (time: number): string => {
    const timeString = String(time);
    const minutes = timeString.substring(timeString.length - 2);

    let hours = timeString.substring(0, timeString.length - 2);

    if (hours.length === 1) {
        hours = `0${hours}`;
    }

    return `${hours}:${minutes}`;
};

export const deepClone = (obj: object): object => {
    return JSON.parse(JSON.stringify(obj));
};

export const createRecordByIdFromArray = <T extends { id: number }>(data: T[]) => data.reduce((acc: Record<number, T>, item: T) => {
    acc[item.id] = { ...acc[item.id], ...item };

    return acc;
}, {});
